/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Annotations } from '../models';
// @ts-ignore
import type { Calendar } from '../models';
// @ts-ignore
import type { Categories } from '../models';
// @ts-ignore
import type { CompanyDetail } from '../models';
// @ts-ignore
import type { CompanySetting } from '../models';
// @ts-ignore
import type { DeleteAnnotationRequest } from '../models';
// @ts-ignore
import type { EmployeeFormData } from '../models';
// @ts-ignore
import type { FormAnnotation } from '../models';
// @ts-ignore
import type { FormCalendar } from '../models';
// @ts-ignore
import type { FormCategories } from '../models';
// @ts-ignore
import type { FormEditAnnotation } from '../models';
// @ts-ignore
import type { FormFilterManagers } from '../models';
// @ts-ignore
import type { FormFullCompanyStructureByTimestamp } from '../models';
// @ts-ignore
import type { FormManagers } from '../models';
// @ts-ignore
import type { FormUpdateCreateCategory } from '../models';
// @ts-ignore
import type { GetListCustomField200Response } from '../models';
// @ts-ignore
import type { GetStructureCompanyRequest } from '../models';
// @ts-ignore
import type { GroupDetail } from '../models';
// @ts-ignore
import type { Managers } from '../models';
// @ts-ignore
import type { Modules } from '../models';
// @ts-ignore
import type { ReponsegetListManagers } from '../models';
// @ts-ignore
import type { ResponseCategories } from '../models';
// @ts-ignore
import type { RolesDetail } from '../models';
// @ts-ignore
import type { StructureCompanyRespone } from '../models';
// @ts-ignore
import type { SystemSetting } from '../models';
/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createAnnotations
         * @param {FormAnnotation} body createAnnotations Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotations: async (body: FormAnnotation, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createAnnotations', 'body', body)
            const localVarPath = `/api/company/createAnnotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createCategory
         * @param {FormUpdateCreateCategory} body createCategory Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory: async (body: FormUpdateCreateCategory, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createCategory', 'body', body)
            const localVarPath = `/api/company/createCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createCustomHoliday
         * @param {FormCalendar} body createCustomHoliday Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomHoliday: async (body: FormCalendar, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createCustomHoliday', 'body', body)
            const localVarPath = `/api/company/createCustomHoliday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createManagers
         * @param {Array<FormManagers>} body createManagers Detail type GROUP||DEPARTMENT
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManagers: async (body: Array<FormManagers>, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createManagers', 'body', body)
            const localVarPath = `/api/company/createManagers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAnnotation
         * @param {number} id 
         * @param {string} [source] 
         * @param {DeleteAnnotationRequest} [body] deleteAnnotation Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotation: async (id: number, source?: string, body?: DeleteAnnotationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAnnotation', 'id', id)
            const localVarPath = `/api/company/deleteAnnotation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCategory
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory: async (id: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCategory', 'id', id)
            const localVarPath = `/api/company/deleteCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCustomHoliday
         * @param {number} id 
         * @param {FormCalendar} body deleteCustomHoliday Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomHoliday: async (id: number, body: FormCalendar, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCustomHoliday', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('deleteCustomHoliday', 'body', body)
            const localVarPath = `/api/company/deleteCustomHoliday/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteManagers
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagers: async (id: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteManagers', 'id', id)
            const localVarPath = `/api/company/deleteManagers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllRole
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRole: async (source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/getAllRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCompanyInformation
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyInformation: async (source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/getCompanyInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCompanySetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySetting: async (key: string, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getCompanySetting', 'key', key)
            const localVarPath = `/api/company/getCompanySetting/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEmployeeFormSetting
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeFormSetting: async (source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/getEmployeeFormSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFullCompanyStructureByTimestamp
         * @param {FormFullCompanyStructureByTimestamp} body getFullCompanyStructureByTimestamp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullCompanyStructureByTimestamp: async (body: FormFullCompanyStructureByTimestamp, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getFullCompanyStructureByTimestamp', 'body', body)
            const localVarPath = `/api/company/getFullCompanyStructureByTimestamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLastestTime
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastestTime: async (source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/getLastestTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListCategories
         * @param {FormCategories} body getListCategories Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListCategories: async (body: FormCategories, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListCategories', 'body', body)
            const localVarPath = `/api/company/getListCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListCustomField
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListCustomField: async (page: number, limit: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getListCustomField', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getListCustomField', 'limit', limit)
            const localVarPath = `/api/company/getListCustomField/{page}/{limit}`
                .replace(`{${"page"}}`, encodeURIComponent(String(page)))
                .replace(`{${"limit"}}`, encodeURIComponent(String(limit)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListHoliday
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListHoliday: async (startTime: string, endTime: string, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getListHoliday', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getListHoliday', 'endTime', endTime)
            const localVarPath = `/api/company/getListHoliday/{start_time}/{end_time}`
                .replace(`{${"start_time"}}`, encodeURIComponent(String(startTime)))
                .replace(`{${"end_time"}}`, encodeURIComponent(String(endTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListManagers
         * @param {FormFilterManagers} body getListManagers Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListManagers: async (body: FormFilterManagers, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListManagers', 'body', body)
            const localVarPath = `/api/company/getListManagers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListModule
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListModule: async (source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/getListModule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStructureCompany
         * @param {GetStructureCompanyRequest} body getStructureCompany Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructureCompany: async (body: GetStructureCompanyRequest, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getStructureCompany', 'body', body)
            const localVarPath = `/api/company/getStructureCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSystemSetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemSetting: async (key: string, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getSystemSetting', 'key', key)
            const localVarPath = `/api/company/getSystemSetting/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary importHoliday
         * @param {number} year 
         * @param {boolean} isDeletedPublicHoliday 
         * @param {string} [source] 
         * @param {object} [body] deleteCustomHoliday Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importHoliday: async (year: number, isDeletedPublicHoliday: boolean, source?: string, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('importHoliday', 'year', year)
            // verify required parameter 'isDeletedPublicHoliday' is not null or undefined
            assertParamExists('importHoliday', 'isDeletedPublicHoliday', isDeletedPublicHoliday)
            const localVarPath = `/api/company/importHoliday/{year}/{isDeletedPublicHoliday}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"isDeletedPublicHoliday"}}`, encodeURIComponent(String(isDeletedPublicHoliday)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary importPublicHoliday
         * @param {number} year 
         * @param {boolean} isDeletedPublicHoliday 
         * @param {boolean} changeShift 
         * @param {string} [source] 
         * @param {object} [body] deleteCustomHoliday Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPublicHoliday: async (year: number, isDeletedPublicHoliday: boolean, changeShift: boolean, source?: string, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('importPublicHoliday', 'year', year)
            // verify required parameter 'isDeletedPublicHoliday' is not null or undefined
            assertParamExists('importPublicHoliday', 'isDeletedPublicHoliday', isDeletedPublicHoliday)
            // verify required parameter 'changeShift' is not null or undefined
            assertParamExists('importPublicHoliday', 'changeShift', changeShift)
            const localVarPath = `/api/company/importPublicHoliday/{year}/{isDeletedPublicHoliday}/{change_shift}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"isDeletedPublicHoliday"}}`, encodeURIComponent(String(isDeletedPublicHoliday)))
                .replace(`{${"change_shift"}}`, encodeURIComponent(String(changeShift)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAnnotation
         * @param {number} id 
         * @param {FormEditAnnotation} body updateAnnotation Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotation: async (id: number, body: FormEditAnnotation, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAnnotation', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateAnnotation', 'body', body)
            const localVarPath = `/api/company/updateAnnotation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCategory
         * @param {number} id 
         * @param {FormUpdateCreateCategory} body updateCategory Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory: async (id: number, body: FormUpdateCreateCategory, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCategory', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCategory', 'body', body)
            const localVarPath = `/api/company/updateCategory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCompanySetting
         * @param {string} key 
         * @param {CompanySetting} body updateCompanySetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySetting: async (key: string, body: CompanySetting, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateCompanySetting', 'key', key)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCompanySetting', 'body', body)
            const localVarPath = `/api/company/updateCompanySetting/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCustomHoliday
         * @param {number} id 
         * @param {FormCalendar} body updateCustomHoliday Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomHoliday: async (id: number, body: FormCalendar, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCustomHoliday', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCustomHoliday', 'body', body)
            const localVarPath = `/api/company/updateCustomHoliday/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createAnnotations
         * @param {FormAnnotation} body createAnnotations Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnnotations(body: FormAnnotation, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnnotations(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.createAnnotations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary createCategory
         * @param {FormUpdateCreateCategory} body createCategory Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCategory(body: FormUpdateCreateCategory, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCategory(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.createCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary createCustomHoliday
         * @param {FormCalendar} body createCustomHoliday Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomHoliday(body: FormCalendar, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Calendar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomHoliday(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.createCustomHoliday']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary createManagers
         * @param {Array<FormManagers>} body createManagers Detail type GROUP||DEPARTMENT
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManagers(body: Array<FormManagers>, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Managers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManagers(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.createManagers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deleteAnnotation
         * @param {number} id 
         * @param {string} [source] 
         * @param {DeleteAnnotationRequest} [body] deleteAnnotation Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotation(id: number, source?: string, body?: DeleteAnnotationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotation(id, source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.deleteAnnotation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deleteCategory
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCategory(id: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCategory(id, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.deleteCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deleteCustomHoliday
         * @param {number} id 
         * @param {FormCalendar} body deleteCustomHoliday Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomHoliday(id: number, body: FormCalendar, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomHoliday(id, body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.deleteCustomHoliday']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deleteManagers
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteManagers(id: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Managers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManagers(id, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.deleteManagers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getAllRole
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllRole(source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolesDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllRole(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getAllRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getCompanyInformation
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyInformation(source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyInformation(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getCompanyInformation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getCompanySetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanySetting(key: string, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySetting(key, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getCompanySetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getEmployeeFormSetting
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeFormSetting(source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeFormData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeFormSetting(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getEmployeeFormSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getFullCompanyStructureByTimestamp
         * @param {FormFullCompanyStructureByTimestamp} body getFullCompanyStructureByTimestamp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullCompanyStructureByTimestamp(body: FormFullCompanyStructureByTimestamp, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullCompanyStructureByTimestamp(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getFullCompanyStructureByTimestamp']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getLastestTime
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastestTime(source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastestTime(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getLastestTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListCategories
         * @param {FormCategories} body getListCategories Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListCategories(body: FormCategories, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCategories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListCategories(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getListCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListCustomField
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListCustomField(page: number, limit: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetListCustomField200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListCustomField(page, limit, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getListCustomField']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListHoliday
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListHoliday(startTime: string, endTime: string, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Calendar>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListHoliday(startTime, endTime, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getListHoliday']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListManagers
         * @param {FormFilterManagers} body getListManagers Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListManagers(body: FormFilterManagers, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReponsegetListManagers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListManagers(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getListManagers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListModule
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListModule(source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Modules>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListModule(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getListModule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getStructureCompany
         * @param {GetStructureCompanyRequest} body getStructureCompany Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStructureCompany(body: GetStructureCompanyRequest, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StructureCompanyRespone>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStructureCompany(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getStructureCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getSystemSetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemSetting(key: string, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemSetting(key, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.getSystemSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary importHoliday
         * @param {number} year 
         * @param {boolean} isDeletedPublicHoliday 
         * @param {string} [source] 
         * @param {object} [body] deleteCustomHoliday Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importHoliday(year: number, isDeletedPublicHoliday: boolean, source?: string, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importHoliday(year, isDeletedPublicHoliday, source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.importHoliday']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary importPublicHoliday
         * @param {number} year 
         * @param {boolean} isDeletedPublicHoliday 
         * @param {boolean} changeShift 
         * @param {string} [source] 
         * @param {object} [body] deleteCustomHoliday Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importPublicHoliday(year: number, isDeletedPublicHoliday: boolean, changeShift: boolean, source?: string, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importPublicHoliday(year, isDeletedPublicHoliday, changeShift, source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.importPublicHoliday']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateAnnotation
         * @param {number} id 
         * @param {FormEditAnnotation} body updateAnnotation Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAnnotation(id: number, body: FormEditAnnotation, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Annotations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAnnotation(id, body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.updateAnnotation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateCategory
         * @param {number} id 
         * @param {FormUpdateCreateCategory} body updateCategory Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCategory(id: number, body: FormUpdateCreateCategory, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Categories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCategory(id, body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.updateCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateCompanySetting
         * @param {string} key 
         * @param {CompanySetting} body updateCompanySetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanySetting(key: string, body: CompanySetting, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanySetting(key, body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.updateCompanySetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateCustomHoliday
         * @param {number} id 
         * @param {FormCalendar} body updateCustomHoliday Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomHoliday(id: number, body: FormCalendar, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Calendar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomHoliday(id, body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.updateCustomHoliday']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary createAnnotations
         * @param {FormAnnotation} body createAnnotations Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnnotations(body: FormAnnotation, source?: string, options?: any): AxiosPromise<string> {
            return localVarFp.createAnnotations(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createCategory
         * @param {FormUpdateCreateCategory} body createCategory Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(body: FormUpdateCreateCategory, source?: string, options?: any): AxiosPromise<Categories> {
            return localVarFp.createCategory(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createCustomHoliday
         * @param {FormCalendar} body createCustomHoliday Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomHoliday(body: FormCalendar, source?: string, options?: any): AxiosPromise<Calendar> {
            return localVarFp.createCustomHoliday(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createManagers
         * @param {Array<FormManagers>} body createManagers Detail type GROUP||DEPARTMENT
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManagers(body: Array<FormManagers>, source?: string, options?: any): AxiosPromise<Managers> {
            return localVarFp.createManagers(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteAnnotation
         * @param {number} id 
         * @param {string} [source] 
         * @param {DeleteAnnotationRequest} [body] deleteAnnotation Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotation(id: number, source?: string, body?: DeleteAnnotationRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteAnnotation(id, source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteCategory
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(id: number, source?: string, options?: any): AxiosPromise<Categories> {
            return localVarFp.deleteCategory(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteCustomHoliday
         * @param {number} id 
         * @param {FormCalendar} body deleteCustomHoliday Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomHoliday(id: number, body: FormCalendar, source?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomHoliday(id, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteManagers
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteManagers(id: number, source?: string, options?: any): AxiosPromise<Managers> {
            return localVarFp.deleteManagers(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllRole
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRole(source?: string, options?: any): AxiosPromise<Array<RolesDetail>> {
            return localVarFp.getAllRole(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getCompanyInformation
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyInformation(source?: string, options?: any): AxiosPromise<CompanyDetail> {
            return localVarFp.getCompanyInformation(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getCompanySetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySetting(key: string, source?: string, options?: any): AxiosPromise<CompanySetting> {
            return localVarFp.getCompanySetting(key, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getEmployeeFormSetting
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeFormSetting(source?: string, options?: any): AxiosPromise<EmployeeFormData> {
            return localVarFp.getEmployeeFormSetting(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getFullCompanyStructureByTimestamp
         * @param {FormFullCompanyStructureByTimestamp} body getFullCompanyStructureByTimestamp Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullCompanyStructureByTimestamp(body: FormFullCompanyStructureByTimestamp, source?: string, options?: any): AxiosPromise<Array<GroupDetail>> {
            return localVarFp.getFullCompanyStructureByTimestamp(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getLastestTime
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastestTime(source?: string, options?: any): AxiosPromise<number> {
            return localVarFp.getLastestTime(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListCategories
         * @param {FormCategories} body getListCategories Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListCategories(body: FormCategories, source?: string, options?: any): AxiosPromise<ResponseCategories> {
            return localVarFp.getListCategories(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListCustomField
         * @param {number} page 
         * @param {number} limit 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListCustomField(page: number, limit: number, source?: string, options?: any): AxiosPromise<GetListCustomField200Response> {
            return localVarFp.getListCustomField(page, limit, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListHoliday
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListHoliday(startTime: string, endTime: string, source?: string, options?: any): AxiosPromise<Array<Calendar>> {
            return localVarFp.getListHoliday(startTime, endTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListManagers
         * @param {FormFilterManagers} body getListManagers Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListManagers(body: FormFilterManagers, source?: string, options?: any): AxiosPromise<ReponsegetListManagers> {
            return localVarFp.getListManagers(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListModule
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListModule(source?: string, options?: any): AxiosPromise<Array<Modules>> {
            return localVarFp.getListModule(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getStructureCompany
         * @param {GetStructureCompanyRequest} body getStructureCompany Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructureCompany(body: GetStructureCompanyRequest, source?: string, options?: any): AxiosPromise<Array<StructureCompanyRespone>> {
            return localVarFp.getStructureCompany(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSystemSetting
         * @param {string} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemSetting(key: string, source?: string, options?: any): AxiosPromise<SystemSetting> {
            return localVarFp.getSystemSetting(key, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary importHoliday
         * @param {number} year 
         * @param {boolean} isDeletedPublicHoliday 
         * @param {string} [source] 
         * @param {object} [body] deleteCustomHoliday Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importHoliday(year: number, isDeletedPublicHoliday: boolean, source?: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.importHoliday(year, isDeletedPublicHoliday, source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary importPublicHoliday
         * @param {number} year 
         * @param {boolean} isDeletedPublicHoliday 
         * @param {boolean} changeShift 
         * @param {string} [source] 
         * @param {object} [body] deleteCustomHoliday Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPublicHoliday(year: number, isDeletedPublicHoliday: boolean, changeShift: boolean, source?: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.importPublicHoliday(year, isDeletedPublicHoliday, changeShift, source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateAnnotation
         * @param {number} id 
         * @param {FormEditAnnotation} body updateAnnotation Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnotation(id: number, body: FormEditAnnotation, source?: string, options?: any): AxiosPromise<Annotations> {
            return localVarFp.updateAnnotation(id, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateCategory
         * @param {number} id 
         * @param {FormUpdateCreateCategory} body updateCategory Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategory(id: number, body: FormUpdateCreateCategory, source?: string, options?: any): AxiosPromise<Categories> {
            return localVarFp.updateCategory(id, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateCompanySetting
         * @param {string} key 
         * @param {CompanySetting} body updateCompanySetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanySetting(key: string, body: CompanySetting, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateCompanySetting(key, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateCustomHoliday
         * @param {number} id 
         * @param {FormCalendar} body updateCustomHoliday Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomHoliday(id: number, body: FormCalendar, source?: string, options?: any): AxiosPromise<Calendar> {
            return localVarFp.updateCustomHoliday(id, body, source, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @summary createAnnotations
     * @param {FormAnnotation} body createAnnotations Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createAnnotations(body: FormAnnotation, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).createAnnotations(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createCategory
     * @param {FormUpdateCreateCategory} body createCategory Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createCategory(body: FormUpdateCreateCategory, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).createCategory(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createCustomHoliday
     * @param {FormCalendar} body createCustomHoliday Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createCustomHoliday(body: FormCalendar, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).createCustomHoliday(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createManagers
     * @param {Array<FormManagers>} body createManagers Detail type GROUP||DEPARTMENT
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createManagers(body: Array<FormManagers>, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).createManagers(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteAnnotation
     * @param {number} id 
     * @param {string} [source] 
     * @param {DeleteAnnotationRequest} [body] deleteAnnotation Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public deleteAnnotation(id: number, source?: string, body?: DeleteAnnotationRequest, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).deleteAnnotation(id, source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteCategory
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public deleteCategory(id: number, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).deleteCategory(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteCustomHoliday
     * @param {number} id 
     * @param {FormCalendar} body deleteCustomHoliday Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public deleteCustomHoliday(id: number, body: FormCalendar, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).deleteCustomHoliday(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteManagers
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public deleteManagers(id: number, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).deleteManagers(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllRole
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getAllRole(source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getAllRole(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getCompanyInformation
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanyInformation(source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanyInformation(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getCompanySetting
     * @param {string} key 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanySetting(key: string, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanySetting(key, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getEmployeeFormSetting
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getEmployeeFormSetting(source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getEmployeeFormSetting(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getFullCompanyStructureByTimestamp
     * @param {FormFullCompanyStructureByTimestamp} body getFullCompanyStructureByTimestamp Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getFullCompanyStructureByTimestamp(body: FormFullCompanyStructureByTimestamp, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getFullCompanyStructureByTimestamp(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getLastestTime
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getLastestTime(source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getLastestTime(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListCategories
     * @param {FormCategories} body getListCategories Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getListCategories(body: FormCategories, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getListCategories(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListCustomField
     * @param {number} page 
     * @param {number} limit 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getListCustomField(page: number, limit: number, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getListCustomField(page, limit, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListHoliday
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getListHoliday(startTime: string, endTime: string, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getListHoliday(startTime, endTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListManagers
     * @param {FormFilterManagers} body getListManagers Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getListManagers(body: FormFilterManagers, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getListManagers(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListModule
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getListModule(source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getListModule(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getStructureCompany
     * @param {GetStructureCompanyRequest} body getStructureCompany Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getStructureCompany(body: GetStructureCompanyRequest, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getStructureCompany(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSystemSetting
     * @param {string} key 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getSystemSetting(key: string, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getSystemSetting(key, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary importHoliday
     * @param {number} year 
     * @param {boolean} isDeletedPublicHoliday 
     * @param {string} [source] 
     * @param {object} [body] deleteCustomHoliday Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public importHoliday(year: number, isDeletedPublicHoliday: boolean, source?: string, body?: object, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).importHoliday(year, isDeletedPublicHoliday, source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary importPublicHoliday
     * @param {number} year 
     * @param {boolean} isDeletedPublicHoliday 
     * @param {boolean} changeShift 
     * @param {string} [source] 
     * @param {object} [body] deleteCustomHoliday Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public importPublicHoliday(year: number, isDeletedPublicHoliday: boolean, changeShift: boolean, source?: string, body?: object, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).importPublicHoliday(year, isDeletedPublicHoliday, changeShift, source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateAnnotation
     * @param {number} id 
     * @param {FormEditAnnotation} body updateAnnotation Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateAnnotation(id: number, body: FormEditAnnotation, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateAnnotation(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateCategory
     * @param {number} id 
     * @param {FormUpdateCreateCategory} body updateCategory Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCategory(id: number, body: FormUpdateCreateCategory, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCategory(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateCompanySetting
     * @param {string} key 
     * @param {CompanySetting} body updateCompanySetting Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompanySetting(key: string, body: CompanySetting, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCompanySetting(key, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateCustomHoliday
     * @param {number} id 
     * @param {FormCalendar} body updateCustomHoliday Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCustomHoliday(id: number, body: FormCalendar, source?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCustomHoliday(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }
}

