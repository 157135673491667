import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Popper } from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowDown,
  CalendarToday,
} from "@mui/icons-material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";

interface CustomMonthPickerProps {
  onChange?: (startOfMonth?: number, endOfMonth?: number) => void;
  start_time?: number;
}

const CustomMonthPicker: React.FC<CustomMonthPickerProps> = ({
  onChange,
  start_time,
}) => {
  const initialDate = start_time ? moment.unix(start_time) : moment();
  const [selectedMonth, setSelectedMonth] = useState<Moment>(initialDate);
  const [openPicker, setOpenPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (start_time) {
      setSelectedMonth(moment.unix(start_time));
    }
  }, [start_time]);

  const handleMonthChange = (newDate: Moment | null) => {
    if (newDate) {
      const startOfMonth = newDate.startOf("month").unix();
      const endOfMonth = newDate.endOf("month").unix();
      setSelectedMonth(newDate);
      setOpenPicker(false); // Close picker after selection
      if (onChange) {
        onChange(startOfMonth, endOfMonth);
      }
    }
  };

  const handlePreviousMonth = () => {
    handleMonthChange(selectedMonth.clone().subtract(1, "month"));
  };

  const handleNextMonth = () => {
    handleMonthChange(selectedMonth.clone().add(1, "month"));
  };

  const handleOpenPicker = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPicker((prev) => !prev);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton onClick={handlePreviousMonth}>
            <KeyboardArrowLeft />
          </IconButton>
          <Box
            display="flex"
            alignItems="center"
            sx={{ cursor: "pointer", color: "#1e74c7", fontWeight: "bold" }}
          >
            <Typography
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              variant="subtitle2"
              onClick={() => handleMonthChange(moment().startOf("month"))}
            >
              This month
            </Typography>
          </Box>
          <IconButton onClick={handleNextMonth}>
            <KeyboardArrowRight />
          </IconButton>

          <CalendarToday sx={{ fontSize: "13px" }} />
          <Typography
            onClick={handleOpenPicker}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            variant="subtitle2"
          >
            {`${selectedMonth
              .startOf("month")
              .format("DD/MM/YYYY")} - ${selectedMonth
              .endOf("month")
              .format("DD/MM/YYYY")}`}
          </Typography>
          <KeyboardArrowDown />
        </Box>

        <Popper open={openPicker} anchorEl={anchorEl} placement="bottom-start">
          <DatePicker
            open
            onClose={() => setOpenPicker(false)}
            views={["month", "year"]}
            value={selectedMonth}
            onChange={handleMonthChange}
            format="DD/MM/YYYY - DD/MM/YYYY"
            slotProps={{
              textField: {
                sx: {
                  opacity: 0,
                  pointerEvents: "none",
                  width: 0,
                  height: 0,
                  position: "absolute",
                },
              },
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -40],
                    },
                  },
                ],
              },
              desktopPaper: {
                sx: {
                  ".MuiPickersMonth-monthButton.Mui-selected": {
                    backgroundColor: "#08B0A0",
                    color: "#fff",
                  },
                  ".MuiPickersMonth-monthButton:hover": {
                    backgroundColor: "rgba(8, 176, 160, 0.5)",
                  },
                  minHeight: "250px",
                  maxHeight: "300px",
                  overflow: "hidden",
                },
              },
            }}
          />
        </Popper>
      </Box>
    </LocalizationProvider>
  );
};

export default CustomMonthPicker;
