import React, { useEffect } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import NoDataFound from "../../../components/NoDataFound";
import { Card } from "../../../components/Card";
import { useStores } from "../../../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";
import CategoryModal from "./CategoryModal";
import { Colors } from "../../../../../theme";

const Categories = observer(() => {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(0);
  const {
    templateStore: { getListTaskCategories, categories, isGetLoading },
  } = useStores();

  async function fetchData() {
    try {
      await getListTaskCategories();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isGetLoading && <CircularProgress size={24} />}
      {!isGetLoading && (
        <>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
              whiteSpace: "nowrap",
              justifyContent: "flex-end",
              marginBottom: "16px",
            }}
          >
            <Button
              style={{ fontSize: "13px", maxHeight: "36px" }}
              variant="contained"
              onClick={() => {
                setOpenModal(true);
                setSelectedId(0);
              }}
            >
              New category
            </Button>
          </Box>
          {categories?.length === 0 && <NoDataFound />}
          {categories && categories?.length > 0 && (
            <Box
              style={{
                display: "flex",
                width: "100%",
                gap: "16px",
                flexWrap: "wrap",
              }}
            >
              {categories.map((cL, index) => (
                <Box
                  key={index}
                  style={{
                    display: "block",
                    width: "30%",
                    textDecoration: "none",
                    flexBasis: "calc(33.333% - 16px)",
                  }}
                >
                  <Card
                    onClick={() => {
                      setOpenModal(true);
                      setSelectedId(cL.category_id);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                    ContentComponent={
                      <>
                        <Typography variant="subtitle2" color={Colors.black}>
                          {cL?.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            marginTop: "4px",
                            color: "gray",
                          }}
                        >
                          {(cL?.total_template_task ?? 0) < 1
                            ? `0 task`
                            : `${cL?.total_template_task} tasks`}
                        </Typography>
                      </>
                    }
                  />
                </Box>
              ))}
            </Box>
          )}
        </>
      )}
      <CategoryModal
        id={selectedId}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
});

export default Categories;
