/* tslint:disable */
/* eslint-disable */
/**
 * TitanPlan
 * TitanPlan API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { WidgetDto } from '../models';
/**
 * WorkingApi - axios parameter creator
 * @export
 */
export const WorkingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [departmentId] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workingLeavesGet: async (departmentId?: number, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Working/Leaves`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workingNoShowsGet: async (departmentId?: number, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Working/NoShows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workingPresentsGet: async (departmentId?: number, token?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Working/Presents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (token != null) {
                localVarHeaderParameter['token'] = String(token);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkingApi - functional programming interface
 * @export
 */
export const WorkingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [departmentId] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workingLeavesGet(departmentId?: number, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WidgetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workingLeavesGet(departmentId, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkingApi.workingLeavesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workingNoShowsGet(departmentId?: number, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WidgetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workingNoShowsGet(departmentId, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkingApi.workingNoShowsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workingPresentsGet(departmentId?: number, token?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WidgetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workingPresentsGet(departmentId, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkingApi.workingPresentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkingApi - factory interface
 * @export
 */
export const WorkingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkingApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [departmentId] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workingLeavesGet(departmentId?: number, token?: string, options?: any): AxiosPromise<Array<WidgetDto>> {
            return localVarFp.workingLeavesGet(departmentId, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workingNoShowsGet(departmentId?: number, token?: string, options?: any): AxiosPromise<Array<WidgetDto>> {
            return localVarFp.workingNoShowsGet(departmentId, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workingPresentsGet(departmentId?: number, token?: string, options?: any): AxiosPromise<Array<WidgetDto>> {
            return localVarFp.workingPresentsGet(departmentId, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkingApi - object-oriented interface
 * @export
 * @class WorkingApi
 * @extends {BaseAPI}
 */
export class WorkingApi extends BaseAPI {
    /**
     * 
     * @param {number} [departmentId] 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingApi
     */
    public workingLeavesGet(departmentId?: number, token?: string, options?: RawAxiosRequestConfig) {
        return WorkingApiFp(this.configuration).workingLeavesGet(departmentId, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [departmentId] 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingApi
     */
    public workingNoShowsGet(departmentId?: number, token?: string, options?: RawAxiosRequestConfig) {
        return WorkingApiFp(this.configuration).workingNoShowsGet(departmentId, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [departmentId] 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkingApi
     */
    public workingPresentsGet(departmentId?: number, token?: string, options?: RawAxiosRequestConfig) {
        return WorkingApiFp(this.configuration).workingPresentsGet(departmentId, token, options).then((request) => request(this.axios, this.basePath));
    }
}

