/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BlockWidgetMobile } from '../models';
// @ts-ignore
import type { ChangePass } from '../models';
// @ts-ignore
import type { DropProfile } from '../models';
// @ts-ignore
import type { EmployeeDetail } from '../models';
// @ts-ignore
import type { EmployeeDetailNew } from '../models';
// @ts-ignore
import type { EmployeeDetailV2 } from '../models';
// @ts-ignore
import type { EmployeeFormDataInvite } from '../models';
// @ts-ignore
import type { EmployeeSetting } from '../models';
// @ts-ignore
import type { EmployeeShort } from '../models';
// @ts-ignore
import type { EmployeeToken } from '../models';
// @ts-ignore
import type { FilterTimeSheet } from '../models';
// @ts-ignore
import type { FormFilterAllEmployee } from '../models';
// @ts-ignore
import type { FormFilterColleague } from '../models';
// @ts-ignore
import type { FormListEmployee } from '../models';
// @ts-ignore
import type { FormListEmployeeByGroups } from '../models';
// @ts-ignore
import type { FormParamV2 } from '../models';
// @ts-ignore
import type { FormSendMailResetPassword } from '../models';
// @ts-ignore
import type { GroupDetail } from '../models';
// @ts-ignore
import type { LogOutForm } from '../models';
// @ts-ignore
import type { Profile } from '../models';
// @ts-ignore
import type { ResponseCheckInvite } from '../models';
// @ts-ignore
import type { ResponseUpdateEmployee } from '../models';
// @ts-ignore
import type { ResultDataEmployees } from '../models';
// @ts-ignore
import type { UpdateEmployeeForm } from '../models';
// @ts-ignore
import type { UploadFileDetail } from '../models';
// @ts-ignore
import type { Users } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary changeDefaultCompany
         * @param {number} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDefaultCompany: async (key: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('changeDefaultCompany', 'key', key)
            const localVarPath = `/api/user/changeDefaultCompany/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changePassword
         * @param {ChangePass} body changePassword Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (body: ChangePass, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('changePassword', 'body', body)
            const localVarPath = `/api/user/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checkInviteEmail
         * @param {EmployeeFormDataInvite} body checkInviteEmail Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInviteEmail: async (body: EmployeeFormDataInvite, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('checkInviteEmail', 'body', body)
            const localVarPath = `/api/user/checkInviteEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createToken
         * @param {EmployeeToken} body createToken Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: async (body: EmployeeToken, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createToken', 'body', body)
            const localVarPath = `/api/user/createToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteNotiByCurrentEmployee
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotiByCurrentEmployee: async (source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notification/deleteNotiByCurrentEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteToken
         * @param {number} employeeId 
         * @param {string} uuid 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken: async (employeeId: number, uuid: string, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteToken', 'employeeId', employeeId)
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteToken', 'uuid', uuid)
            const localVarPath = `/api/user/deleteToken/{uuid}/{employeeId}`
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary dropProfile
         * @param {DropProfile} body DropProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropProfile: async (body: DropProfile, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('dropProfile', 'body', body)
            const localVarPath = `/api/user/dropProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllEmployees
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmployees: async (source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getAllEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDashboardInformation
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardInformation: async (minTime: string, maxTime: string, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getDashboardInformation', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getDashboardInformation', 'maxTime', maxTime)
            const localVarPath = `/api/user/getDashboardInformation/{min_time}/{max_time}`
                .replace(`{${"min_time"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"max_time"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDashboardWidgetMobile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardWidgetMobile: async (source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getDashboardWidgetMobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEmployeeByGroups
         * @param {FilterTimeSheet} body getEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeByGroups: async (body: FilterTimeSheet, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getEmployeeByGroups', 'body', body)
            const localVarPath = `/api/user/getEmployeeByGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEmployeeByID
         * @param {number} empId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeByID: async (empId: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'empId' is not null or undefined
            assertParamExists('getEmployeeByID', 'empId', empId)
            const localVarPath = `/api/user/getEmployeeByID/{empId}`
                .replace(`{${"empId"}}`, encodeURIComponent(String(empId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEmployeeSettings
         * @param {number} moduleId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeSettings: async (moduleId: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moduleId' is not null or undefined
            assertParamExists('getEmployeeSettings', 'moduleId', moduleId)
            const localVarPath = `/api/user/getEmployeeSettings/{moduleId}`
                .replace(`{${"moduleId"}}`, encodeURIComponent(String(moduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListBirthdayInSameGroup
         * @param {number} employeeID 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListBirthdayInSameGroup: async (employeeID: number, minTime: string, maxTime: string, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeID' is not null or undefined
            assertParamExists('getListBirthdayInSameGroup', 'employeeID', employeeID)
            // verify required parameter 'minTime' is not null or undefined
            assertParamExists('getListBirthdayInSameGroup', 'minTime', minTime)
            // verify required parameter 'maxTime' is not null or undefined
            assertParamExists('getListBirthdayInSameGroup', 'maxTime', maxTime)
            const localVarPath = `/api/user/getListBirthdayInSameGroup/{employeeID}/{min_time}/{max_time}`
                .replace(`{${"employeeID"}}`, encodeURIComponent(String(employeeID)))
                .replace(`{${"min_time"}}`, encodeURIComponent(String(minTime)))
                .replace(`{${"max_time"}}`, encodeURIComponent(String(maxTime)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListEmployee
         * @param {FormListEmployee} body getListEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployee: async (body: FormListEmployee, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListEmployee', 'body', body)
            const localVarPath = `/api/user/getListEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListEmployeeByGroups
         * @param {FormListEmployeeByGroups} body ListEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployeeByGroups: async (body: FormListEmployeeByGroups, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListEmployeeByGroups', 'body', body)
            const localVarPath = `/api/user/getListEmployeeByGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListEmployeeColleague
         * @param {FormFilterColleague} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployeeColleague: async (body: FormFilterColleague, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListEmployeeColleague', 'body', body)
            const localVarPath = `/api/user/getListEmployeeColleague`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListEmployees
         * @param {FormParamV2} body getListEmployees Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployees: async (body: FormParamV2, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('getListEmployees', 'body', body)
            const localVarPath = `/api/user/getListEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getUserProfile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: async (source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary inviteEmployee
         * @param {EmployeeFormDataInvite} body inviteEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteEmployee: async (body: EmployeeFormDataInvite, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('inviteEmployee', 'body', body)
            const localVarPath = `/api/user/inviteEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary logout
         * @param {LogOutForm} body Logout Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (body: LogOutForm, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('logout', 'body', body)
            const localVarPath = `/api/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary orderEmployeeSettingMobile
         * @param {Array<BlockWidgetMobile>} body orderEmployeeSettingMobile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderEmployeeSettingMobile: async (body: Array<BlockWidgetMobile>, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('orderEmployeeSettingMobile', 'body', body)
            const localVarPath = `/api/user/orderEmployeeSettingMobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveFileForMessenger
         * @param {string} [source] 
         * @param {File} [file] image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFileForMessenger: async (source?: string, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/saveFileForMessenger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveImage
         * @param {string} [source] 
         * @param {File} [file] image
         * @param {number} [clockType] clock_type
         * @param {number} [type] type
         * @param {number} [id] id
         * @param {string} [ids] [1,2,3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveImage: async (source?: string, file?: File, clockType?: number, type?: number, id?: number, ids?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/saveImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (clockType !== undefined) { 
                localVarFormParams.append('clock_type', clockType as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
            if (ids !== undefined) { 
                localVarFormParams.append('ids', ids as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchAllEmployee
         * @param {FormFilterAllEmployee} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllEmployee: async (body: FormFilterAllEmployee, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('searchAllEmployee', 'body', body)
            const localVarPath = `/api/user/searchAllEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sendMailResetPassword API.
         * @summary sendMailResetPassword API
         * @param {FormSendMailResetPassword} body sendMailResetPassword Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailResetPassword: async (body: FormSendMailResetPassword, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sendMailResetPassword', 'body', body)
            const localVarPath = `/api/user/sendMailResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateEmployee
         * @param {number} id 
         * @param {UpdateEmployeeForm} body updateEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee: async (id: number, body: UpdateEmployeeForm, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEmployee', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateEmployee', 'body', body)
            const localVarPath = `/api/user/updateEmployee/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateEmployeeFireBase
         * @param {object} body updateEmployeeFireBase Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeFireBase: async (body: object, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateEmployeeFireBase', 'body', body)
            const localVarPath = `/api/user/updateEmployeeFireBase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateEmployeeSetting
         * @param {number} employeeSettingId 
         * @param {EmployeeSetting} body updateEmployeeSetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeSetting: async (employeeSettingId: number, body: EmployeeSetting, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeSettingId' is not null or undefined
            assertParamExists('updateEmployeeSetting', 'employeeSettingId', employeeSettingId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateEmployeeSetting', 'body', body)
            const localVarPath = `/api/user/updateEmployeeSetting/{employee_setting_id}`
                .replace(`{${"employee_setting_id"}}`, encodeURIComponent(String(employeeSettingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateEmployeeSettingMobile
         * @param {BlockWidgetMobile} body updateEmployeeSettingMobile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeSettingMobile: async (body: BlockWidgetMobile, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateEmployeeSettingMobile', 'body', body)
            const localVarPath = `/api/user/updateEmployeeSettingMobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateUserProfile
         * @param {Profile} body updateUserProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (body: Profile, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUserProfile', 'body', body)
            const localVarPath = `/api/user/updateUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadFile
         * @param {string} [source] 
         * @param {File} [file] image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (source?: string, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/uploadFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary changeDefaultCompany
         * @param {number} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeDefaultCompany(key: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeDefaultCompany(key, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.changeDefaultCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary changePassword
         * @param {ChangePass} body changePassword Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(body: ChangePass, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.changePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary checkInviteEmail
         * @param {EmployeeFormDataInvite} body checkInviteEmail Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkInviteEmail(body: EmployeeFormDataInvite, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCheckInvite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkInviteEmail(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.checkInviteEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary createToken
         * @param {EmployeeToken} body createToken Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createToken(body: EmployeeToken, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createToken(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.createToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deleteNotiByCurrentEmployee
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotiByCurrentEmployee(source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotiByCurrentEmployee(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteNotiByCurrentEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deleteToken
         * @param {number} employeeId 
         * @param {string} uuid 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteToken(employeeId: number, uuid: string, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteToken(employeeId, uuid, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary dropProfile
         * @param {DropProfile} body DropProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dropProfile(body: DropProfile, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dropProfile(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.dropProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getAllEmployees
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEmployees(source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeShort>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEmployees(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getAllEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getDashboardInformation
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardInformation(minTime: string, maxTime: string, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardInformation(minTime, maxTime, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getDashboardInformation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getDashboardWidgetMobile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardWidgetMobile(source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockWidgetMobile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardWidgetMobile(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getDashboardWidgetMobile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getEmployeeByGroups
         * @param {FilterTimeSheet} body getEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeByGroups(body: FilterTimeSheet, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeDetailNew>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeByGroups(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getEmployeeByGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getEmployeeByID
         * @param {number} empId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeByID(empId: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeByID(empId, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getEmployeeByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getEmployeeSettings
         * @param {number} moduleId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeSettings(moduleId: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeSetting>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeSettings(moduleId, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getEmployeeSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListBirthdayInSameGroup
         * @param {number} employeeID 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListBirthdayInSameGroup(employeeID: number, minTime: string, maxTime: string, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListBirthdayInSameGroup(employeeID, minTime, maxTime, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getListBirthdayInSameGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListEmployee
         * @param {FormListEmployee} body getListEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListEmployee(body: FormListEmployee, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDataEmployees>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListEmployee(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getListEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListEmployeeByGroups
         * @param {FormListEmployeeByGroups} body ListEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListEmployeeByGroups(body: FormListEmployeeByGroups, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListEmployeeByGroups(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getListEmployeeByGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListEmployeeColleague
         * @param {FormFilterColleague} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListEmployeeColleague(body: FormFilterColleague, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDataEmployees>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListEmployeeColleague(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getListEmployeeColleague']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListEmployees
         * @param {FormParamV2} body getListEmployees Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListEmployees(body: FormParamV2, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeDetailV2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListEmployees(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getListEmployees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getUserProfile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfile(source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary inviteEmployee
         * @param {EmployeeFormDataInvite} body inviteEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteEmployee(body: EmployeeFormDataInvite, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeShort>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteEmployee(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.inviteEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary logout
         * @param {LogOutForm} body Logout Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(body: LogOutForm, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.logout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary orderEmployeeSettingMobile
         * @param {Array<BlockWidgetMobile>} body orderEmployeeSettingMobile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderEmployeeSettingMobile(body: Array<BlockWidgetMobile>, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockWidgetMobile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderEmployeeSettingMobile(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.orderEmployeeSettingMobile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary saveFileForMessenger
         * @param {string} [source] 
         * @param {File} [file] image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveFileForMessenger(source?: string, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveFileForMessenger(source, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.saveFileForMessenger']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary saveImage
         * @param {string} [source] 
         * @param {File} [file] image
         * @param {number} [clockType] clock_type
         * @param {number} [type] type
         * @param {number} [id] id
         * @param {string} [ids] [1,2,3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveImage(source?: string, file?: File, clockType?: number, type?: number, id?: number, ids?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveImage(source, file, clockType, type, id, ids, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.saveImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary searchAllEmployee
         * @param {FormFilterAllEmployee} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAllEmployee(body: FormFilterAllEmployee, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultDataEmployees>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAllEmployee(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.searchAllEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * sendMailResetPassword API.
         * @summary sendMailResetPassword API
         * @param {FormSendMailResetPassword} body sendMailResetPassword Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMailResetPassword(body: FormSendMailResetPassword, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMailResetPassword(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.sendMailResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateEmployee
         * @param {number} id 
         * @param {UpdateEmployeeForm} body updateEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployee(id: number, body: UpdateEmployeeForm, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUpdateEmployee>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployee(id, body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateEmployeeFireBase
         * @param {object} body updateEmployeeFireBase Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployeeFireBase(body: object, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployeeFireBase(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateEmployeeFireBase']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateEmployeeSetting
         * @param {number} employeeSettingId 
         * @param {EmployeeSetting} body updateEmployeeSetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployeeSetting(employeeSettingId: number, body: EmployeeSetting, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployeeSetting(employeeSettingId, body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateEmployeeSetting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateEmployeeSettingMobile
         * @param {BlockWidgetMobile} body updateEmployeeSettingMobile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEmployeeSettingMobile(body: BlockWidgetMobile, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockWidgetMobile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEmployeeSettingMobile(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateEmployeeSettingMobile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateUserProfile
         * @param {Profile} body updateUserProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(body: Profile, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary uploadFile
         * @param {string} [source] 
         * @param {File} [file] image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(source?: string, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(source, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.uploadFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary changeDefaultCompany
         * @param {number} key 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDefaultCompany(key: number, source?: string, options?: any): AxiosPromise<Users> {
            return localVarFp.changeDefaultCompany(key, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary changePassword
         * @param {ChangePass} body changePassword Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(body: ChangePass, source?: string, options?: any): AxiosPromise<Users> {
            return localVarFp.changePassword(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary checkInviteEmail
         * @param {EmployeeFormDataInvite} body checkInviteEmail Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkInviteEmail(body: EmployeeFormDataInvite, source?: string, options?: any): AxiosPromise<ResponseCheckInvite> {
            return localVarFp.checkInviteEmail(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createToken
         * @param {EmployeeToken} body createToken Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken(body: EmployeeToken, source?: string, options?: any): AxiosPromise<EmployeeToken> {
            return localVarFp.createToken(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteNotiByCurrentEmployee
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotiByCurrentEmployee(source?: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteNotiByCurrentEmployee(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteToken
         * @param {number} employeeId 
         * @param {string} uuid 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken(employeeId: number, uuid: string, source?: string, options?: any): AxiosPromise<EmployeeToken> {
            return localVarFp.deleteToken(employeeId, uuid, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary dropProfile
         * @param {DropProfile} body DropProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropProfile(body: DropProfile, source?: string, options?: any): AxiosPromise<string> {
            return localVarFp.dropProfile(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getAllEmployees
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmployees(source?: string, options?: any): AxiosPromise<Array<EmployeeShort>> {
            return localVarFp.getAllEmployees(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDashboardInformation
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardInformation(minTime: string, maxTime: string, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getDashboardInformation(minTime, maxTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDashboardWidgetMobile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardWidgetMobile(source?: string, options?: any): AxiosPromise<Array<BlockWidgetMobile>> {
            return localVarFp.getDashboardWidgetMobile(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getEmployeeByGroups
         * @param {FilterTimeSheet} body getEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeByGroups(body: FilterTimeSheet, source?: string, options?: any): AxiosPromise<Array<EmployeeDetailNew>> {
            return localVarFp.getEmployeeByGroups(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getEmployeeByID
         * @param {number} empId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeByID(empId: number, source?: string, options?: any): AxiosPromise<EmployeeDetail> {
            return localVarFp.getEmployeeByID(empId, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getEmployeeSettings
         * @param {number} moduleId 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeSettings(moduleId: number, source?: string, options?: any): AxiosPromise<Array<EmployeeSetting>> {
            return localVarFp.getEmployeeSettings(moduleId, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListBirthdayInSameGroup
         * @param {number} employeeID 
         * @param {string} minTime 
         * @param {string} maxTime 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListBirthdayInSameGroup(employeeID: number, minTime: string, maxTime: string, source?: string, options?: any): AxiosPromise<Array<EmployeeDetail>> {
            return localVarFp.getListBirthdayInSameGroup(employeeID, minTime, maxTime, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListEmployee
         * @param {FormListEmployee} body getListEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployee(body: FormListEmployee, source?: string, options?: any): AxiosPromise<ResultDataEmployees> {
            return localVarFp.getListEmployee(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListEmployeeByGroups
         * @param {FormListEmployeeByGroups} body ListEmployeeByGroups Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployeeByGroups(body: FormListEmployeeByGroups, source?: string, options?: any): AxiosPromise<Array<GroupDetail>> {
            return localVarFp.getListEmployeeByGroups(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListEmployeeColleague
         * @param {FormFilterColleague} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployeeColleague(body: FormFilterColleague, source?: string, options?: any): AxiosPromise<ResultDataEmployees> {
            return localVarFp.getListEmployeeColleague(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListEmployees
         * @param {FormParamV2} body getListEmployees Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListEmployees(body: FormParamV2, source?: string, options?: any): AxiosPromise<Array<EmployeeDetailV2>> {
            return localVarFp.getListEmployees(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getUserProfile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(source?: string, options?: any): AxiosPromise<Profile> {
            return localVarFp.getUserProfile(source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary inviteEmployee
         * @param {EmployeeFormDataInvite} body inviteEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteEmployee(body: EmployeeFormDataInvite, source?: string, options?: any): AxiosPromise<EmployeeShort> {
            return localVarFp.inviteEmployee(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary logout
         * @param {LogOutForm} body Logout Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(body: LogOutForm, source?: string, options?: any): AxiosPromise<string> {
            return localVarFp.logout(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary orderEmployeeSettingMobile
         * @param {Array<BlockWidgetMobile>} body orderEmployeeSettingMobile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderEmployeeSettingMobile(body: Array<BlockWidgetMobile>, source?: string, options?: any): AxiosPromise<Array<BlockWidgetMobile>> {
            return localVarFp.orderEmployeeSettingMobile(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveFileForMessenger
         * @param {string} [source] 
         * @param {File} [file] image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFileForMessenger(source?: string, file?: File, options?: any): AxiosPromise<UploadFileDetail> {
            return localVarFp.saveFileForMessenger(source, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveImage
         * @param {string} [source] 
         * @param {File} [file] image
         * @param {number} [clockType] clock_type
         * @param {number} [type] type
         * @param {number} [id] id
         * @param {string} [ids] [1,2,3]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveImage(source?: string, file?: File, clockType?: number, type?: number, id?: number, ids?: string, options?: any): AxiosPromise<string> {
            return localVarFp.saveImage(source, file, clockType, type, id, ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchAllEmployee
         * @param {FormFilterAllEmployee} body getListEmployeeColleague Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllEmployee(body: FormFilterAllEmployee, source?: string, options?: any): AxiosPromise<ResultDataEmployees> {
            return localVarFp.searchAllEmployee(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * sendMailResetPassword API.
         * @summary sendMailResetPassword API
         * @param {FormSendMailResetPassword} body sendMailResetPassword Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMailResetPassword(body: FormSendMailResetPassword, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.sendMailResetPassword(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateEmployee
         * @param {number} id 
         * @param {UpdateEmployeeForm} body updateEmployee Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployee(id: number, body: UpdateEmployeeForm, source?: string, options?: any): AxiosPromise<ResponseUpdateEmployee> {
            return localVarFp.updateEmployee(id, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateEmployeeFireBase
         * @param {object} body updateEmployeeFireBase Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeFireBase(body: object, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateEmployeeFireBase(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateEmployeeSetting
         * @param {number} employeeSettingId 
         * @param {EmployeeSetting} body updateEmployeeSetting Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeSetting(employeeSettingId: number, body: EmployeeSetting, source?: string, options?: any): AxiosPromise<EmployeeSetting> {
            return localVarFp.updateEmployeeSetting(employeeSettingId, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateEmployeeSettingMobile
         * @param {BlockWidgetMobile} body updateEmployeeSettingMobile
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmployeeSettingMobile(body: BlockWidgetMobile, source?: string, options?: any): AxiosPromise<Array<BlockWidgetMobile>> {
            return localVarFp.updateEmployeeSettingMobile(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateUserProfile
         * @param {Profile} body updateUserProfile Detail
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(body: Profile, source?: string, options?: any): AxiosPromise<Profile> {
            return localVarFp.updateUserProfile(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploadFile
         * @param {string} [source] 
         * @param {File} [file] image
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(source?: string, file?: File, options?: any): AxiosPromise<string> {
            return localVarFp.uploadFile(source, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary changeDefaultCompany
     * @param {number} key 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeDefaultCompany(key: number, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).changeDefaultCompany(key, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary changePassword
     * @param {ChangePass} body changePassword Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changePassword(body: ChangePass, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).changePassword(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary checkInviteEmail
     * @param {EmployeeFormDataInvite} body checkInviteEmail Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public checkInviteEmail(body: EmployeeFormDataInvite, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).checkInviteEmail(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createToken
     * @param {EmployeeToken} body createToken Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createToken(body: EmployeeToken, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).createToken(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteNotiByCurrentEmployee
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteNotiByCurrentEmployee(source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteNotiByCurrentEmployee(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteToken
     * @param {number} employeeId 
     * @param {string} uuid 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteToken(employeeId: number, uuid: string, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteToken(employeeId, uuid, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary dropProfile
     * @param {DropProfile} body DropProfile Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public dropProfile(body: DropProfile, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).dropProfile(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getAllEmployees
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllEmployees(source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllEmployees(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDashboardInformation
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getDashboardInformation(minTime: string, maxTime: string, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getDashboardInformation(minTime, maxTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDashboardWidgetMobile
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getDashboardWidgetMobile(source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getDashboardWidgetMobile(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getEmployeeByGroups
     * @param {FilterTimeSheet} body getEmployeeByGroups Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getEmployeeByGroups(body: FilterTimeSheet, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getEmployeeByGroups(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getEmployeeByID
     * @param {number} empId 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getEmployeeByID(empId: number, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getEmployeeByID(empId, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getEmployeeSettings
     * @param {number} moduleId 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getEmployeeSettings(moduleId: number, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getEmployeeSettings(moduleId, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListBirthdayInSameGroup
     * @param {number} employeeID 
     * @param {string} minTime 
     * @param {string} maxTime 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getListBirthdayInSameGroup(employeeID: number, minTime: string, maxTime: string, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getListBirthdayInSameGroup(employeeID, minTime, maxTime, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListEmployee
     * @param {FormListEmployee} body getListEmployee Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getListEmployee(body: FormListEmployee, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getListEmployee(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListEmployeeByGroups
     * @param {FormListEmployeeByGroups} body ListEmployeeByGroups Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getListEmployeeByGroups(body: FormListEmployeeByGroups, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getListEmployeeByGroups(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListEmployeeColleague
     * @param {FormFilterColleague} body getListEmployeeColleague Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getListEmployeeColleague(body: FormFilterColleague, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getListEmployeeColleague(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListEmployees
     * @param {FormParamV2} body getListEmployees Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getListEmployees(body: FormParamV2, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getListEmployees(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getUserProfile
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserProfile(source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserProfile(source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary inviteEmployee
     * @param {EmployeeFormDataInvite} body inviteEmployee Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public inviteEmployee(body: EmployeeFormDataInvite, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).inviteEmployee(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary logout
     * @param {LogOutForm} body Logout Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public logout(body: LogOutForm, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).logout(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary orderEmployeeSettingMobile
     * @param {Array<BlockWidgetMobile>} body orderEmployeeSettingMobile
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public orderEmployeeSettingMobile(body: Array<BlockWidgetMobile>, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).orderEmployeeSettingMobile(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveFileForMessenger
     * @param {string} [source] 
     * @param {File} [file] image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public saveFileForMessenger(source?: string, file?: File, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).saveFileForMessenger(source, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveImage
     * @param {string} [source] 
     * @param {File} [file] image
     * @param {number} [clockType] clock_type
     * @param {number} [type] type
     * @param {number} [id] id
     * @param {string} [ids] [1,2,3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public saveImage(source?: string, file?: File, clockType?: number, type?: number, id?: number, ids?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).saveImage(source, file, clockType, type, id, ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchAllEmployee
     * @param {FormFilterAllEmployee} body getListEmployeeColleague Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public searchAllEmployee(body: FormFilterAllEmployee, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).searchAllEmployee(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * sendMailResetPassword API.
     * @summary sendMailResetPassword API
     * @param {FormSendMailResetPassword} body sendMailResetPassword Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendMailResetPassword(body: FormSendMailResetPassword, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).sendMailResetPassword(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateEmployee
     * @param {number} id 
     * @param {UpdateEmployeeForm} body updateEmployee Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateEmployee(id: number, body: UpdateEmployeeForm, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateEmployee(id, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateEmployeeFireBase
     * @param {object} body updateEmployeeFireBase Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateEmployeeFireBase(body: object, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateEmployeeFireBase(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateEmployeeSetting
     * @param {number} employeeSettingId 
     * @param {EmployeeSetting} body updateEmployeeSetting Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateEmployeeSetting(employeeSettingId: number, body: EmployeeSetting, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateEmployeeSetting(employeeSettingId, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateEmployeeSettingMobile
     * @param {BlockWidgetMobile} body updateEmployeeSettingMobile
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateEmployeeSettingMobile(body: BlockWidgetMobile, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateEmployeeSettingMobile(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateUserProfile
     * @param {Profile} body updateUserProfile Detail
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserProfile(body: Profile, source?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserProfile(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploadFile
     * @param {string} [source] 
     * @param {File} [file] image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadFile(source?: string, file?: File, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).uploadFile(source, file, options).then((request) => request(this.axios, this.basePath));
    }
}

