import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Colors } from "../../../../theme";

const Reports = () => {
  const [selected, setSelected] = useState<string>("Checklist progress");
  const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = [
    {
      link: "/checklist/reports/checklist",
      title: "Checklists progress",
    },
    {
      link: "/checklist/reports/employee",
      title: "Employee activity",
    },
    {
      link: "/checklist/reports/monthly",
      title: "Monthly reports",
    },
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingItem = items.find((item) => currentPath.includes(item.link));
    if (matchingItem) {
      setSelected(matchingItem.title);
    }
  }, [location, items]);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Typography color={Colors.black} variant="h6">
            REPORTS
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            gap: "40px",
            flex: 1,
          }}
        >
          {items.map((item) => (
            <Link
              key={item.title}
              to={item.link}
              style={{
                fontWeight: "400",
                fontSize: "13px",
                cursor: "pointer",
                textDecoration: "none",
                color: selected === item.title ? Colors.primary : Colors.black,
                whiteSpace: "nowrap",
              }}
              onClick={() => setSelected(item.title)}
            >
              {item.title}
            </Link>
          ))}
        </Box>
      </Box>
      <Box
        style={{
          width: "100%",
          borderBottom: "2px solid #E0E0E0",
          marginTop: "24px",
          marginBottom: "10px",
        }}
      />
      <Outlet />
    </Box>
  );
};

export default Reports;
