import React, { memo } from 'react';
interface avatarProp {
    image: string,
    color: string,
    name: string,
    isSquare?: boolean
}
const Avatar = memo(({ image, color, name, isSquare = false }: avatarProp) => {
    const getInitials = (name: string) => {
        const nameParts = name.trim().split(' ');
        if (nameParts.length === 1) {
            return nameParts[0].substring(0, 2).toUpperCase();
        }
        return (nameParts[0][0] + nameParts[1][0]).toUpperCase();
    };

    const styles = {
        avatar: {
            width: isSquare? '44px' : '46px',
            height: isSquare? '44px' : '46px',
            borderRadius: isSquare? '12px' : '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '18px',
            color: 'white',
            backgroundColor: color,
        },
        image: {
            width: isSquare? '44px' : '46px',
            height: isSquare? '44px' : '46px',
            'object-fit': 'cover',
            borderRadius: isSquare? '12px' : '24px',
        },
    };

    return (
        <div style={styles.avatar}>
            {image ? (
                <img src={image} alt="Avatar" style={styles.image} />
            ) : (
                getInitials(name)
            )}
        </div>
    );
});

export default Avatar;
