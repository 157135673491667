import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Task, TasksApi } from "../../../services/phpServices";
import { CustomListItem } from "./ListItem";
import { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Colors } from "../../../theme";

interface RequiredModalProps {
  open: boolean;
  onClose: () => void;
  task: Task | null;
  dateTime: number;
  onConfirm: () => void
}

const RequiredModal: React.FC<RequiredModalProps> = ({
  open,
  onClose,
  task,
  dateTime,
  onConfirm
}) => {
  const [dataNote, setDataNote] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e: any) => {
    const newImage = e.target.files[0];
    if (newImage) {
      setImages((prevImages) => [...prevImages, newImage]);
    }
  };

  const handleIconClick = () => {
    document?.getElementById("image-upload")?.click();
  };

  const removeImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  async function complete() {
    try {
      setLoading(true);
      onClose();
      const newStatus =
        task?.complete_status === "COMPLETED" ? "UNCOMPLETED" : "COMPLETED";
      await new TasksApi().changeStatusCompleteTask(
        task?.task_id ?? 0,
        dateTime,
        "web",
        images,
        newStatus,
        dataNote
      );
      setLoading(false);
      onConfirm()
    } catch (e) {
      //
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          backgroundColor: "white",
          borderRadius: "4px",
          boxShadow: 24,
          width: "500px",
          maxHeight: "80vh",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          flexDirection: "column",
        }}
      >
      {loading && <CircularProgress size={24} />}
        <Box
          sx={{
            height: "40px",
            backgroundColor: "#08B0A0",
            display: "flex",
            alignItems: "center",
            borderRadius: "4px 4px 0 0",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" color="white" sx={{ paddingLeft: "16px" }}>
            {task?.name?.toUpperCase()}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: "white", width: 24, height: 24 }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "20px",
            overflowY: "auto",
            maxHeight: "calc(80vh - 40px)",
            marginTop: "16px",
            flex: 1,
          }}
        >
          {task?.note === 1 && (
            <CustomListItem
              leftContainerWidth={150}
              text="Required note*"
              containerStyle={{ marginBottom: "32px" }}
              RightComponent={
                <TextField
                  id="task-name"
                  label="Data note"
                  multiline
                  variant="standard"
                  style={{ width: "100%", fontSize: "13px" }}
                  onChange={(e) => setDataNote(e.target.value)}
                  value={dataNote}
                  sx={{
                    "& .MuiInputLabel-root": {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                  }}
                />
              }
            />
          )}
          {task?.photo === 1 && (
            <>
              <Typography
                variant="subtitle2"
                color={Colors.black}
              >
                Required photo*
              </Typography>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <input
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />

                {images.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      marginRight: "32px",
                      marginTop: "16px",
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Selected preview ${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                        objectFit: "cover",
                      }}
                    />
                    <IconButton
                      onClick={() => removeImage(index)}
                      style={{
                        position: "absolute",
                        top: "-8px",
                        right: "-8px",
                        backgroundColor: "gray",
                        borderRadius: "50%",
                        padding: "4px",
                      }}
                    >
                      <CloseIcon style={{ color: "white", fontSize: "16px" }} />
                    </IconButton>
                  </div>
                ))}

                <AddCircleOutlineIcon
                  style={{
                    marginTop: "24px",
                    color: "#636363",
                    cursor: "pointer",
                    marginLeft: "32px",
                  }}
                  fontSize="large"
                  onClick={handleIconClick}
                />
              </div>
            </>
          )}
        </Box>
        <Box
          sx={{
            justifyContent: "flex-end",
            display: "flex",
            paddingRight: "20px",
            paddingBottom: "20px",
            paddingTop: "20px",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <Button
            variant="outlined"
            style={{ color: "gray", borderColor: "gray" }}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={complete}
            disabled={
              (task?.note === 1 && dataNote.trim() === "") ||
              images.length === 0
            }
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RequiredModal;
