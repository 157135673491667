import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { MyChecklistStoreModel } from "./modules/MyChecklistStore"
import { AllChecklistStoreModel } from "./modules/AllChecklistStore"
import { TemplateStoreModel } from "./modules/TemplateStore"
import { ChecklistReportStoreModel } from "./modules/ChecklistReportStore"
import { AppStoreModel } from "./AppStore"

/**
 * A RootStore model.
 */
export const RootStoreModel = types.model("RootStore").props({
    // App
    appStore: types.optional(AppStoreModel, {}),

    // Checklist
    myChecklistStore: types.optional(MyChecklistStoreModel, {}),
    allChecklistStore: types.optional(AllChecklistStoreModel, {}),
    templateStore: types.optional(TemplateStoreModel, {}),
    checklistReportStore: types.optional(ChecklistReportStoreModel, {}),
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}
/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
