import { Box, CircularProgress, Typography } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import CustomMonthPicker from "../../../components/MonthPicker";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../models/helpers/useStores";
import { useEffect } from "react";
import moment from "moment";
import NoDataFound from "../../../components/NoDataFound";
import { Card } from "../../../components/Card";
import { Colors } from "../../../../../theme";

const EmployeeDateReport = observer(() => {
  const params = useParams();
  const {
    checklistReportStore: {
      isGetLoading,
      filterEmp,
      dateInTasks,
      listEmpDateReport,
    },
  } = useStores();

  useEffect(() => {
    dateInTasks({
      employee_ids: [Number(params.empId)],
      start_time: filterEmp.start_time ?? moment().startOf("month").unix(),
      end_time: filterEmp.end_time ?? moment().endOf("month").unix(),
    });
  }, []);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      ></Box>
      <CustomMonthPicker
        start_time={filterEmp.start_time}
        onChange={(st, et) => {
          dateInTasks({
            ...filterEmp,
            start_time: st,
            end_time: et,
          });
        }}
      />
      {isGetLoading && <CircularProgress size={24} />}
      {!isGetLoading && listEmpDateReport.length === 0 && <NoDataFound />}
      {!isGetLoading && listEmpDateReport.length > 0 && (
        <Box sx={{ marginTop: "12px" }}>
          {[...listEmpDateReport]
            .sort((a, b) => b.percent - a.percent)
            .map((item) => (
              <Card
                disabled
                key={item.date}
                ContentComponent={
                  <Typography variant="h5">
                    {moment.unix(item.date).format("DD/MM/YYYY")}
                  </Typography>
                }
                RightComponent={
                  <Typography variant="h5" color={Colors.primary}>
                    {item.percent}%
                  </Typography>
                }
              />
            ))}
        </Box>
      )}
      <Outlet />
    </Box>
  );
});

export default EmployeeDateReport;
