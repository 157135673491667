import React, { ReactElement, CSSProperties } from "react";

// Type definition for the component props
interface ListItemProps {
  /**
   * Text to display on the left side if not using `tx` or nested components.
   */
  text?: string;
  /**
   * Optional text style override.
   */
  textStyle?: CSSProperties;
  /**
   * Optional container style override.
   */
  containerStyle?: CSSProperties;
  /**
   * Right action custom ReactElement (any component).
   */
  RightComponent?: ReactElement;
  /**
   * Optional max-width for left container.
   */
  leftContainerWidth?: string | number; // Add a customizable left container width
  /**
   * Optional flag to prevent centering the left text vertically.
   */
  noCentered?: boolean; // Add the `noCentered` flag
}

/**
 * A styled row component that can be used in lists or by itself.
 * @param {ListItemProps} props
 * @returns {JSX.Element}
 */
export function CustomListItem({
  text,
  textStyle,
  containerStyle,
  RightComponent,
  leftContainerWidth = "200px",
  noCentered = false, // Default to false
}: ListItemProps) {
  return (
    <div
      style={{
        ...styles.container,
        ...containerStyle,
        alignItems: noCentered ? "stretch" : "center",
      }}
    >
      {/* Left container with fixed width */}
      <div
        style={{
          ...styles.leftContainer,
          width: leftContainerWidth,
          alignItems: noCentered ? "flex-start" : "",
        }}
      >
        <span style={{ ...styles.text, ...textStyle }}>{text}</span>
      </div>

      {/* Right container aligned immediately next to the left container */}
      <div style={{ ...styles.rightContainer }}>{RightComponent}</div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex" as "flex",
    flexDirection: "row" as "row",
    gap: "8px",
    width: "100%",
  } as CSSProperties,
  leftContainer: {
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    flexShrink: 0,
    display: "flex",
  } as CSSProperties,
  text: {
    color: "#636363",
    fontSize: "13px",
    fontWeight: 400,
    wordWrap: "break-word",
  } as CSSProperties,
  rightContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  } as CSSProperties,
};
