import moment from "moment-timezone";
import { CommonApi } from "./netServices";
import { DefaultTimeZone } from "../common/dateTime";

export function processAPIURL(
  urlAPI: string | undefined | null,
  urlReport: string | undefined | null
) {
  if (urlAPI) localStorage.setItem("PHPAPI", urlAPI);
  if (urlReport) localStorage.setItem("NETAPI", urlReport);
}

export function saveToken(token: string | undefined) {
  if (token) localStorage.setItem("TOKEN", token);
}
export function getToken() {
  return localStorage.getItem("TOKEN");
}
export function saveUrl(url: string | undefined) {
  if (url) localStorage.setItem("URL", url);
}
export function getUrl() {
  return localStorage.getItem("URL");
}

export async function initUser() {
  try {
    const { data } = await new CommonApi().commonGetUserProfileGet();
    const companyInformation = await new CommonApi().commonGetCompanyInformationGet().then((response) => response.data);
    const timeZone = companyInformation.Timezone ?? DefaultTimeZone
    moment.updateLocale('en', {
      week: {
        dow: companyInformation.FirstDayOfWeek ?? 0,
      },
    })

    moment.tz.setDefault(timeZone)
    moment.tz(timeZone)
    console.log(timeZone)

    localStorage.setItem("USER", JSON.stringify(data));
    processAPIURL(data.UrlApi, data.UrlReport);

  } catch {
    alert('Error! Please try again or contact your admin!')
  }
}
