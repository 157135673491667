import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CheckCircleOutline, CircleOutlined } from "@mui/icons-material";
import { CheckListTemplateDataInner } from "../../../../../services/phpServices";
import { Card } from "../../../components/Card";
import NoDataFound from "../../../components/NoDataFound";
import { useStores } from "../../../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";
import { Colors } from "../../../../../theme";

interface ChecklistContentProps {
  onConfirmed?: (c: any) => void;
  onClose?: () => void;
}

const ImportChecklistContent: React.FC<ChecklistContentProps> = observer(
  ({ onConfirmed, onClose }) => {
    const [selected, setSelected] =
      React.useState<CheckListTemplateDataInner>();
    const {
      templateStore: {
        checklistTemplates,
        getListChecklistTemplate,
        isGetLoading,
      },
    } = useStores();

    const handleConfirm = async () => {
      if (onConfirmed && selected) {
        onConfirmed(selected);
        onClose && onClose();
      }
    };

    async function fetchData() {
      try {
        getListChecklistTemplate();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          maxHeight: "600px",
          height: "600px",
          overflowY: "auto",
        }}
      >
        {isGetLoading && <CircularProgress size={24} />}
        {checklistTemplates && checklistTemplates.length === 0 && (
          <NoDataFound />
        )}
        {checklistTemplates &&
          checklistTemplates?.length > 0 &&
          !isGetLoading && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                marginTop: "16px",
              }}
            >
              {checklistTemplates?.map((cL, index) => (
                <Box key={index}>
                  <Card
                    onClick={() => setSelected(cL)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                    LeftComponent={
                      selected?.check_list_id === cL.check_list_id ? (
                        <CheckCircleOutline style={{ color: Colors.primary }} />
                      ) : (
                        <CircleOutlined style={{ color: "gray" }} />
                      )
                    }
                    ContentComponent={
                      <>
                        <Typography variant="subtitle2" color={Colors.black}>
                          {cL?.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            color: "gray",
                          }}
                        >
                          {cL?.tasks?.length} tasks
                        </Typography>
                      </>
                    }
                  />
                </Box>
              ))}
            </Box>
          )}

        <Box
          sx={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            backgroundColor: "white",
            paddingTop: "16px",
            paddingBottom: "16px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
            paddingRight: "16px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              color: "gray",
              borderColor: "gray",
              fontSize: "13px",
              maxHeight: "36px",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            style={{ fontSize: "13px", maxHeight: "36px" }}
            disabled={selected === undefined}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  }
);

export default ImportChecklistContent;
