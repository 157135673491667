/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SettingRepeat
 */
export interface SettingRepeat {
    /**
     * Indicates whether the setting repeats (0 = No, 1 = Yes).
     * @type {number}
     * @memberof SettingRepeat
     */
    'repeat': number;
    /**
     * The frequency of repetition.
     * @type {string}
     * @memberof SettingRepeat
     */
    'frequency': SettingRepeatFrequencyEnum;
    /**
     * The specific days of the week (1-7) for \'weekly\' or days of the month (1-31) for \'monthly\'.
     * @type {Array<number>}
     * @memberof SettingRepeat
     */
    'frequency_values': Array<number>;
    /**
     * Defines how the repetition ends.
     * @type {string}
     * @memberof SettingRepeat
     */
    'end_repeat': SettingRepeatEndRepeatEnum;
    /**
     * The number of occurrences before repetition stops. Required if end_repeat is \'after_x_occurrences\'.
     * @type {number}
     * @memberof SettingRepeat
     */
    'end_repeat_value'?: number;
    /**
     * The end date of the repetition as a timestamp.
     * @type {number}
     * @memberof SettingRepeat
     */
    'end_date'?: number;
    /**
     * Indicates whether the setting repeat_type (0 = all, 1 = Repeat_Edit_Only, 2=Repeat_Edit_Following).
     * @type {number}
     * @memberof SettingRepeat
     */
    'repeat_type'?: number;
    /**
     * Indicates whether the setting edit_date_start 
     * @type {number}
     * @memberof SettingRepeat
     */
    'edit_date_start'?: number;
    /**
     * Indicates whether the setting edit_date_end 
     * @type {number}
     * @memberof SettingRepeat
     */
    'edit_date_end'?: number;
}

export const SettingRepeatFrequencyEnum = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly'
} as const;

export type SettingRepeatFrequencyEnum = typeof SettingRepeatFrequencyEnum[keyof typeof SettingRepeatFrequencyEnum];
export const SettingRepeatEndRepeatEnum = {
    UntilDate: 'until_date',
    AfterXOccurrences: 'after_x_occurrences'
} as const;

export type SettingRepeatEndRepeatEnum = typeof SettingRepeatEndRepeatEnum[keyof typeof SettingRepeatEndRepeatEnum];


