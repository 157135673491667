import { Box, Typography } from "@mui/material";
import { Colors } from "../../../theme";

const NoDataFound = () => (
  <Box
    style={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "12px",
      width: "100%",
    }}
  >
    <svg
      width="200px"
      height="200px"
      viewBox="0 0 150 150"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>No data found</title>
      <defs>
        <rect id="path-1" x="256" y="164" width="1094" height="287"></rect>
        <mask
          id="mask-2"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width="1094"
          height="287"
          fill="white"
        >
          <use xlinkHref="#path-1"></use>
        </mask>
        <filter id="filter-3">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0.300000 0"
          ></feColorMatrix>
        </filter>
      </defs>
      <g
        id="---Settings/Salary-Groups"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Salary-group" transform="translate(-728.000000, -209.000000)">
          <rect fill="#ffffff" x="0" y="0" width="1366" height="768"></rect>
          <use
            id="Rectangle-3"
            stroke-opacity="0.1"
            stroke="#000000"
            mask="url(#mask-2)"
            stroke-width="2"
            fill="#ffffff"
            fill-rule="nonzero"
            stroke-dasharray="4,4"
            xlinkHref="#path-1"
          ></use>
          <g id="No-data-found" transform="translate(728.000000, 209.000000)">
            <rect
              id="base"
              fill="#ffffff"
              x="0"
              y="0"
              width="150"
              height="150"
            ></rect>
            <g filter="url(#filter-3)" id="Group" stroke-linecap="round">
              <g transform="translate(15.000000, 23.000000)">
                <path
                  d="M66.0215389,70.9380365 L52.2553457,70.9380365 C48.7435617,70.9380365 45.9341345,68.1286093 45.9341345,64.6168253 L45.9341345,46.49602 C45.9341345,42.984236 48.7435617,40.1748088 52.2553457,40.1748088 L66.0215389,40.1748088 C69.5333229,40.1748088 72.3427501,42.984236 72.3427501,46.49602 L72.3427501,64.6168253 C72.3427501,68.1286093 69.5333229,70.9380365 66.0215389,70.9380365 Z"
                  id="Path"
                  stroke="#0DB0A0"
                  stroke-width="3"
                ></path>
                <path
                  d="M98.470423,90.6040268 L85.8280006,103.246449 L82.3162166,85.1256438 C81.7543312,82.1757453 79.0853754,80.0686749 76.1354768,80.0686749 L68.1286093,80.0686749 L59.2789137,89.0588419 L50.429218,80.0686749 L42.2818792,80.0686749 C39.1915093,80.0686749 36.6630248,82.1757453 36.1011394,85.1256438 L32.5893554,103.246449 L20.2278758,91.0254409"
                  id="Path"
                  stroke="#0DB0A0"
                  stroke-width="3"
                ></path>
                <path
                  d="M118.83877,90.6040268 C118.83877,96.2228812 114.343687,100.717965 108.724832,100.717965 C103.105978,100.717965 98.6108943,96.2228812 98.6108943,90.6040268 L118.83877,90.6040268 Z"
                  id="Path"
                  stroke="#0DB0A0"
                  stroke-width="3"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M20.2278758,90.6040268 C20.2278758,96.2228812 15.7327923,100.717965 10.1139379,100.717965 C4.4950835,100.717965 -1.77635684e-14,96.2228812 -1.77635684e-14,90.6040268 L20.2278758,90.6040268 Z"
                  id="Path"
                  stroke="#0DB0A0"
                  stroke-width="3"
                  stroke-linejoin="round"
                ></path>
                <line
                  x1="59.7003278"
                  y1="10.3948806"
                  x2="59.7003278"
                  y2="-1.24344979e-14"
                  id="Path"
                  stroke="#0DB0A0"
                  stroke-width="3"
                ></line>
                <line
                  x1="36.9439675"
                  y1="22.8968316"
                  x2="28.6561573"
                  y2="14.7494927"
                  id="Path"
                  stroke="#0DB0A0"
                  stroke-width="3"
                ></line>
                <line
                  x1="82.5971594"
                  y1="22.8968316"
                  x2="90.7444982"
                  y2="14.7494927"
                  id="Path"
                  stroke="#0DB0A0"
                  stroke-width="3"
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <Typography
      style={{
        marginTop: "10px",
      }}
      variant="h5"
      color={Colors.backdropBg}
    >
      No data found
    </Typography>
  </Box>
);

export default NoDataFound;
