import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { TasksApi } from "../../../../../services/phpServices";
import moment from "moment";
import ListTasks from "../../../components/ListTasks";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useStores } from "../../../../../models/helpers/useStores";
import { Colors } from "../../../../../theme";

export const CListDetail: React.FC = observer(() => {
  const params = useParams();
  const {
    allChecklistStore: { filter, isGetLoading, alltasks, getAllTasks },
  } = useStores();

  async function getData(check_list_id: number) {
    try {
      getAllTasks({
        checklist_id: check_list_id,
        start_time: filter.start_time ?? moment().startOf("day").unix(),
        end_time: filter.end_time ?? moment().endOf("day").unix(),
        isMy: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function completeAll() {
    try {
      await new TasksApi().markAllCompleteTasks("web", {
        checklist_id: Number(params.checklistId),
        start_time: filter.start_time ?? moment().startOf("day").unix(),
        end_time: filter.end_time ?? moment().endOf("day").unix(),
        isMy: false,
        complete_status: "COMPLETED",
      });
      getData(Number(params.checklistId));
    } catch (e) {
      //
    }
  }

  React.useEffect(() => {
    if (params.checklistId) {
      getData(Number(params.checklistId));
    }
  }, []);

  return (
    <>
      {isGetLoading && <CircularProgress size={24} />}
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          width: "100%",
          whiteSpace: "nowrap",
          marginBottom: "8px",
        }}
      >
        <Typography variant="h5">{params.checklistName}</Typography>
        <Button
          onClick={completeAll}
          variant="contained"
          disabled={alltasks.every((d) => d.complete_status === "COMPLETED")}
          style={{ fontSize: "13px", maxHeight: "36px" }}
        >
            Complete all
        </Button>
      </Box>
      <ListTasks
        data={alltasks}
        onRefresh={() => getData(Number(params.checklistId))}
        taskDate={filter.start_time ?? 0}
      />
    </>
  );
});

export default CListDetail;
