/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AddAndMoveTaskCheckListTeamplateRequest } from '../models';
// @ts-ignore
import type { CategoryTaskTemplate } from '../models';
// @ts-ignore
import type { CheckList } from '../models';
// @ts-ignore
import type { CheckListTemplate } from '../models';
// @ts-ignore
import type { CreateChecklistRequest } from '../models';
// @ts-ignore
import type { DashboardByDateRequest } from '../models';
// @ts-ignore
import type { FormFilterCheckListTemPlate } from '../models';
// @ts-ignore
import type { FormFilterTasks } from '../models';
// @ts-ignore
import type { ListImages } from '../models';
// @ts-ignore
import type { ListTasksGroupEmployee } from '../models';
// @ts-ignore
import type { ResponseDashboardGroupDate } from '../models';
// @ts-ignore
import type { Task } from '../models';
// @ts-ignore
import type { TaskCompleteResponse } from '../models';
// @ts-ignore
import type { TaskTemplate } from '../models';
// @ts-ignore
import type { TaskTemplateCheckListForm } from '../models';
// @ts-ignore
import type { TaskTemplateFilterForm } from '../models';
// @ts-ignore
import type { TaskTemplateForm } from '../models';
// @ts-ignore
import type { UpdateChecklistRequest } from '../models';
// @ts-ignore
import type { UpdateTask } from '../models';
/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addAndMoveTaskCheckListTeamplate
         * @param {AddAndMoveTaskCheckListTeamplateRequest} body 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAndMoveTaskCheckListTeamplate: async (body: AddAndMoveTaskCheckListTeamplateRequest, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addAndMoveTaskCheckListTeamplate', 'body', body)
            const localVarPath = `/api/tasks/addAndMoveTaskCheckListTeamplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changeStatusCompleteTask
         * @param {number} taskId task_id
         * @param {number} taskDate task_date
         * @param {string} [source] 
         * @param {Array<File>} [file] The files to upload
         * @param {string} [completeStatus] complete_status
         * @param {string} [dataNote] data_note
         * @param {string} [dataCheckin] data_checkin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusCompleteTask: async (taskId: number, taskDate: number, source?: string, file?: Array<File>, completeStatus?: string, dataNote?: string, dataCheckin?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('changeStatusCompleteTask', 'taskId', taskId)
            // verify required parameter 'taskDate' is not null or undefined
            assertParamExists('changeStatusCompleteTask', 'taskDate', taskDate)
            const localVarPath = `/api/tasks/changeStatusCompleteTask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file[]', element as any);
                })
            }

    
            if (taskId !== undefined) { 
                localVarFormParams.append('task_id', taskId as any);
            }
    
            if (completeStatus !== undefined) { 
                localVarFormParams.append('complete_status', completeStatus as any);
            }
    
            if (dataNote !== undefined) { 
                localVarFormParams.append('data_note', dataNote as any);
            }
    
            if (dataCheckin !== undefined) { 
                localVarFormParams.append('data_checkin', dataCheckin as any);
            }
    
            if (taskDate !== undefined) { 
                localVarFormParams.append('task_date', taskDate as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createChecklist
         * @param {CreateChecklistRequest} body 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklist: async (body: CreateChecklistRequest, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createChecklist', 'body', body)
            const localVarPath = `/api/tasks/createChecklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createChecklistTaskTemplate
         * @param {string} [source] 
         * @param {TaskTemplateCheckListForm} [body] createChecklistTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklistTaskTemplate: async (source?: string, body?: TaskTemplateCheckListForm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/createChecklistTaskTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createTaskTemplate
         * @param {string} [source] 
         * @param {TaskTemplateForm} [body] createTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskTemplate: async (source?: string, body?: TaskTemplateForm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/createTaskTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary dashboardByDate
         * @param {string} [source] 
         * @param {DashboardByDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardByDate: async (source?: string, body?: DashboardByDateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/dashboardByDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteChecklist
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChecklist: async (id: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteChecklist', 'id', id)
            const localVarPath = `/api/tasks/deleteChecklist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteTask
         * @param {number} id 
         * @param {string} [source] 
         * @param {UpdateTask} [body] deleteTask Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask: async (id: number, source?: string, body?: UpdateTask, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTask', 'id', id)
            const localVarPath = `/api/tasks/deleteTask/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteTaskTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskTemplate: async (id: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTaskTemplate', 'id', id)
            const localVarPath = `/api/tasks/deleteTaskTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary detailChecklist
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailChecklist: async (id: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('detailChecklist', 'id', id)
            const localVarPath = `/api/tasks/detailChecklist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary detailChecklistTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailChecklistTemplate: async (id: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('detailChecklistTemplate', 'id', id)
            const localVarPath = `/api/tasks/detailChecklistTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary detailTask
         * @param {number} id 
         * @param {string} [source] 
         * @param {number} [startTime] 
         * @param {number} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailTask: async (id: number, source?: string, startTime?: number, endTime?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('detailTask', 'id', id)
            const localVarPath = `/api/tasks/detailTask/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary detailTaskTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailTaskTemplate: async (id: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('detailTaskTemplate', 'id', id)
            const localVarPath = `/api/tasks/detailTaskTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary duplicateChecklist
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateChecklist: async (id: number, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('duplicateChecklist', 'id', id)
            const localVarPath = `/api/tasks/duplicateChecklist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getChecklist
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getChecklist Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklist: async (source?: string, body?: FormFilterTasks, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/getChecklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getChecklistTemplate
         * @param {string} [source] 
         * @param {FormFilterCheckListTemPlate} [body] getChecklistTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistTemplate: async (source?: string, body?: FormFilterCheckListTemPlate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/getChecklistTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListTaskImages
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getListTaskImages Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTaskImages: async (source?: string, body?: FormFilterTasks, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/getListTaskImages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListTaskTemplate
         * @param {string} [source] 
         * @param {TaskTemplateFilterForm} [body] getListTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTaskTemplate: async (source?: string, body?: TaskTemplateFilterForm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/getListTaskTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListTasks
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getListTasks Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTasks: async (source?: string, body?: FormFilterTasks, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/getListTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getListTasksGroupEmployee
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getListTasksGroupEmployee Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTasksGroupEmployee: async (source?: string, body?: FormFilterTasks, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/getListTasksGroupEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary markAllCompleteTasks
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] TaskCompleteForm Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllCompleteTasks: async (source?: string, body?: FormFilterTasks, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks/markAllCompleteTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateChecklist
         * @param {number} checkListId 
         * @param {UpdateChecklistRequest} body 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklist: async (checkListId: number, body: UpdateChecklistRequest, source?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkListId' is not null or undefined
            assertParamExists('updateChecklist', 'checkListId', checkListId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateChecklist', 'body', body)
            const localVarPath = `/api/tasks/updateChecklist/{check_list_id}`
                .replace(`{${"check_list_id"}}`, encodeURIComponent(String(checkListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateChecklistTaskTemplate
         * @param {number} checkListId 
         * @param {string} [source] 
         * @param {TaskTemplateCheckListForm} [body] updateChecklistTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistTaskTemplate: async (checkListId: number, source?: string, body?: TaskTemplateCheckListForm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkListId' is not null or undefined
            assertParamExists('updateChecklistTaskTemplate', 'checkListId', checkListId)
            const localVarPath = `/api/tasks/updateChecklistTaskTemplate/{check_list_id}`
                .replace(`{${"check_list_id"}}`, encodeURIComponent(String(checkListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateTask
         * @param {number} id 
         * @param {string} [source] 
         * @param {UpdateTask} [body] updateTask Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: async (id: number, source?: string, body?: UpdateTask, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTask', 'id', id)
            const localVarPath = `/api/tasks/updateTask/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateTaskTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {TaskTemplateForm} [body] updateTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskTemplate: async (id: number, source?: string, body?: TaskTemplateForm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTaskTemplate', 'id', id)
            const localVarPath = `/api/tasks/updateTaskTemplate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary addAndMoveTaskCheckListTeamplate
         * @param {AddAndMoveTaskCheckListTeamplateRequest} body 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAndMoveTaskCheckListTeamplate(body: AddAndMoveTaskCheckListTeamplateRequest, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAndMoveTaskCheckListTeamplate(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.addAndMoveTaskCheckListTeamplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary changeStatusCompleteTask
         * @param {number} taskId task_id
         * @param {number} taskDate task_date
         * @param {string} [source] 
         * @param {Array<File>} [file] The files to upload
         * @param {string} [completeStatus] complete_status
         * @param {string} [dataNote] data_note
         * @param {string} [dataCheckin] data_checkin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeStatusCompleteTask(taskId: number, taskDate: number, source?: string, file?: Array<File>, completeStatus?: string, dataNote?: string, dataCheckin?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskCompleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeStatusCompleteTask(taskId, taskDate, source, file, completeStatus, dataNote, dataCheckin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.changeStatusCompleteTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary createChecklist
         * @param {CreateChecklistRequest} body 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChecklist(body: CreateChecklistRequest, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChecklist(body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.createChecklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary createChecklistTaskTemplate
         * @param {string} [source] 
         * @param {TaskTemplateCheckListForm} [body] createChecklistTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChecklistTaskTemplate(source?: string, body?: TaskTemplateCheckListForm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskTemplateCheckListForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createChecklistTaskTemplate(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.createChecklistTaskTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary createTaskTemplate
         * @param {string} [source] 
         * @param {TaskTemplateForm} [body] createTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTaskTemplate(source?: string, body?: TaskTemplateForm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTaskTemplate(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.createTaskTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary dashboardByDate
         * @param {string} [source] 
         * @param {DashboardByDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardByDate(source?: string, body?: DashboardByDateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseDashboardGroupDate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardByDate(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.dashboardByDate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deleteChecklist
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChecklist(id: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChecklist(id, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.deleteChecklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deleteTask
         * @param {number} id 
         * @param {string} [source] 
         * @param {UpdateTask} [body] deleteTask Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTask(id: number, source?: string, body?: UpdateTask, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTask(id, source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.deleteTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary deleteTaskTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTaskTemplate(id: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTaskTemplate(id, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.deleteTaskTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary detailChecklist
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailChecklist(id: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailChecklist(id, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.detailChecklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary detailChecklistTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailChecklistTemplate(id: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailChecklistTemplate(id, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.detailChecklistTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary detailTask
         * @param {number} id 
         * @param {string} [source] 
         * @param {number} [startTime] 
         * @param {number} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailTask(id: number, source?: string, startTime?: number, endTime?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailTask(id, source, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.detailTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary detailTaskTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailTaskTemplate(id: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailTaskTemplate(id, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.detailTaskTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary duplicateChecklist
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateChecklist(id: number, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateChecklist(id, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.duplicateChecklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getChecklist
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getChecklist Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklist(source?: string, body?: FormFilterTasks, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklist(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getChecklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getChecklistTemplate
         * @param {string} [source] 
         * @param {FormFilterCheckListTemPlate} [body] getChecklistTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistTemplate(source?: string, body?: FormFilterCheckListTemPlate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckListTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistTemplate(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getChecklistTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListTaskImages
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getListTaskImages Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTaskImages(source?: string, body?: FormFilterTasks, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListImages>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTaskImages(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getListTaskImages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListTaskTemplate
         * @param {string} [source] 
         * @param {TaskTemplateFilterForm} [body] getListTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTaskTemplate(source?: string, body?: TaskTemplateFilterForm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryTaskTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTaskTemplate(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getListTaskTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListTasks
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getListTasks Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTasks(source?: string, body?: FormFilterTasks, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Task>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTasks(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getListTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary getListTasksGroupEmployee
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getListTasksGroupEmployee Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListTasksGroupEmployee(source?: string, body?: FormFilterTasks, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListTasksGroupEmployee>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListTasksGroupEmployee(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getListTasksGroupEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary markAllCompleteTasks
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] TaskCompleteForm Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAllCompleteTasks(source?: string, body?: FormFilterTasks, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskCompleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAllCompleteTasks(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.markAllCompleteTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateChecklist
         * @param {number} checkListId 
         * @param {UpdateChecklistRequest} body 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChecklist(checkListId: number, body: UpdateChecklistRequest, source?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChecklist(checkListId, body, source, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.updateChecklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateChecklistTaskTemplate
         * @param {number} checkListId 
         * @param {string} [source] 
         * @param {TaskTemplateCheckListForm} [body] updateChecklistTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChecklistTaskTemplate(checkListId: number, source?: string, body?: TaskTemplateCheckListForm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskTemplateCheckListForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChecklistTaskTemplate(checkListId, source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.updateChecklistTaskTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateTask
         * @param {number} id 
         * @param {string} [source] 
         * @param {UpdateTask} [body] updateTask Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTask(id: number, source?: string, body?: UpdateTask, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTask(id, source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.updateTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary updateTaskTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {TaskTemplateForm} [body] updateTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskTemplate(id: number, source?: string, body?: TaskTemplateForm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskTemplate(id, source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.updateTaskTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksApiFp(configuration)
    return {
        /**
         * 
         * @summary addAndMoveTaskCheckListTeamplate
         * @param {AddAndMoveTaskCheckListTeamplateRequest} body 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAndMoveTaskCheckListTeamplate(body: AddAndMoveTaskCheckListTeamplateRequest, source?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.addAndMoveTaskCheckListTeamplate(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary changeStatusCompleteTask
         * @param {number} taskId task_id
         * @param {number} taskDate task_date
         * @param {string} [source] 
         * @param {Array<File>} [file] The files to upload
         * @param {string} [completeStatus] complete_status
         * @param {string} [dataNote] data_note
         * @param {string} [dataCheckin] data_checkin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusCompleteTask(taskId: number, taskDate: number, source?: string, file?: Array<File>, completeStatus?: string, dataNote?: string, dataCheckin?: string, options?: any): AxiosPromise<TaskCompleteResponse> {
            return localVarFp.changeStatusCompleteTask(taskId, taskDate, source, file, completeStatus, dataNote, dataCheckin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createChecklist
         * @param {CreateChecklistRequest} body 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklist(body: CreateChecklistRequest, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.createChecklist(body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createChecklistTaskTemplate
         * @param {string} [source] 
         * @param {TaskTemplateCheckListForm} [body] createChecklistTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChecklistTaskTemplate(source?: string, body?: TaskTemplateCheckListForm, options?: any): AxiosPromise<TaskTemplateCheckListForm> {
            return localVarFp.createChecklistTaskTemplate(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createTaskTemplate
         * @param {string} [source] 
         * @param {TaskTemplateForm} [body] createTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskTemplate(source?: string, body?: TaskTemplateForm, options?: any): AxiosPromise<TaskTemplate> {
            return localVarFp.createTaskTemplate(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary dashboardByDate
         * @param {string} [source] 
         * @param {DashboardByDateRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardByDate(source?: string, body?: DashboardByDateRequest, options?: any): AxiosPromise<Array<ResponseDashboardGroupDate>> {
            return localVarFp.dashboardByDate(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteChecklist
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChecklist(id: number, source?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteChecklist(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteTask
         * @param {number} id 
         * @param {string} [source] 
         * @param {UpdateTask} [body] deleteTask Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask(id: number, source?: string, body?: UpdateTask, options?: any): AxiosPromise<Task> {
            return localVarFp.deleteTask(id, source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteTaskTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskTemplate(id: number, source?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteTaskTemplate(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary detailChecklist
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailChecklist(id: number, source?: string, options?: any): AxiosPromise<CheckList> {
            return localVarFp.detailChecklist(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary detailChecklistTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailChecklistTemplate(id: number, source?: string, options?: any): AxiosPromise<CheckList> {
            return localVarFp.detailChecklistTemplate(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary detailTask
         * @param {number} id 
         * @param {string} [source] 
         * @param {number} [startTime] 
         * @param {number} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailTask(id: number, source?: string, startTime?: number, endTime?: number, options?: any): AxiosPromise<Task> {
            return localVarFp.detailTask(id, source, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary detailTaskTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailTaskTemplate(id: number, source?: string, options?: any): AxiosPromise<TaskTemplate> {
            return localVarFp.detailTaskTemplate(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary duplicateChecklist
         * @param {number} id 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateChecklist(id: number, source?: string, options?: any): AxiosPromise<CheckList> {
            return localVarFp.duplicateChecklist(id, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getChecklist
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getChecklist Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklist(source?: string, body?: FormFilterTasks, options?: any): AxiosPromise<Array<CheckList>> {
            return localVarFp.getChecklist(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getChecklistTemplate
         * @param {string} [source] 
         * @param {FormFilterCheckListTemPlate} [body] getChecklistTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistTemplate(source?: string, body?: FormFilterCheckListTemPlate, options?: any): AxiosPromise<CheckListTemplate> {
            return localVarFp.getChecklistTemplate(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListTaskImages
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getListTaskImages Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTaskImages(source?: string, body?: FormFilterTasks, options?: any): AxiosPromise<Array<ListImages>> {
            return localVarFp.getListTaskImages(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListTaskTemplate
         * @param {string} [source] 
         * @param {TaskTemplateFilterForm} [body] getListTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTaskTemplate(source?: string, body?: TaskTemplateFilterForm, options?: any): AxiosPromise<Array<CategoryTaskTemplate>> {
            return localVarFp.getListTaskTemplate(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListTasks
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getListTasks Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTasks(source?: string, body?: FormFilterTasks, options?: any): AxiosPromise<Array<Task>> {
            return localVarFp.getListTasks(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getListTasksGroupEmployee
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] getListTasksGroupEmployee Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListTasksGroupEmployee(source?: string, body?: FormFilterTasks, options?: any): AxiosPromise<Array<ListTasksGroupEmployee>> {
            return localVarFp.getListTasksGroupEmployee(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary markAllCompleteTasks
         * @param {string} [source] 
         * @param {FormFilterTasks} [body] TaskCompleteForm Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAllCompleteTasks(source?: string, body?: FormFilterTasks, options?: any): AxiosPromise<TaskCompleteResponse> {
            return localVarFp.markAllCompleteTasks(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateChecklist
         * @param {number} checkListId 
         * @param {UpdateChecklistRequest} body 
         * @param {string} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklist(checkListId: number, body: UpdateChecklistRequest, source?: string, options?: any): AxiosPromise<object> {
            return localVarFp.updateChecklist(checkListId, body, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateChecklistTaskTemplate
         * @param {number} checkListId 
         * @param {string} [source] 
         * @param {TaskTemplateCheckListForm} [body] updateChecklistTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistTaskTemplate(checkListId: number, source?: string, body?: TaskTemplateCheckListForm, options?: any): AxiosPromise<TaskTemplateCheckListForm> {
            return localVarFp.updateChecklistTaskTemplate(checkListId, source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateTask
         * @param {number} id 
         * @param {string} [source] 
         * @param {UpdateTask} [body] updateTask Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(id: number, source?: string, body?: UpdateTask, options?: any): AxiosPromise<Task> {
            return localVarFp.updateTask(id, source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateTaskTemplate
         * @param {number} id 
         * @param {string} [source] 
         * @param {TaskTemplateForm} [body] updateTaskTemplate Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskTemplate(id: number, source?: string, body?: TaskTemplateForm, options?: any): AxiosPromise<TaskTemplate> {
            return localVarFp.updateTaskTemplate(id, source, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @summary addAndMoveTaskCheckListTeamplate
     * @param {AddAndMoveTaskCheckListTeamplateRequest} body 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public addAndMoveTaskCheckListTeamplate(body: AddAndMoveTaskCheckListTeamplateRequest, source?: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).addAndMoveTaskCheckListTeamplate(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary changeStatusCompleteTask
     * @param {number} taskId task_id
     * @param {number} taskDate task_date
     * @param {string} [source] 
     * @param {Array<File>} [file] The files to upload
     * @param {string} [completeStatus] complete_status
     * @param {string} [dataNote] data_note
     * @param {string} [dataCheckin] data_checkin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public changeStatusCompleteTask(taskId: number, taskDate: number, source?: string, file?: Array<File>, completeStatus?: string, dataNote?: string, dataCheckin?: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).changeStatusCompleteTask(taskId, taskDate, source, file, completeStatus, dataNote, dataCheckin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createChecklist
     * @param {CreateChecklistRequest} body 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public createChecklist(body: CreateChecklistRequest, source?: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).createChecklist(body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createChecklistTaskTemplate
     * @param {string} [source] 
     * @param {TaskTemplateCheckListForm} [body] createChecklistTaskTemplate Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public createChecklistTaskTemplate(source?: string, body?: TaskTemplateCheckListForm, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).createChecklistTaskTemplate(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createTaskTemplate
     * @param {string} [source] 
     * @param {TaskTemplateForm} [body] createTaskTemplate Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public createTaskTemplate(source?: string, body?: TaskTemplateForm, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).createTaskTemplate(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary dashboardByDate
     * @param {string} [source] 
     * @param {DashboardByDateRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public dashboardByDate(source?: string, body?: DashboardByDateRequest, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).dashboardByDate(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteChecklist
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteChecklist(id: number, source?: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteChecklist(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteTask
     * @param {number} id 
     * @param {string} [source] 
     * @param {UpdateTask} [body] deleteTask Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteTask(id: number, source?: string, body?: UpdateTask, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteTask(id, source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteTaskTemplate
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteTaskTemplate(id: number, source?: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteTaskTemplate(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary detailChecklist
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public detailChecklist(id: number, source?: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).detailChecklist(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary detailChecklistTemplate
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public detailChecklistTemplate(id: number, source?: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).detailChecklistTemplate(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary detailTask
     * @param {number} id 
     * @param {string} [source] 
     * @param {number} [startTime] 
     * @param {number} [endTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public detailTask(id: number, source?: string, startTime?: number, endTime?: number, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).detailTask(id, source, startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary detailTaskTemplate
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public detailTaskTemplate(id: number, source?: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).detailTaskTemplate(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary duplicateChecklist
     * @param {number} id 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public duplicateChecklist(id: number, source?: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).duplicateChecklist(id, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getChecklist
     * @param {string} [source] 
     * @param {FormFilterTasks} [body] getChecklist Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getChecklist(source?: string, body?: FormFilterTasks, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getChecklist(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getChecklistTemplate
     * @param {string} [source] 
     * @param {FormFilterCheckListTemPlate} [body] getChecklistTemplate Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getChecklistTemplate(source?: string, body?: FormFilterCheckListTemPlate, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getChecklistTemplate(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListTaskImages
     * @param {string} [source] 
     * @param {FormFilterTasks} [body] getListTaskImages Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getListTaskImages(source?: string, body?: FormFilterTasks, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getListTaskImages(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListTaskTemplate
     * @param {string} [source] 
     * @param {TaskTemplateFilterForm} [body] getListTaskTemplate Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getListTaskTemplate(source?: string, body?: TaskTemplateFilterForm, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getListTaskTemplate(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListTasks
     * @param {string} [source] 
     * @param {FormFilterTasks} [body] getListTasks Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getListTasks(source?: string, body?: FormFilterTasks, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getListTasks(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getListTasksGroupEmployee
     * @param {string} [source] 
     * @param {FormFilterTasks} [body] getListTasksGroupEmployee Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getListTasksGroupEmployee(source?: string, body?: FormFilterTasks, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getListTasksGroupEmployee(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary markAllCompleteTasks
     * @param {string} [source] 
     * @param {FormFilterTasks} [body] TaskCompleteForm Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public markAllCompleteTasks(source?: string, body?: FormFilterTasks, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).markAllCompleteTasks(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateChecklist
     * @param {number} checkListId 
     * @param {UpdateChecklistRequest} body 
     * @param {string} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateChecklist(checkListId: number, body: UpdateChecklistRequest, source?: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateChecklist(checkListId, body, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateChecklistTaskTemplate
     * @param {number} checkListId 
     * @param {string} [source] 
     * @param {TaskTemplateCheckListForm} [body] updateChecklistTaskTemplate Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateChecklistTaskTemplate(checkListId: number, source?: string, body?: TaskTemplateCheckListForm, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateChecklistTaskTemplate(checkListId, source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateTask
     * @param {number} id 
     * @param {string} [source] 
     * @param {UpdateTask} [body] updateTask Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTask(id: number, source?: string, body?: UpdateTask, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTask(id, source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateTaskTemplate
     * @param {number} id 
     * @param {string} [source] 
     * @param {TaskTemplateForm} [body] updateTaskTemplate Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTaskTemplate(id: number, source?: string, body?: TaskTemplateForm, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTaskTemplate(id, source, body, options).then((request) => request(this.axios, this.basePath));
    }
}

