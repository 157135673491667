const Checked = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_147_1052)">
      <path
        d="M3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5Z"
        stroke="#08B0A0"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <g clip-path="url(#clip1_147_1052)">
        <path
          d="M7.625 12L10.75 15.125L17 8.875"
          stroke="#08B0A0"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_147_1052">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_147_1052">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(4.5 4.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Checked;
