import React, { ReactNode } from "react";

// Define the type for the component props
interface CardProps {
  LeftComponent?: ReactNode;
  RightComponent?: ReactNode;
  ContentComponent?: ReactNode;
  FooterComponent?: ReactNode;
  contentStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onClick?: () => void; // On click handler for the card (equivalent of TouchableOpacity)
  disabled?: boolean;
}

/**
 * A Card component for web displaying content in a contained way.
 * Can contain custom components on left, right, and footer sections.
 * @param {CardProps} props - The props for the `Card` component.
 * @returns {JSX.Element} The rendered `Card` component.
 */
export function Card({
  LeftComponent,
  RightComponent,
  ContentComponent,
  FooterComponent,
  contentStyle,
  style,
  onClick,
  disabled = false,
}: CardProps) {
  return (
    <div
      style={{ ...containerBaseStyle, ...style, cursor: disabled ? "default" : "pointer", pointerEvents: disabled ? "none" : "auto", }}
      onClick={onClick}
      role={onClick ? "button" : undefined} // Add role="button" if there's an onClick
    >
      <div style={{ ...contentStyle, ...contentStyleOverride }}>
        {LeftComponent}
        <div style={alignmentWrapperStyle}>{ContentComponent}</div>
        {RightComponent}
      </div>
      {FooterComponent}
    </div>
  );
}

// Base styles for the card container
const containerBaseStyle: React.CSSProperties = {
  borderRadius: "4px",
  padding: "16px",
  backgroundColor: "#f7f7f7",
  marginBottom: "8px",
  gap: "16px",
  cursor: "pointer",
};

// Style for content area of the card
const contentStyleOverride: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  gap: "16px",
};

// Alignment style for content in the middle section
const alignmentWrapperStyle: React.CSSProperties = {
  flex: 1,
};

