import { Box, CircularProgress, Typography } from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import CustomMonthPicker from "../../../components/MonthPicker";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../models/helpers/useStores";
import { useEffect } from "react";
import moment from "moment";
import NoDataFound from "../../../components/NoDataFound";
import { Card } from "../../../components/Card";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Colors } from "../../../../../theme";

const EmployeeReport = observer(() => {
  const {
    checklistReportStore: {
      isGetLoading,
      filterEmp,
      employeeActivityInTask,
      listEmpReport,
    },
  } = useStores();

  useEffect(() => {
    employeeActivityInTask({
      start_time: filterEmp.start_time ?? moment().startOf("month").unix(),
      end_time: filterEmp.end_time ?? moment().endOf("month").unix(),
    });
  }, []);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      ></Box>
      <CustomMonthPicker
        start_time={filterEmp.start_time}
        onChange={(st, et) => {
          employeeActivityInTask({
            ...filterEmp,
            start_time: st,
            end_time: et,
          });
        }}
      />
      {isGetLoading && <CircularProgress size={24} />}
      {!isGetLoading && listEmpReport.length === 0 && <NoDataFound />}
      {!isGetLoading && listEmpReport.length > 0 && (
        <Box sx={{ marginTop: "12px" }}>
          {[...listEmpReport]
            .sort((a, b) => b.percent - a.percent)
            .map((item, index) => (
              <Link
                key={index}
                to={`/checklist/reports/employee/${item.employee_id}`}
                style={{ color: "black", textDecoration: "none" }}
              >
                <Card
                  key={item.employee_id}
                  ContentComponent={
                    <Typography variant="h5">{item.fullname}</Typography>
                  }
                  RightComponent={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <Typography variant="h5" color={Colors.primary}>
                        {item.percent}%
                      </Typography>
                      <KeyboardArrowRight
                        style={{ marginLeft: "8px", color: "gray" }}
                      />
                    </Box>
                  }
                />
              </Link>
            ))}
        </Box>
      )}
      <Outlet />
    </Box>
  );
});

export default EmployeeReport;
