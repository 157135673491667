import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import WhoWorking from "./pages/whoIsWorking/WhoWorking";
import theme from "./theme";
import Messenger from "./pages/messenger/Messenger";
import CheckList from "./pages/checkList/CheckList";
import MyChecklist from "./pages/checkList/tabs/MyChecklist";
import Everyone from "./pages/checkList/tabs/Everyone/Everyone";
import CheckLists from "./pages/checkList/tabs/Everyone/Contents/Checklists";
import AllTasks from "./pages/checkList/tabs/Everyone/Contents/AllTasks";
import Employees from "./pages/checkList/tabs/Everyone/Contents/Employees";
import { EmployeeCList } from "./pages/checkList/tabs/Everyone/Contents/EmployeeCList";
import { CListDetail } from "./pages/checkList/tabs/Everyone/Contents/CListDetail";
import Templates from "./pages/checkList/tabs/Templates/Templates";
import CheckListTemplates from "./pages/checkList/tabs/Templates/Contents/ChecklistTemplates";
import TaskTemplates from "./pages/checkList/tabs/Templates/Contents/TaskTemplates";
import Categories from "./pages/checkList/tabs/Templates/Contents/Categories";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import Reports from "./pages/checkList/tabs/Reports/Reports";
import CListReports from "./pages/checkList/tabs/Reports/Contents/ChecklistReport";
import EmployeeReport from "./pages/checkList/tabs/Reports/Contents/EmployeeReport";
import MonthlyReport from "./pages/checkList/tabs/Reports/Contents/MonthlyReport";
import EmployeeDateReport from "./pages/checkList/tabs/Reports/Contents/EmployeeDateReport";

// Create a client
const queryClient = new QueryClient();

// Create a root element and render the application
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/who-is-working",
        element: <WhoWorking />,
      },
      {
        path: "/messenger",
        element: <Messenger />,
      },
      {
        path: "/checklist",
        element: <CheckList />,

        children: [
          {
            path: "/checklist/my",
            element: <MyChecklist />,
          },
          {
            path: "/checklist/everyone",
            element: <Everyone />,
            children: [
              {
                path: "/checklist/everyone/all-tasks",
                element: <AllTasks />,
              },
              {
                path: "/checklist/everyone/employees",
                Component: () => {
                  return <Outlet />;
                },
                children: [
                  {
                    path: "/checklist/everyone/employees",
                    element: <Employees />,
                  },
                  {
                    path: "/checklist/everyone/employees/:empId",
                    element: <EmployeeCList />,
                  },
                ],
              },
              {
                path: "/checklist/everyone/checklists",
                Component: () => {
                  return <Outlet />;
                },
                children: [
                  {
                    path: "/checklist/everyone/checklists",
                    element: <CheckLists />,
                  },
                  {
                    path: "/checklist/everyone/checklists/:checklistId/:checklistName",
                    element: <CListDetail />,
                  },
                ],
              },
            ],
          },
          {
            path: "/checklist/templates",
            element: <Templates />,
            children: [
              {
                path: "/checklist/templates/checklists",
                element: <CheckListTemplates />,
              },
              {
                path: "/checklist/templates/tasks",
                element: <TaskTemplates />,
              },
              {
                path: "/checklist/templates/categories",
                element: <Categories />,
              },
            ],
          },
          {
            path: "/checklist/reports",
            element: <Reports />,
            children: [
              {
                path: "/checklist/reports/checklist",
                element: <CListReports />,
              },
              {
                path: "/checklist/reports/employee",
                Component: () => {
                  return <Outlet />;
                },
                children: [
                  {
                    path: "/checklist/reports/employee",
                    element: <EmployeeReport />,
                  },
                  {
                    path: "/checklist/reports/employee/:empId",
                    element: <EmployeeDateReport />,
                  },
                ],
              },
              {
                path: "/checklist/reports/monthly",
                element: <MonthlyReport />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
      >
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <RouterProvider router={router} fallbackElement="/" />
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
