import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CompanyApi } from "../../../../../services/phpServices";
import { CustomListItem } from "../../../components/ListItem";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStores } from "../../../../../models/helpers/useStores";
import { Colors } from "../../../../../theme";

interface NewCategoryProps {
  open: boolean;
  onClose?: () => void;
  id: number;
}

const CategoryModal: React.FC<NewCategoryProps> = observer(
  ({ open, onClose, id }) => {
    // State for task name
    const [categoryName, setcategoryName] = useState("");
    const {
      templateStore: { getListTaskCategories, categories },
    } = useStores();

    const handleChange = (value: string) => {
      setcategoryName(value);
    };
    const handleClose = () => {
      if (onClose) onClose();
    };
    const deleteCate = async () => {
      await new CompanyApi().deleteCategory(id);
      getListTaskCategories();
      handleClose();
    };

    const handleConfirm = async () => {
      if (!categoryName.trim()) return;
      try {
        if (id === 0) {
          await new CompanyApi().createCategory({
            type: "TASKS",
            name: categoryName,
          });
        }
        if (id !== 0) {
          await new CompanyApi().updateCategory(id, {
            type: "TASKS",
            name: categoryName,
          });
        }
        getListTaskCategories();
        handleClose();
      } catch (e) {
        console.error("Failed to create category:", e);
      }
    };

    useEffect(() => {
      if (id !== 0) {
        const categoryName = categories.find((c) => c.category_id === id)?.name;
        setcategoryName(categoryName ?? "");
      }
    }, [id]);

    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: 24,
            width: "500px",
            maxHeight: "80vh",
            transform: "translate(-50%, -50%)",
            overflow: "hidden",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              height: "40px",
              backgroundColor: "#08B0A0",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px 4px 0 0",
              justifyContent: "space-between",
            }}
          >
            <Typography
              color={Colors.white}
              sx={{ paddingLeft: "16px" }}
              variant="h5"
            >
              {id === 0 ? "NEW CATEGORY" : "EDIT CATEGORY"}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "white", width: 24, height: 24 }} />
            </IconButton>
          </Box>

          {/* Content */}
          <Box
            sx={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
              overflowY: "auto",
              maxHeight: "calc(80vh - 40px)",
              marginTop: "8px",
            }}
          >
            <CustomListItem
              leftContainerWidth={150}
              text="Name*"
              containerStyle={{ marginBottom: "16px" }}
              RightComponent={
                <TextField
                  id="category-name"
                  label="Category name"
                  variant="standard"
                  style={{ width: "100%" }}
                  value={categoryName}
                  onChange={(e) => handleChange(e.target.value)}
                  InputProps={{
                    style: { fontSize: "13px", fontWeight: "400" },
                  }}
                  InputLabelProps={{
                    style: { fontSize: "13px", fontWeight: "400" },
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                  }}
                />
              }
            />
          </Box>

          {/* Footer Buttons */}
          <Box
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              marginTop: "40px",
              paddingRight: "20px",
              paddingBottom: "20px",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            {id !== 0 && (
              <Button
                variant="outlined"
                style={{
                  color: "rgb(255, 100, 100)",
                  borderColor: "rgb(255, 150, 150)",
                  fontSize: "13px",
                  maxHeight: "36px",
                }}
                onClick={deleteCate}
              >
                DELETE
              </Button>
            )}
            <Button
              variant="outlined"
              style={{
                color: "gray",
                borderColor: "gray",
                fontSize: "13px",
                maxHeight: "36px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ fontSize: "13px", maxHeight: "36px" }}
              onClick={handleConfirm}
              disabled={!categoryName.trim()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }
);

export default CategoryModal;
