import { Box, Grid } from "@mui/material";
import React from "react";
import SelectedBar from "./components/SelectedBar";
import { Outlet } from "react-router-dom";

const App: React.FC = () => {
  return (
    <Box display="flex" height="100vh" overflow="hidden">
      <Grid container style={{ height: "100%" }}>
        {/* Left Sidebar */}
        <Grid
          item
          sx={{
            height: "100%",
            width: "20%",
            top: 0,
            left: 0,
            overflow: "hidden",
            backgroundColor: "#E8E6E7",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
          position={"fixed"}
        >
          <SelectedBar />
        </Grid>
        {/* Main Content */}
        <Grid
          item
          sx={{
            marginLeft: "20%",
            width: "80%",
            height: "100%",
            overflowY: "auto",
            backgroundColor: "#fffff",
            paddingTop: "24px",
            paddingLeft: "20px",
            paddingRight: "20px",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};

export default App;
