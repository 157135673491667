import { Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStores } from "../../../models/helpers/useStores";
import { Colors } from "../../../theme";

const SelectedBar = () => {
  const location = useLocation();  // Hook to get the current location/path
  const [selected, setSelected] = useState<string>("My checklist");
  const {
    appStore: {
      userProfile,
      getACLGroup,
    },
  } = useStores()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = [
    {
      link: "/checklist/my",
      title: "My checklist",
    },
    {
      link: "/checklist/everyone/all-tasks",
      title: "Everyone",
    },
    {
      link: "/checklist/templates/checklists",
      title: "Templates",
    },
    {
      link: "/checklist/reports/checklist",
      title: "Reports",
    }
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingItem = items.find(item => currentPath.includes(item.link));
    if (matchingItem) {
      setSelected(matchingItem.title);
    }
  }, [location, items]);

  const onSelect = (item: string) => {
    setSelected(item);
  };


  const { aclChecklist } = useMemo(() => {
    const aclGroup = getACLGroup()?.[userProfile?.PrimaryGroup?.GroupId ?? 0]
    return {
      aclChecklist: aclGroup?.task,
    }
  }, [userProfile])

  const filteredItems = useMemo(() => {
    return items.filter(item => {
      if (item.link === "/checklist/everyone/all-tasks" || item.link === "/checklist/reports/checklist") {
        return aclChecklist?.ViewAll !== false;
      }
      if(item.link === "/checklist/templates/checklists") {
        return aclChecklist?.ViewTemplate !== false;
      }
      return true;
    });
  }, [aclChecklist]);


  return (
    <>
      {filteredItems.map((item, index) => (
        <Link
          key={item.title}
          to={item.link}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            backgroundColor:
              selected === item.title ? "#DCDADB" : "transparent",
            cursor: "pointer",
            marginTop: index === 0 ? "40px" : "0",
            textDecoration: "none",
            padding: "10px 20px",
            fontWeight: selected === item.title ? "600" : "400",
            fontSize: "13px",
            whiteSpace: "nowrap",
          }}
          onClick={() => onSelect(item.title)}
        >
          <Typography
            style={{
              whiteSpace: "nowrap",
              fontWeight: selected === item.title ? "500" : "400",
            }}
            variant="subtitle2"
            color={Colors.black}
          >
            {item.title}
          </Typography>
        </Link>
      ))}
    </>
  );
};

export default SelectedBar;
