import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { withSetPropAction } from "../helpers/withSetPropAction";
import { FormFilterTasks, Task, TasksApi } from "../../services/phpServices";

export const MyChecklistStoreModel = types
  .model("MyChecklistStore")
  .props({
    isGetLoading: false,
    filter: types.optional(types.frozen<FormFilterTasks>(), {}),
    mytasks: types.optional(types.frozen<Task[]>(), []),
    errorGet: types.maybe(types.string),
    taskDetail: types.optional(types.frozen<Task>(), {}),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async getListMyChecklist(newFilter: FormFilterTasks) {
      store.setProp("filter", newFilter);
      try {
        store.setProp("isGetLoading", true);
        const { data } = await new TasksApi().getListTasks("web", newFilter);
        store.setProp("mytasks", data);
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async getDetailTask(id: number, start: number, end: number) {
      try {
        store.setProp("isGetLoading", true);
        const { data } = await new TasksApi().detailTask(
          id,
          "web",
          start,
          end
        );
        store.setProp("taskDetail", data);
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
  }));

export interface MyChecklistStore extends Instance<typeof MyChecklistStoreModel> {}
export interface MyChecklistStoreSnapshot
  extends SnapshotOut<typeof MyChecklistStoreModel> {}
