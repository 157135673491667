import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "./helpers/withSetPropAction"
import moment from "moment"
import "moment-timezone"
import i18n from "i18n-js"
import "moment/locale/vi"
import "moment/locale/da"
import { DepartmentApi, DepartmentDetail } from "../services/phpServices"
import { CommonApi, CompanyInfo, ModuleDto, TimeClockConfigDto, UserProfileDto } from "../services/netServices"
import { DefaultTimeZone } from "../common/dateTime"
import { processAPIURL } from "../services"

export interface RoleProps {
    View: boolean
    Create?: boolean
    Edit?: boolean
    Delete?: boolean
    ViewAll?: boolean
    Approve?: boolean
    Lock?: boolean
    Take?: boolean
    Open?: boolean
    Swap?: boolean
    Shift?: boolean
    TimeClock?: boolean
    Timesheet?: boolean
    Off?: boolean
    Give?: boolean
    Info?: boolean
    Condition?: boolean
    Custom?: boolean
    Report?: boolean
    Note?: boolean
    CreateTemplate?: boolean
    ViewTemplate?: boolean
    EditTemplate?: boolean
    DeleteTemplate?: boolean
}

export const MODULES = {
    shift: 1,
    timesheet: 2,
    salary: 3,
    timebank: 6,
    contract: 7,
    employee_and_access_role: 11,
    employee: 12,
    access_role: 13,
    newsfeed: 15,
    request: 16,
    messenger: 17,
    calendar: 18,
    report: 24,
    hr: 26,
    document: 28,
    timebudget: 29,
    task: 25,
}

const ModuleIds = Object.values(MODULES)
const ModuleNames = Object.keys(MODULES)
export interface GroupACLProps {
    [key: string]: {
        [key: string]: RoleProps
    }
}

function getModuleNameById(moduleId: number) {
    const indexModule = ModuleIds.findIndex((i) => i === +moduleId)
    return ModuleNames[indexModule]
}

/**
 * Initializes the group module ACL.
 *
 * @param departments - An array of DepartmentDetail objects.
 * @param listModules - An array of ModuleDto objects.
 * @param userInfo - The UserProfileDto object.
 * @returns The group module key pair.
 */
function initGroupModuleACL(
    departments: DepartmentDetail[],
    listModules: ModuleDto[],
    userInfo: UserProfileDto,
) {
    const groupModuleKeypair: GroupACLProps = {}
    departments.forEach((d) => {
        d?.groups?.forEach((g) => {
            groupModuleKeypair[g.group_id] = {} as any
            g.action?.forEach((m) => {
                const key = getModuleNameById(Number(m.module_id))
                if (userInfo.IsAdmin) {
                    groupModuleKeypair[g.group_id][key] = {
                        View: true,
                        Create: true,
                        Edit: true,
                        Delete: true,
                        ViewAll: true,
                        Approve: true,
                        Lock: true,
                        Take: true,
                        Open: true,
                        Swap: true,
                        Shift: true,
                        Timesheet: true,
                        Off: true,
                        Give: true,
                        Info: true,
                        Condition: true,
                        Custom: true,
                        TimeClock: true,
                        Note: true,
                        CreateTemplate: true,
                        ViewTemplate: true,
                        EditTemplate: true,
                        DeleteTemplate: true
                    }
                } else {
                    let RoleObject: RoleProps = {
                        View: getRole(m.action, 0),
                        Create: getRole(m.action, 1),
                        Edit: getRole(m.action, 2),
                        Delete: getRole(m.action, 3),
                        Approve: getRole(m.action, 4),
                        Lock: getRole(m.action, 5),
                    }
                    switch (Number(m.module_id)) {
                        case MODULES.request:
                            RoleObject = {
                                View: getRole(m.action, 0),
                                Take: getRole(m.action, 1),
                                Open: getRole(m.action, 2),
                                Swap: getRole(m.action, 7),
                                Shift: getRole(m.action, 3),
                                Timesheet: getRole(m.action, 4),
                                Off: getRole(m.action, 5),
                                Approve: getRole(m.action, 6),
                                Give: getRole(m.action, 8),
                            }
                            break
                        case MODULES.employee:
                            RoleObject = {
                                View: getRole(m.action, 0),
                                Create: getRole(m.action, 1),
                                Edit: getRole(m.action, 2),
                                Info: getRole(m.action, 3),
                                Condition: getRole(m.action, 4),
                                Custom: getRole(m.action, 5),
                                Delete: getRole(m.action, 6),
                            }
                            break
                        case MODULES.shift:
                            RoleObject = {
                                View: getRole(m.action, 0),
                                Create: getRole(m.action, 1),
                                Edit: getRole(m.action, 2),
                                Delete: getRole(m.action, 3),
                                ViewAll: getRole(m.action, 4),
                                Lock: getRole(m.action, 5),
                                Report: getRole(m.action, 6),
                                Note: getRole(m.action, 7),
                            }
                            break
                        case MODULES.timesheet:
                            RoleObject = {
                                View: getRole(m.action, 0),
                                ViewAll: getRole(m.action, 7),
                                Create: getRole(m.action, 1),
                                Edit: getRole(m.action, 2),
                                Delete: getRole(m.action, 3),
                                Approve: getRole(m.action, 4),
                                Lock: getRole(m.action, 5),
                                TimeClock: getRole(m.action, 6),
                            }
                            break
                        case MODULES.timebank:
                        case MODULES.contract:
                            RoleObject = {
                                View: getRole(m.action, 0),
                                ViewAll: getRole(m.action, 1),
                                Create: getRole(m.action, 2),
                                Edit: getRole(m.action, 3),
                                Delete: getRole(m.action, 4),
                            }
                            break
                        case MODULES.report:
                            RoleObject = {
                                View: getRole(m.action, 0),
                                Create: getRole(m.action, 1),
                                ViewAll: getRole(m.action, 2),
                            }
                            break
                        case MODULES.document:
                            RoleObject = {
                                View: getRole(m.action, 0),
                            }
                            break
                        case MODULES.newsfeed:
                            RoleObject = {
                                View: getRole(m.action, 0),
                                Create: getRole(m.action, 1),
                                Edit: getRole(m.action, 2),
                                Delete: getRole(m.action, 3),
                            }
                            break
                        case MODULES.task:
                            RoleObject = {
                                ViewAll: getRole(m.action, 0),
                                Approve: getRole(m.action, 1),
                                Create: getRole(m.action, 2),
                                View: getRole(m.action, 3),
                                Edit: getRole(m.action, 4),
                                Delete: getRole(m.action, 5),
                                CreateTemplate: getRole(m.action, 6),
                                ViewTemplate: getRole(m.action, 7),
                                EditTemplate: getRole(m.action, 8),
                                DeleteTemplate: getRole(m.action, 9),
                            }
                    }
                    if (+m.module_id === MODULES.timesheet) {
                        if (
                            listModules.find((item) => Number(item.ModuleId) === Number(MODULES.salary)) == null
                        ) {
                            RoleObject.Approve = false
                        }
                    }

                    // / Map module action + acl for request
                    if (+m.module_id === MODULES.request) {
                        const MOD = listModules.find((item) => item.ModuleId === +m.module_id)
                        const MOD_ACTION = MOD?.Action ?? ""
                        const MOD_TAKE = getRole(MOD_ACTION, 1)
                        const MOD_ACL = {
                            View: RoleObject.View,
                            Take: MOD_TAKE && RoleObject.Take,
                            Open: MOD_TAKE && getRole(MOD_ACTION, 2) && RoleObject.Open,
                            Swap: getRole(MOD_ACTION, 7) && RoleObject.Swap,
                            Shift: getRole(MOD_ACTION, 3) && RoleObject.Shift,
                            Timesheet: getRole(MOD_ACTION, 4) && RoleObject.Timesheet,
                            Off: getRole(MOD_ACTION, 5) && RoleObject.Off,
                            Approve: getRole(MOD_ACTION, 6) && RoleObject.Approve,
                            Give: getRole(MOD_ACTION, 8) && RoleObject.Give,
                        }
                        RoleObject = MOD_ACL
                    }
                    groupModuleKeypair[g.group_id][key] = RoleObject
                }
            })
        })
    })
    return groupModuleKeypair
}

function getRole(action: string | undefined, key: number): boolean {
    return Number(action?.substring(key, key + 1)) === 1
}

function getCleanFilteredDeparmentACL(
    departments: DepartmentDetail[],
    groupModuleACL: GroupACLProps,
    moduleID?: number,
    roleType?: keyof RoleProps,
) {
    const newDep = departments.map((d) => {
        // Filter list group lấy ra những group có ACL
        const groupACL = d.groups?.filter((g) => {
            if (moduleID) {
                const module = getModuleNameById(moduleID)
                return groupModuleACL[g.group_id][module][roleType ?? "View"] === true
            } else {
                return true
            }
        })
        const newG = groupACL?.map((g) => {
            return {
                group_id: g.group_id,
            }
        })
        return {
            department_id: d.department_id,
            groups: newG,
        }
    })
    return newDep.filter((d) => d.groups && d.groups?.length > 0)
}

export const AppStoreModel = types
    .model("AppStore")
    .props({
        userProfile: types.optional(types.frozen<UserProfileDto | undefined>(), undefined),
        companyInformation: types.optional(types.frozen<CompanyInfo | undefined>(), undefined),
        listModule: types.optional(types.frozen<ModuleDto[] | undefined>(), undefined),
        departments: types.optional(types.frozen<DepartmentDetail[] | undefined>(), undefined),
        groupACL: types.optional(types.frozen<GroupACLProps | undefined>(), undefined),
        commonAvatar: types.optional(types.frozen<string[] | undefined>(), undefined),
        screen: types.maybe(types.string),
        tab: types.maybe(types.string),
        isLoading: false,
        timeZone: types.maybe(types.string)
    })
    .actions(withSetPropAction)
    .actions((store) => ({
        getACLDepartment(moduleID: number, roleType: keyof RoleProps) {
            return getCleanFilteredDeparmentACL(
                store.departments ?? [],
                store.groupACL ?? {},
                moduleID,
                roleType,
            )
        },
        getACLGroup() {
            return store.groupACL ?? {}
        },
        isActiveModule(moduleId: number) {
            const MODULES: ModuleDto[] | undefined = store.listModule
            if (moduleId === 0) {
                return true
            }
            if (MODULES != null && MODULES.length > 0) {
                const moduleArr = MODULES.filter((module) => moduleId === Number(module.ModuleId))
                if (moduleArr.length > 0) {
                    return Number(moduleArr[0].Status) === 1
                }
            }
            return false
        },
        isHaveRole(moduleId: number, key: number): boolean {
            const MODULES: ModuleDto[] | undefined = store.listModule
            const module = MODULES?.find((module) => moduleId === Number(module.ModuleId))
            if (module) {
                return Number(module.Action?.substring(key, key + 1)) === 1
            }
            return false
        },
    }))
    .actions((store) => ({
        checkIsUseCostCenter(companyInformation: CompanyInfo): boolean {
            let isUseCostCenter = false
            try {
                const useCostCenterSetting =
                    companyInformation?.Settings?.find((setting) => setting.Key === "use_cost_center")
                        ?.Value ?? "{}"
                isUseCostCenter = JSON.parse(useCostCenterSetting).value
            } catch (error) {
                console.error("Failed to parse use_cost_center setting:", error)
            }
            return store.isActiveModule(MODULES.salary) && isUseCostCenter
        },
        checkIsClockOutOver30Minutes(timeClockInfo: TimeClockConfigDto): boolean {
            return (
                moment().unix() -
                Number(timeClockInfo.Timesheet?.CheckInTime) -
                Number(
                    timeClockInfo.Timesheet?.Breaks?.reduce(
                        (a, b) => a + (Number(b.CheckOutTime) - Number(b.CheckInTime)),
                        0,
                    ),
                ) >
                1800
            )
        },
    }))
    .actions((store) => ({
        async getAppData() {
            try {
                store.setProp("isLoading", true);
                const [userProfile, companyInformation, listModule] = await Promise.all([
                    new CommonApi().commonGetUserProfileGet().then((response) => response.data),
                    new CommonApi().commonGetCompanyInformationGet().then((response) => response.data),
                    new CommonApi().commonGetListModuleGet().then((response) => response.data),
                ]);

                const timeZone = companyInformation.Timezone ?? DefaultTimeZone;
                moment.updateLocale(i18n.locale, {
                    week: {
                        dow: companyInformation.FirstDayOfWeek ?? 0,
                    },
                });
                store.setProp("timeZone", timeZone);
                moment.tz.setDefault(timeZone);
                moment.tz(timeZone);
                store.setProp("companyInformation", companyInformation);
                localStorage.setItem("USER", JSON.stringify(userProfile));
                store.setProp("userProfile", userProfile);
                store.setProp("listModule", listModule);
                processAPIURL(userProfile.UrlApi, userProfile.UrlReport);

                return { userProfile, companyInformation, listModule };
            } catch (error: any) {
                throw new Error(error?.message);
            } finally {
                store.setProp("isLoading", false);
            }
        },
        async getAllDepartments() {
            try {
                const departments = await new DepartmentApi()
                    .getAllDepartment(
                        false,
                        moment().startOf("month").unix().toString(),
                        moment().endOf("month").unix().toString()
                    )
                    .then((response) => response.data);

                store.setProp("departments", departments);
                return departments;
            } catch (error: any) {
                console.log("Error fetching departments:", error);
                return [];
            }
        },
        async fetchAcl(departments: DepartmentDetail[], listModule: ModuleDto[], userProfile: UserProfileDto) {
            try {
                const groupACL = initGroupModuleACL(departments, listModule, userProfile);
                store.setProp("groupACL", groupACL);
            } catch (error: any) {
                console.log("Error calculating groupACL:", error);
            }
        },
        async getUser() {
            try {
                store.setProp("isLoading", true)
                const { data } = await new CommonApi().commonGetUserProfileGet()
                store.setProp("userProfile", data)
            } catch (error: any) {
                console.log(error)
            } finally {
                store.setProp("isLoading", false)
            }
        },
        async getCommonAvatars() {
            try {
                store.setProp("isLoading", true)
                const { data } = await new CommonApi().commonAvatarsGet()
                store.setProp("commonAvatar", data)
            } catch (error: any) {
                console.log(error)
            } finally {
                store.setProp("isLoading", false)
            }
        },
        setUser(user: UserProfileDto) {
            store.setProp("userProfile", user)
        },
        setScreen(screen: string) {
            store.setProp("screen", screen)
        },
        setTab(tab: string) {
            store.setProp("tab", tab)
        },
    }))
    .actions((store) => ({
        async reset() {
            store.setProp("listModule", [])
            store.setProp("departments", [])
            store.setProp("userProfile", {})
            store.setProp("companyInformation", {})
        },
    }))

export interface AppStore extends Instance<typeof AppStoreModel> { }
export interface AppStoreSnapshot extends SnapshotOut<typeof AppStoreModel> { }
