import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { CustomListItem } from "../../../components/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import {
  CheckList,
  CheckListTemplateDataInner,
  CreateChecklistRequest,
  EmployeeDetail,
  SettingRepeat,
  Task,
  TasksApi,
  TaskTemplate,
  UpdateChecklistRequest,
  UserApi,
} from "../../../../../services/phpServices";
import NewTask from "./NewTaskModal";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ImportCListModal from "../../Templates/ImportCListModal";
import moment from "moment-timezone";
import { Edit, KeyboardArrowDown, Visibility } from "@mui/icons-material";
import RepeatSettings from "./RepeatSetting";
import { formatDate } from "../../../../../common/dateTime";
import { Colors } from "../../../../../theme";

interface NewChecklistModalProps {
  open: boolean;
  onClose: () => void;
  time?: number;
  onConfirm?: () => void;
  id?: number;
}

const ChecklistModal: React.FC<NewChecklistModalProps> = ({
  open,
  onClose,
  time,
  onConfirm,
  id,
}) => {
  const [emps, setEmps] = useState<EmployeeDetail[]>();
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [openSettingRepeatModal, setOpenSettingRepeatModal] = useState(false);
  const [data, setData] = useState<CheckList>();
  const [showImportClist, setShowImportClist] = useState(false);

  const repeatType = [
    { value: 0, label: "All checklist" },
    { value: 1, label: "This checklist" },
    { value: 2, label: "This and following checklist" },
  ];

  const defaultParam: CreateChecklistRequest = {
    check_list_id: 0,
    name: "",
    start_time: time ? moment.unix(time).unix() : moment().unix(),
    end_time: time ? moment.unix(time).unix() : moment().unix(),
    status: "PUBLISH",
    setting_repeat: undefined,
    employees: [],
    tasks: [],
  };

  const defaultEditParam: UpdateChecklistRequest = {
    check_list_id: data?.check_list_id,
    name: data?.name,
    start_time: data?.start_time
      ? moment.unix(data?.start_time).unix()
      : moment().unix(),
    end_time: data?.end_time
      ? moment.unix(data?.end_time).unix()
      : moment().unix(),
    status: data?.status,
    employees: data?.employees?.map((employee) => Number(employee.employee_id)),
    setting_repeat: data?.setting_repeat,
    tasks: data?.tasks ?? [],
  };

  useEffect(() => {
    if (id !== 0) {
      const fetchData = async () => {
        try {
          const res = await new TasksApi().detailChecklist(id ?? 0);
          setData(res.data);
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    }
  }, [id, open]);

  const [params, setParams] = useState<
    CreateChecklistRequest | UpdateChecklistRequest
  >(Number(id) === 0 ? defaultParam : defaultEditParam);

  useEffect(() => {
    if (data && id !== 0) {
      setParams(defaultEditParam);
    }
    if (id === 0) {
      setParams(defaultParam);
    }
  }, [data, id]);

  async function getEmps() {
    try {
      const res = await new UserApi().getListEmployee({
        name: "",
        order: 0,
        page: 1,
        limit: 100,
        status: [1, 4],
        groups: [],
        type_object: id === 0 ? "createChecklist" : "updateChecklist",
      });
      setEmps(res.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  const handleClose = () => {
    if (id === 0) {
      setParams(defaultParam);
    } else {
      setParams(defaultEditParam);
    }
    onClose();
  };

  // Handle name change
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParams({
      ...params,
      name: event.target.value,
    });
  };

  // Handle date selection for start date
  const handleStartDateChange = (date: any) => {
    const newEndTime =
      params.setting_repeat?.frequency === "daily" &&
      params.setting_repeat?.end_repeat === "after_x_occurrences"
        ? moment(date)
            .add(Number(params.setting_repeat?.end_repeat_value), "day")
            .unix()
        : params.setting_repeat?.frequency === "weekly" &&
          params.setting_repeat?.end_repeat === "after_x_occurrences"
        ? moment(date)
            .add(params.setting_repeat?.end_repeat_value, "week")
            .unix()
        : params.setting_repeat?.frequency === "monthly" &&
          params.setting_repeat?.end_repeat === "after_x_occurrences"
        ? moment(date)
            .add(params.setting_repeat?.end_repeat_value, "month")
            .unix()
        : params.end_time;
    setParams({
      ...params,
      start_time: moment(date).unix(),
      end_time: newEndTime,
    });
  };

  const handleEndDateChange = (date: any) => {
    setParams({
      ...params,
      end_time: moment(date).unix(),
    });
  };

  const handleTaskConfirmed = (t: Task) => {
    setParams((prevParams) => ({
      ...prevParams,
      tasks: [...(prevParams?.tasks ?? []), t],
    }));
  };

  const handleImportClist = (cList: CheckListTemplateDataInner) => {
    setParams((prevParams) => ({
      ...prevParams,
      name:
        prevParams.name !== ""
          ? prevParams.name
          : cList.name ?? prevParams.name,
      tasks: [...(prevParams?.tasks ?? []), ...(cList?.tasks ?? [])],
    }));
  };

  const handleImportTasks = (t: TaskTemplate[]) => {
    setParams((prevParams) => ({
      ...prevParams,
      tasks: [...(prevParams?.tasks ?? []), ...(t ?? [])],
    }));
  };

  const handleRemoveTask = (taskIndex: number) => {
    setParams((prevParams) => ({
      ...prevParams,
      tasks: prevParams?.tasks?.filter((_, index) => index !== taskIndex),
    }));
  };

  async function createChecklist() {
    try {
      const apiParams: CreateChecklistRequest | UpdateChecklistRequest = {
        ...params,
        start_time: moment
          .unix(params.start_time ?? 0)
          .startOf("day")
          .unix(),
        end_time: moment
          .unix(params.end_time ?? 0)
          .endOf("day")
          .unix(),
      };
      if (id === 0) {
        await new TasksApi().createChecklist(apiParams);
      } else {
        await new TasksApi().updateChecklist(id ?? 0, apiParams);
      }
      if (onConfirm) {
        onConfirm();
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleClose();
    }
  }

  useEffect(() => {
    getEmps();
  }, []);

  const WEEK_DAYS = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const getOrdinal = (n: number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return `${n}${suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]}`;
  };
  const getWeekTx = (frequencyValues: number[]) => {
    return frequencyValues
      .sort((a, b) => a - b)
      .map((day) => WEEK_DAYS[day - 1])
      .join(", ");
  };

  const getMonthlyTx = (frequencyValues: number[]) => {
    return frequencyValues
      .sort((a, b) => a - b)
      .map(getOrdinal)
      .join(", ");
  };
  const getRepeatText = (params: any) => {
    if (params?.setting_repeat?.repeat === 0) {
      return "None";
    }
    const {
      frequency,
      end_repeat,
      end_date,
      end_repeat_value,
      frequency_values,
    } = params?.setting_repeat ?? {};
    if (frequency === "daily") {
      return end_repeat === "until_date"
        ? `Daily until ${formatDate(end_date ?? 0)}`
        : `Daily after ${end_repeat_value} occurrences`;
    }
    if (frequency === "weekly") {
      return end_repeat === "until_date"
        ? `Weekly on ${getWeekTx(frequency_values)} until ${formatDate(
            end_date ?? 0
          )}`
        : `Weekly on ${getWeekTx(
            frequency_values
          )} after ${end_repeat_value} occurrences`;
    }
    if (frequency === "monthly") {
      return end_repeat === "until_date"
        ? `Monthly on ${getMonthlyTx(frequency_values)} until ${formatDate(
            end_date ?? 0
          )}`
        : `Monthly on ${getMonthlyTx(
            frequency_values
          )} after ${end_repeat_value} occurrences`;
    }
    return "None";
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: 24,
            width: "600px",
            maxWidth: "600px",
            maxHeight: "80vh",
            transform: "translate(-50%, -50%)",
            overflow: "hidden",
          }}
        >
          <>
            {/* Modal Header */}
            <Box
              sx={{
                height: "40px",
                backgroundColor: "#08B0A0",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px 4px 0 0",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ paddingLeft: "16px" }}
                variant="h5"
                color={Colors.white}
              >
                {id === 0 ? "NEW CHECKLIST" : "EDIT CHECKLIST"}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon
                  sx={{ color: Colors.white, width: 24, height: 24 }}
                />
              </IconButton>
            </Box>

            {/* Modal Content */}
            <Box
              sx={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
                overflowY: "auto",
                maxHeight: "calc(80vh - 40px)",
                marginTop: "8px",
              }}
            >
              <CustomListItem
                leftContainerWidth={150}
                text="Name*"
                containerStyle={{ marginBottom: "16px" }}
                RightComponent={
                  <TextField
                    id="cL-name"
                    label="Checklist name"
                    variant="standard"
                    style={{ width: "100%" }}
                    InputProps={{
                      style: {
                        fontSize: "13px",
                        fontWeight: "400",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "13px",
                        fontWeight: "400",
                      },
                    }}
                    value={params.name}
                    onChange={handleNameChange}
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontSize: "13px",
                        fontWeight: "400",
                      },
                      "& .MuiInputBase-input": {
                        fontSize: "13px",
                        fontWeight: "400",
                      },
                    }}
                  />
                }
              />
              <CustomListItem
                containerStyle={{ marginBottom: "16px" }}
                leftContainerWidth={150}
                text="Period"
                RightComponent={
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      gap: "40px",
                      width: "100%",
                    }}
                  >
                    <DesktopDatePicker
                      value={moment.unix(params?.start_time ?? 0)}
                      format="DD/MM/YYYY"
                      onChange={handleStartDateChange}
                      slots={{ openPickerIcon: KeyboardArrowDown }}
                      sx={{ width: "50%" }}
                      maxDate={moment.unix(params?.end_time ?? 0)}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          style: { width: "100%", fontSize: "13px" },
                          InputLabelProps: { size: "small" },
                          sx: {
                            "& .MuiInputLabel-root": {
                              fontSize: "13px",
                              fontWeight: "400",
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "13px",
                              fontWeight: "400",
                            },
                          },
                        },
                      }}
                    />
                    <DesktopDatePicker
                      value={moment.unix(params?.end_time ?? 0)}
                      onChange={handleEndDateChange}
                      format="DD/MM/YYYY"
                      disabled={
                        params?.setting_repeat?.end_repeat ===
                        "after_x_occurrences"
                      }
                      minDate={moment.unix(params?.start_time ?? 0)}
                      slots={{ openPickerIcon: KeyboardArrowDown }}
                      sx={{ width: "50%" }}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          style: { width: "100%", fontSize: "13px" },
                          InputLabelProps: { size: "small" },
                          sx: {
                            "& .MuiInputLabel-root": {
                              fontSize: "13px",
                              fontWeight: "400",
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "13px",
                              fontWeight: "400",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                }
              />
              <CustomListItem
                leftContainerWidth={150}
                text="Repeat"
                containerStyle={{ marginBottom: "16px" }}
                RightComponent={
                  <TextField
                    id="cL-repeat"
                    label="Repeat settings"
                    variant="standard"
                    style={{ width: "100%" }}
                    InputProps={{
                      style: {
                        fontSize: "13px",
                        fontWeight: "400",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setOpenSettingRepeatModal(true);
                            }}
                          >
                            {id === 0 && (
                              <Edit style={{ width: 20, height: 20 }} />
                            )}
                            {id !== 0 && (
                              <Visibility style={{ width: 20, height: 20 }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "13px",
                        fontWeight: "400",
                      },
                    }}
                    value={getRepeatText(params)}
                    disabled
                  />
                }
              />
              {id !== 0 && params.setting_repeat?.repeat === 1 && (
                <CustomListItem
                  leftContainerWidth={150}
                  text="Edit repeat type"
                  containerStyle={{ marginBottom: "16px" }}
                  RightComponent={
                    <FormControl fullWidth>
                      <Autocomplete
                        id="Edit repeat type"
                        onChange={(event, newValue) =>
                          setParams({
                            ...params,
                            setting_repeat: {
                              ...(params.setting_repeat as SettingRepeat),
                              repeat_type: newValue?.value ?? 0,
                              edit_date_start: moment
                                .unix(params.start_time ?? 0)
                                .startOf("day")
                                .unix(),
                            },
                          })
                        }
                        value={repeatType.find(
                          (opt) =>
                            opt.value === params.setting_repeat?.repeat_type
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="End repeat"
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "13px",
                                fontWeight: "400",
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "13px",
                                fontWeight: "400",
                                maxWidth: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            }}
                          />
                        )}
                        options={repeatType}
                        getOptionLabel={(option) => option.label}
                        disableClearable
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            style={{ fontSize: "13px", fontWeight: "400" }}
                          >
                            {option.label}
                          </li>
                        )}
                      />
                    </FormControl>
                  }
                />
              )}
              <RepeatSettings
                open={openSettingRepeatModal}
                onClose={() => setOpenSettingRepeatModal(false)}
                startTime={params.start_time ?? 0}
                endTime={params.end_time ?? 0}
                onConfirmed={(s) => {
                  setParams({
                    ...params,
                    setting_repeat: s,
                    end_time: moment.unix(s.end_date ?? 0).unix(),
                  });
                }}
                settings={params.setting_repeat}
                isEdit={id !== 0 ? true : false}
              />
              <CustomListItem
                leftContainerWidth={150}
                text="Status"
                containerStyle={{ marginBottom: "16px" }}
                RightComponent={
                  <FormControl>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Switch
                        checked={params.status === "PUBLISH"}
                        onChange={(event) => {
                          setParams({
                            ...params,
                            status: event.target.checked
                              ? "PUBLISH"
                              : "UNPUBLISH",
                          });
                        }}
                        inputProps={{ "aria-label": "Publish status toggle" }}
                        color="primary"
                      />
                      <Typography variant="subtitle2">Published</Typography>
                    </Box>
                  </FormControl>
                }
              />
              <CustomListItem
                leftContainerWidth={150}
                text="Assigned to*"
                containerStyle={{ marginBottom: "16px" }}
                RightComponent={
                  <FormControl fullWidth>
                    <Autocomplete
                      multiple
                      id="assigned-to"
                      options={emps || []}
                      getOptionLabel={(option) => option.fullname ?? ""}
                      value={
                        emps?.filter((e) =>
                          params?.employees?.includes(Number(e.employee_id))
                        ) || []
                      }
                      onChange={(event, newValue) => {
                        const selectedIds =
                          newValue?.map((e) => Number(e.employee_id)) ?? [];
                        setParams({
                          ...params,
                          employees: selectedIds,
                        });
                      }}
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Select employees"
                          sx={{
                            "& .MuiInputLabel-root": {
                              fontSize: "13px",
                              fontWeight: "400",
                            },
                            "& .MuiInputBase-input": {
                              fontSize: "13px",
                              fontWeight: "400",
                              maxWidth: "300px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            },
                          }}
                        />
                      )}
                      renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                          <Typography
                            key={option.employee_id}
                            style={{
                              margin: "2px",
                            }}
                            variant="subtitle2"
                          >
                            {option.fullname}
                            {index < value.length - 1 && ", "}
                          </Typography>
                        ));
                      }}
                      renderOption={(props, option, state) => (
                        <li
                          {...props}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Checkbox
                            checked={params?.employees?.some(
                              (id) => Number(id) === Number(option.employee_id)
                            )}
                          />
                          <Typography>{option.fullname}</Typography>
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.employee_id === value.employee_id
                      }
                    />
                  </FormControl>
                }
              />
              <CustomListItem
                leftContainerWidth={150}
                containerStyle={{ marginBottom: "100px" }}
                noCentered={true}
                text="Tasks*"
                RightComponent={
                  <Box
                    sx={{
                      width: "100%",
                      minHeight: "100px",
                      maxHeight: "300px",
                      overflowY: "auto",
                    }}
                  >
                    <List>
                      {params?.tasks?.map((t, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <AssignmentOutlinedIcon
                            sx={{
                              color: "gray",
                              width: 20,
                              height: 20,
                              marginRight: "12px",
                            }}
                          />
                          <ListItemText
                            primary={t.name}
                            sx={{ fontSize: "13px", fontWeight: "400" }}
                          />
                          <IconButton
                            onClick={() => handleRemoveTask(index)}
                            sx={{
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            }}
                          >
                            {(id === 0 ||
                              (id !== 0 &&
                                (t as unknown as { complete_status?: any })
                                  ?.complete_status !== "COMPLETED")) && (
                              <DeleteIcon
                                sx={{ color: "gray", width: 20, height: 20 }}
                              />
                            )}
                          </IconButton>
                        </ListItem>
                      ))}
                    </List>
                    <Box
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        style={{
                          color: "rgb(82, 146, 82)",
                          borderColor: "rgb(69, 139, 69)",
                          marginRight: "20px",
                          fontSize: "13px",
                          maxHeight: "36px",
                        }}
                        onClick={() => setOpenTaskModal(true)}
                      >
                        Add
                      </Button>
                      <Button
                        variant="outlined"
                        style={{
                          color: "rgb(82, 104, 146)",
                          borderColor: "rgb(69, 92, 139)",
                          fontSize: "13px",
                          maxHeight: "36px",
                        }}
                        onClick={() => setShowImportClist(true)}
                      >
                        Import
                      </Button>
                    </Box>
                  </Box>
                }
              />
              <NewTask
                open={openTaskModal}
                onClose={() => setOpenTaskModal(false)}
                onConfirmed={handleTaskConfirmed}
              />

              {/* Modal Button */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                  display: "flex",
                  gap: "20px",
                  backgroundColor: "white",
                  padding: "20px 20px",
                  borderRadius: "4px",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    color: "gray",
                    borderColor: "gray",
                    fontSize: "13px",
                    maxHeight: "36px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ fontSize: "13px", maxHeight: "36px" }}
                  onClick={createChecklist}
                  disabled={
                    params.name === "" ||
                    Number(params.end_time) < Number(params.start_time) ||
                    params.employees?.length === 0 ||
                    (params.tasks?.length === 0 && id === 0)
                  }
                >
                  Save
                </Button>
              </Box>
            </Box>
          </>
          <ImportCListModal
            open={showImportClist}
            onClose={() => setShowImportClist(false)}
            onConfirmed={handleImportClist}
            onConfirmedTask={handleImportTasks}
          />
        </Box>
      </Modal>
    </>
  );
};
export default ChecklistModal;
