import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Pagination,
} from "@mui/material";
import NoDataFound from "../../../components/NoDataFound";
import { TaskTemplate } from "../../../../../services/phpServices";
import { Card } from "../../../components/Card";
import TaskTemplateDetailModal from "./TaskTemplateDetailModal";
import NewTask from "../../Everyone/Contents/NewTaskModal";
import { useStores } from "../../../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";
import { Colors } from "../../../../../theme";

const TaskTemplates = observer(() => {
  const [selectedTask, setSelectedTask] = useState<TaskTemplate>();
  const [showTask, setShowTask] = useState(false);
  const [openNewTaskModal, setOpenNewTaskModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 10;

  const openMenu = Boolean(anchorEl);
  const {
    templateStore: {
      taskTemplates,
      getListTaskTemplate,
      isGetLoading,
      deleteTaskTemplate,
      isSubmitting,
    },
    appStore: { userProfile, getACLGroup },
  } = useStores();

  async function fetchData() {
    try {
      getListTaskTemplate();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const { aclChecklist } = useMemo(() => {
    const aclGroup = getACLGroup()?.[userProfile?.PrimaryGroup?.GroupId ?? 0];
    return {
      aclChecklist: aclGroup?.task,
    };
  }, [userProfile]);

  useEffect(() => {
    fetchData();
  }, []);

  const allTasks = useMemo(() => {
    return (
      taskTemplates?.flatMap((category) =>
        category.template_taks?.map((task) => ({
          ...task,
          categoryName: category.name,
        }))
      ) || []
    );
  }, [taskTemplates]);

  const paginatedTasks = useMemo(() => {
    return allTasks.slice(
      (currentPage - 1) * tasksPerPage,
      currentPage * tasksPerPage
    );
  }, [allTasks, currentPage]);

  const totalPages = Math.ceil(allTasks.length / tasksPerPage);

  const categorizedTasks = useMemo(() => {
    const grouped = new Map();
    paginatedTasks.forEach((task) => {
      if (!grouped.has(task?.categoryName)) {
        grouped.set(task?.categoryName, []);
      }
      grouped.get(task?.categoryName).push(task);
    });
    return Array.from(grouped.entries());
  }, [paginatedTasks]);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    task: TaskTemplate
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedTask(task);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleViewDetails = () => {
    setShowTask(true);
    handleCloseMenu();
  };

  const triggerDeleteDialog = () => {
    setOpenDeleteDialog(true);
    handleCloseMenu();
  };

  const confirmDelete = async () => {
    if (selectedTask) {
      try {
        await deleteTaskTemplate(selectedTask.task_template_id ?? 0);
        await fetchData();
      } catch (error) {
        console.error("Error deleting task:", error);
      } finally {
        setOpenDeleteDialog(false);
      }
    }
  };

  const cancelDelete = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <>
      {isGetLoading && isSubmitting && <CircularProgress size={24} />}
      {!isGetLoading && !isSubmitting && (
        <>
          {/* Nút New Task */}
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
              whiteSpace: "nowrap",
              justifyContent: "flex-end",
              marginBottom: "4px",
            }}
          >
            {aclChecklist?.CreateTemplate && (
              <Button
                style={{
                  maxHeight: "36px",
                  fontSize: "13px",
                  color: Colors.white,
                }}
                variant="contained"
                onClick={() => setOpenNewTaskModal(true)}
              >
                New task
              </Button>
            )}
          </Box>

          {allTasks.length === 0 && <NoDataFound />}
          {categorizedTasks.length > 0 && (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "16px",
              }}
            >
              {categorizedTasks.map(([categoryName, tasks], index) => (
                <Box key={index} style={{ width: "100%" }}>
                  <Typography
                    style={{ marginBottom: "12px" }}
                    variant="subtitle1"
                    color={Colors.black}
                  >
                    {categoryName}
                  </Typography>
                  {tasks.map((task: any, idx: any) => (
                    <Card
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "8px",
                      }}
                      ContentComponent={
                        <div onClick={(e) => handleMenuClick(e, task)}>
                          <Typography variant="subtitle2" color={Colors.black}>
                            {task?.name}
                          </Typography>
                        </div>
                      }
                    />
                  ))}
                </Box>
              ))}
            </Box>
          )}

          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "16px",
                marginBottom: "16px",
              }}
            />
          )}
        </>
      )}
      <NewTask
        isTemplate={true}
        open={openNewTaskModal}
        onClose={() => setOpenNewTaskModal(false)}
        onConfirmed={fetchData}
        isCreateTask={true}
      />

      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
        <MenuItem onClick={handleViewDetails}>View task details</MenuItem>
        {aclChecklist?.DeleteTemplate && (
          <>
            <Divider />
            <MenuItem onClick={triggerDeleteDialog}>Delete task</MenuItem>
          </>
        )}
      </Menu>

      <TaskTemplateDetailModal
        open={showTask}
        onClose={() => setShowTask(false)}
        id={selectedTask?.task_template_id}
        onConfirmed={() => {
          setShowTask(false);
          fetchData();
        }}
      />

      <Dialog open={openDeleteDialog} onClose={cancelDelete}>
        <Box
          sx={{
            height: "40px",
            backgroundColor: "#08B0A0",
            display: "flex",
            alignItems: "center",
            borderRadius: "2px 2px 0 0",
          }}
        >
          <Typography
            variant="h5"
            color={Colors.white}
            sx={{ paddingLeft: "16px" }}
          >
            CONFIRMATION
          </Typography>
        </Box>
        <DialogContent>
          Are you sure you want to delete this task?
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default TaskTemplates;
