import { Box, CircularProgress, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import CustomDatePicker from "../../../components/Datepicker";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../models/helpers/useStores";
import { useEffect } from "react";
import moment from "moment";
import { Card } from "../../../components/Card";
import NoDataFound from "../../../components/NoDataFound";
import { Colors } from "../../../../../theme";

const CListReports = observer(() => {
  const {
    checklistReportStore: {
      isGetLoading,
      filterCList,
      checkListProgress,
      listChecklistReport,
    },
  } = useStores();

  useEffect(() => {
    checkListProgress({
      start_time: filterCList.start_time ?? moment().startOf("day").unix(),
      end_time: filterCList.end_time ?? moment().endOf("day").unix(),
    });
  }, []);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      ></Box>
      <CustomDatePicker
        start_time={filterCList.start_time}
        end_time={filterCList.end_time}
        onChange={(st, et) => {
          checkListProgress({
            ...filterCList,
            start_time: st,
            end_time: et,
          });
        }}
      />
      {isGetLoading && <CircularProgress size={24} />}
      {!isGetLoading && listChecklistReport.length === 0 && <NoDataFound />}
      {!isGetLoading && listChecklistReport.length > 0 && (
        <Box sx={{ marginTop: "12px" }}>
          {listChecklistReport.map((item) => (
            <Card
              disabled
              key={item.check_list_id}
              ContentComponent={
                <Typography variant="h5">{item.name}</Typography>
              }
              RightComponent={
                <Typography variant="h5" color={Colors.primary}>
                  {item.percent}%
                </Typography>
              }
            />
          ))}
        </Box>
      )}
      <Outlet />
    </Box>
  );
});

export default CListReports;
