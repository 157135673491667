import moment from "moment-timezone"

export const DefaultTimeZone = "Europe/Copenhagen"

export const formatDate = (timestamp: number, override?: string) => {
  return moment.unix(timestamp).format(override ?? 'MMM DD YYYY')
}

export const formatTime = (timestamp: number, override?: string) => {
  return moment.unix(timestamp).format('HH:mm')
}

export function formatDuration(time: number, formatOverwite?: string) {
  time = Number(time);
  let isNegativeNum = false;
  if (time < 0) isNegativeNum = true;
  time = Math.abs(time);
  
  let text = "";
  let format =  "0";
  if (formatOverwite != null) format = formatOverwite;

  const hour = Math.floor(time / 3600);
  const min = Math.floor((time % 3600) / 60);

  switch (Number(format)) {
    case 0:
      text = (hour + min / 60).toFixed(2);
      break;
    case 1:
      text = hour + ":" + (min < 10 ? "0" + min : min);
      break;
    case 2:
      // const d1 = moment(), d2 = moment();
      // d2.add(hour, 'hours').add(min, 'minutes');
      // text = moment.preciseDiff(d1, d2, $filter('translate'), 'hour');
      // if (hour > 23)
      // text = hour + $filter('translate')('00_04_label_hours') + min + $filter('translate')('00_04_label_minutes');
      break;
  }

  return (isNegativeNum ? "-" : "") + text;
}


export interface Period {
  start_of_period: number
  duration_type: number | string // 0 days 1 weeks 2months
  duration_value: number
}

export function getPeriodRange(period: Period, minTime?: number) {
  const start = moment.unix(period.start_of_period)
  let current
  let diff
  const mTime = !minTime ? moment() : moment.unix(minTime)

  switch (Number(period.duration_type)) {
    case 0:
      diff = start.diff(mTime, "days")
      break
    case 1:
      diff = start.diff(mTime, "weeks")
      break
    case 2:
      diff = start.diff(mTime, "months")
      break
    default:
      diff = 0
      break
  }

  if (start.diff(minTime, "days") > 0 && diff === 0) {
    diff = 1
  }

  const obj = {
    start: 0,
    end: 0,
    text: "",
  }

  if (diff !== 0) {
    switch (Number(period.duration_type)) {
      case 0:
        {
          const count = Math.ceil(diff / period.duration_value)
          current = start.subtract(count * period.duration_value, "days")
          obj.start = current.unix()
          obj.end = current.add(period.duration_value - 1, "days").unix()
        }
        break
      case 1:
        current = start.subtract(diff * period.duration_value, "weeks")
        obj.start = current.unix()
        obj.end = current.add(period.duration_value, "weeks").subtract(1, "days").unix()
        break
      case 2:
        current = start.subtract(diff * period.duration_value, "months")
        obj.start = current.unix()
        obj.end = current.add(period.duration_value, "months").subtract(1, "days").unix()
        break
    }
  } else {
    switch (Number(period.duration_type)) {
      case 0:
        obj.start = start.unix()
        obj.end = start.add(period.duration_value - 1, "days").unix()
        break
      case 1:
        obj.start = start.unix()
        obj.end = start.add(period.duration_value, "weeks").subtract(1, "days").unix()
        break
      case 2:
        obj.start = start.unix()
        obj.end = start.add(period.duration_value, "months").subtract(1, "days").unix()
        break
    }
  }
  obj.text = formatDate(obj.start) + " - " + formatDate(obj.end)
  return obj
}


export function enumerateDaysBetweenDates (startTime?: string | number, endTime?: string | number) {
  const startDate = moment.unix(Number(startTime))
  const endDate = moment.unix(Number(endTime))
  const dates = []
  while (startDate.isSameOrBefore(endDate)) {
    dates.push(startDate.clone().startOf('day').unix())
    startDate.add(1, 'days')
  }
  return dates
}