import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import NoDataFound from "../../../components/NoDataFound";
import { Card } from "../../../components/Card";
import ProgressBar from "../../../components/ProgressBar";
import { CheckList, TasksApi } from "../../../../../services/phpServices";
import moment from "moment-timezone";
import CustomDatePicker from "../../../components/Datepicker";
import { useNavigate } from "react-router-dom";
import ChecklistModal from "./ChecklistModal";
import { useStores } from "../../../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";
import { Colors } from "../../../../../theme";

const CheckLists = observer(() => {
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [selectedChecklist, setSelectedChecklist] = useState<CheckList | null>(
    null
  );

  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(0);
  const {
    allChecklistStore: { filter, isGetLoading, checklists, getListCList },
    appStore: { userProfile, getACLGroup },
  } = useStores();

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    Clist?: CheckList
  ) => {
    setAnchorEl(event.currentTarget);
    if (Clist) {
      setSelectedChecklist(Clist);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const refresh = () => {
    getListCList({
      start_time: filter.start_time ?? moment().startOf("day").unix(),
      end_time: filter.end_time ?? moment().endOf("day").unix(),
    });
  };

  const handleMenuItemClick = (Clist: CheckList) => {
    // Navigate to the checklist detail page
    navigate(
      `/checklist/everyone/checklists/${Clist?.check_list_id}/${Clist?.name}`
    );
    handleCloseMenu();
  };

  useEffect(() => {
    getListCList({
      start_time: filter.start_time ?? moment().startOf("day").unix(),
      end_time: filter.end_time ?? moment().endOf("day").unix(),
    });
  }, []);

  const { aclChecklist } = useMemo(() => {
    const aclGroup = getACLGroup()?.[userProfile?.PrimaryGroup?.GroupId ?? 0];
    return {
      aclChecklist: aclGroup?.task,
    };
  }, [userProfile]);

  async function duplicateCList() {
    try {
      handleCloseMenu();
      await new TasksApi().duplicateChecklist(
        selectedChecklist?.check_list_id ?? 0,
        "web"
      );
      refresh();
    } catch (e) {
      //
    }
  }

  async function completeAll() {
    try {
      handleCloseMenu();
      await new TasksApi().markAllCompleteTasks("web", {
        checklist_id: selectedChecklist?.check_list_id,
        start_time: filter.start_time ?? moment().startOf("day").unix(),
        end_time: filter.end_time ?? moment().endOf("day").unix(),
        isMy: false,
        complete_status: "COMPLETED",
      });
      refresh();
    } catch (e) {
      //
    }
  }

  async function updateStatusPublish(stt?: string) {
    try {
      handleCloseMenu();
      const newStatus = stt === "UNPUBLISH" ? "PUBLISH" : "UNPUBLISH";
      const employeeIds =
        selectedChecklist?.employees?.map((emp) => Number(emp.employee_id)) ||
        [];
      const tasks =
        selectedChecklist?.tasks?.map((task) => ({
          task_id: task.task_id,
          name: task.name,
          description: task.description,
          photo: task.photo,
          note: task.note,
          checkin: task.checkin,
          status: task.status,
        })) || [];
      await new TasksApi().createChecklist({
        ...selectedChecklist,
        status: newStatus,
        employees: employeeIds,
        tasks: tasks,
      } as any);
      refresh();
    } catch (e) {
      //
    }
  }

  return (
    <>
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          width: "100%",
          whiteSpace: "nowrap",
          marginBottom: "8px",
        }}
      >
        <CustomDatePicker
          start_time={filter?.start_time || moment().startOf("day").unix()}
          end_time={filter?.end_time || moment().endOf("day").unix()}
          onChange={(st, et) => {
            getListCList({
              ...filter,
              start_time: st,
              end_time: et,
            });
          }}
        />
        {aclChecklist?.Create && (
          <Button
            onClick={() => {
              setOpenModal(true);
              setSelectedId(0);
            }}
            style={{ maxHeight: "36px", color: Colors.white, fontSize: "13px" }}
            variant="contained"
          >
            New checklist
          </Button>
        )}
      </Box>
      {isGetLoading && <CircularProgress size={24} />}
      {!isGetLoading && (
        <>
          {checklists.length === 0 && <NoDataFound />}
          {checklists.length > 0 && (
            <Box
              style={{
                display: "flex",
                width: "100%",
                gap: "16px",
                flexWrap: "wrap",
              }}
            >
              {checklists.map((cL, index) => (
                <div
                  key={index}
                  style={{
                    display: "block",
                    width: "30%",
                    textDecoration: "none",
                    flexBasis: "calc(33.333% - 16px)",
                  }}
                >
                  <Card
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      border:
                        cL?.status === "UNPUBLISH"
                          ? "1px dashed orange"
                          : "none",
                    }}
                    ContentComponent={
                      <div onClick={(e) => handleClick(e, cL)}>
                        <Typography
                          style={{
                            marginBottom: "8px",
                          }}
                          variant="subtitle2"
                          color={Colors.black}
                        >
                          {cL?.name}
                        </Typography>
                        <ProgressBar data={cL.tasks} isEmp={true} />
                      </div>
                    }
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    slotProps={{
                      paper: {
                        elevation: 1,
                        sx: {
                          overflow: "visible",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      onClick={async () => {
                        await setSelectedChecklist(cL);
                        handleMenuItemClick(selectedChecklist ?? cL);
                      }}
                    >
                      View checklist detail
                    </MenuItem>
                    {aclChecklist?.Edit && (
                      <div>
                        <Divider />
                        <MenuItem
                          onClick={async () => {
                            await setSelectedChecklist(cL);
                            setSelectedId(
                              selectedChecklist?.check_list_id ?? 0
                            );
                            setOpenModal(true);
                            handleCloseMenu();
                          }}
                        >
                          Edit checklist
                        </MenuItem>
                      </div>
                    )}
                    {aclChecklist?.Edit && (
                      <>
                        <Divider />
                        <MenuItem onClick={duplicateCList}>Duplicate</MenuItem>
                      </>
                    )}
                    {selectedChecklist?.total_task_completed !==
                      selectedChecklist?.total_task &&
                      selectedChecklist?.status === "PUBLISH" &&
                      aclChecklist?.Approve && (
                        <div>
                          <Divider />
                          <MenuItem onClick={completeAll}>
                            Complete all
                          </MenuItem>
                        </div>
                      )}
                    {selectedChecklist?.status === "UNPUBLISH" &&
                      selectedChecklist?.total_task_completed !==
                        selectedChecklist?.total_task &&
                      aclChecklist?.Edit && (
                        <div>
                          <Divider />
                          <MenuItem
                            onClick={() =>
                              updateStatusPublish(selectedChecklist?.status)
                            }
                          >
                            Publish
                          </MenuItem>
                        </div>
                      )}
                    {selectedChecklist?.status === "PUBLISH" &&
                      selectedChecklist?.total_task_completed !==
                        selectedChecklist?.total_task &&
                      aclChecklist?.Edit && (
                        <div>
                          <Divider />
                          <MenuItem
                            onClick={() =>
                              updateStatusPublish(selectedChecklist?.status)
                            }
                          >
                            Unpublish
                          </MenuItem>
                        </div>
                      )}
                  </Menu>
                </div>
              ))}
            </Box>
          )}
          <ChecklistModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            time={filter.start_time}
            onConfirm={refresh}
            id={selectedId}
          />
        </>
      )}
    </>
  );
});

export default CheckLists;
