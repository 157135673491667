import { Box, IconButton, Modal, Typography, Tabs, Tab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import ImportChecklistContent from "./Contents/ImportCListContent";
import ImportTaskContent from "./Contents/ImportTaskContent";
import { CheckListTemplateDataInner, TaskTemplate } from "../../../../services/phpServices";
import { Colors } from "../../../../theme";


interface ImportCListModalProps {
  open: boolean;
  onConfirmed?: (c: CheckListTemplateDataInner) => void;
  onConfirmedTask?: (t: TaskTemplate[]) => void;
  onClose?: () => void;
}

const ImportCListModal: React.FC<ImportCListModalProps> = ({
  open,
  onConfirmed,
  onConfirmedTask,
  onClose,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          backgroundColor: "white",
          borderRadius: "4px",
          boxShadow: 24,
          width: "500px",
          maxHeight: "80vh",
          minHeight: "600px",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            height: "40px",
            backgroundColor: "#08B0A0",
            display: "flex",
            alignItems: "center",
            borderRadius: "4px 4px 0 0",
            justifyContent: "space-between",
            paddingLeft: "16px",
          }}
        >
          <Typography variant="h5" color={Colors.white}>
            {selectedTab === 0 ? "IMPORT CHECKLIST" : "IMPORT TASKS"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: "white", width: 24, height: 24 }} />
          </IconButton>
        </Box>

        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="import tabs" style={{marginTop: "8px"}}>
          <Tab label="Checklists" />
          <Tab label="Tasks" />
        </Tabs>

        {selectedTab === 0 && (
          <ImportChecklistContent onConfirmed={onConfirmed} onClose={onClose} />
        )}
        {selectedTab === 1 && (
          <ImportTaskContent onConfirmed={onConfirmedTask} onClose={onClose} />
        )}
      </Box>
    </Modal>
  );
};

export default ImportCListModal;
