import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import {
  KeyboardArrowRight,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  CalendarToday,
} from "@mui/icons-material";
import moment, { Moment } from "moment";
import { Colors } from "../../../theme";

interface CustomDatePickerProps {
  onChange?: (startTime?: number, endTime?: number) => void;
  start_time?: number;
  end_time?: number;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  onChange,
  start_time,
  end_time,
}) => {
  const initialDate = start_time ? moment.unix(start_time) : moment();
  const [selectedDate, setSelectedDate] = useState<Moment | null>(initialDate);
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    if (start_time) {
      setSelectedDate(moment.unix(start_time));
    }
  }, [start_time]);

  const handleDateChange = (newDate: Moment | null) => {
    if (newDate) {
      const startTime = newDate.clone().startOf("day").unix();
      const endTime = newDate.endOf("day").unix();
      setSelectedDate(newDate);
      if (onChange) {
        onChange(startTime, endTime);
      }
    }
  };

  const handlePreviousDay = () => {
    if (selectedDate) {
      handleDateChange(selectedDate.clone().subtract(1, "days").startOf("day"));
    }
  };

  const handleNextDay = () => {
    if (selectedDate) {
      handleDateChange(selectedDate.clone().add(1, "days").startOf("day"));
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          cursor: "pointer",
        }}
      >
        <IconButton style={{ color: Colors.black }} onClick={handlePreviousDay}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography
          onClick={() => handleDateChange(moment())}
          style={{
            cursor: "pointer",
          }}
          variant="subtitle2"
          color="#1e74c7"
        >
          Today
        </Typography>
        <IconButton style={{ color: Colors.black }} onClick={handleNextDay}>
          <KeyboardArrowRight />
        </IconButton>

        <CalendarToday sx={{fontSize: "13px"}} />
        <Typography
          onClick={() => setShowDatePicker(true)}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          variant="subtitle2"
        >
          {selectedDate ? selectedDate.format("dddd - DD/MM/YYYY") : ""}
        </Typography>
        <KeyboardArrowDown />
      </Box>

      {/* DatePicker Component */}
      {showDatePicker && (
        <StaticDatePicker
          onChange={handleDateChange}
          displayStaticWrapperAs="desktop"
          defaultValue={selectedDate}
          onAccept={() => setShowDatePicker(false)}
          sx={{
            backgroundColor: "#f0f0f0",
            borderRadius: "8px",
            marginBottom: "16px",
          }}
        />
      )}
    </Box>
  );
};

export default CustomDatePicker;
