/* tslint:disable */
/* eslint-disable */
/**
 * Titan
 * Titan
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tuannv@teusoft.dk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { FilterChecklistReport } from '../models';
// @ts-ignore
import type { FormFilterListTimeSheetApproveEmployeeView } from '../models';
// @ts-ignore
import type { FormReportFilter } from '../models';
// @ts-ignore
import type { ResponseChecklistReportData } from '../models';
// @ts-ignore
import type { ResponseDateInTasks } from '../models';
// @ts-ignore
import type { ResponseEarningReport } from '../models';
// @ts-ignore
import type { ResponseEarningTimeSheet } from '../models';
// @ts-ignore
import type { ResponseEatSchedule } from '../models';
// @ts-ignore
import type { ResponseEmployeeActivityInTaskData } from '../models';
// @ts-ignore
import type { ResponseSummaryCheckList } from '../models';
/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary chekListProgress
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] chekListProgress Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chekListProgress: async (source?: string, body?: FilterChecklistReport, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/chekListProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary dateInTasks
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] dateInTasks Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dateInTasks: async (source?: string, body?: FilterChecklistReport, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/dateInTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary earningReport
         * @param {string} [source] 
         * @param {object} [body] earningReport Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        earningReport: async (source?: string, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/earningReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary eatScheduleHtml
         * @param {string} [source] 
         * @param {FormReportFilter} [body] eatScheduleHtml Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eatScheduleHtml: async (source?: string, body?: FormReportFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/eatScheduleHtml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary employeeActivityInTask
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] employeeActivityInTask Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeActivityInTask: async (source?: string, body?: FilterChecklistReport, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/employeeActivityInTask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reportTimeSheetByTime
         * @param {string} [source] 
         * @param {FormFilterListTimeSheetApproveEmployeeView} [body] reportTimeSheetByTime Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTimeSheetByTime: async (source?: string, body?: FormFilterListTimeSheetApproveEmployeeView, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/reportTimeSheetByTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary summaryCheckList
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] summaryCheckList Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryCheckList: async (source?: string, body?: FilterChecklistReport, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/report/summaryCheckList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (source != null) {
                localVarHeaderParameter['source'] = String(source);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary chekListProgress
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] chekListProgress Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chekListProgress(source?: string, body?: FilterChecklistReport, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistReportData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chekListProgress(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.chekListProgress']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary dateInTasks
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] dateInTasks Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dateInTasks(source?: string, body?: FilterChecklistReport, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResponseDateInTasks>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dateInTasks(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.dateInTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary earningReport
         * @param {string} [source] 
         * @param {object} [body] earningReport Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async earningReport(source?: string, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEarningReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.earningReport(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.earningReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary eatScheduleHtml
         * @param {string} [source] 
         * @param {FormReportFilter} [body] eatScheduleHtml Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eatScheduleHtml(source?: string, body?: FormReportFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEatSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eatScheduleHtml(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.eatScheduleHtml']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary employeeActivityInTask
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] employeeActivityInTask Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeActivityInTask(source?: string, body?: FilterChecklistReport, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEmployeeActivityInTaskData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeActivityInTask(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.employeeActivityInTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary reportTimeSheetByTime
         * @param {string} [source] 
         * @param {FormFilterListTimeSheetApproveEmployeeView} [body] reportTimeSheetByTime Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportTimeSheetByTime(source?: string, body?: FormFilterListTimeSheetApproveEmployeeView, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseEarningTimeSheet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportTimeSheetByTime(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.reportTimeSheetByTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary summaryCheckList
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] summaryCheckList Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async summaryCheckList(source?: string, body?: FilterChecklistReport, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseSummaryCheckList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.summaryCheckList(source, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportApi.summaryCheckList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @summary chekListProgress
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] chekListProgress Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chekListProgress(source?: string, body?: FilterChecklistReport, options?: any): AxiosPromise<ResponseChecklistReportData> {
            return localVarFp.chekListProgress(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary dateInTasks
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] dateInTasks Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dateInTasks(source?: string, body?: FilterChecklistReport, options?: any): AxiosPromise<Array<ResponseDateInTasks>> {
            return localVarFp.dateInTasks(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary earningReport
         * @param {string} [source] 
         * @param {object} [body] earningReport Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        earningReport(source?: string, body?: object, options?: any): AxiosPromise<ResponseEarningReport> {
            return localVarFp.earningReport(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary eatScheduleHtml
         * @param {string} [source] 
         * @param {FormReportFilter} [body] eatScheduleHtml Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eatScheduleHtml(source?: string, body?: FormReportFilter, options?: any): AxiosPromise<ResponseEatSchedule> {
            return localVarFp.eatScheduleHtml(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary employeeActivityInTask
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] employeeActivityInTask Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeActivityInTask(source?: string, body?: FilterChecklistReport, options?: any): AxiosPromise<ResponseEmployeeActivityInTaskData> {
            return localVarFp.employeeActivityInTask(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reportTimeSheetByTime
         * @param {string} [source] 
         * @param {FormFilterListTimeSheetApproveEmployeeView} [body] reportTimeSheetByTime Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportTimeSheetByTime(source?: string, body?: FormFilterListTimeSheetApproveEmployeeView, options?: any): AxiosPromise<ResponseEarningTimeSheet> {
            return localVarFp.reportTimeSheetByTime(source, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary summaryCheckList
         * @param {string} [source] 
         * @param {FilterChecklistReport} [body] summaryCheckList Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryCheckList(source?: string, body?: FilterChecklistReport, options?: any): AxiosPromise<ResponseSummaryCheckList> {
            return localVarFp.summaryCheckList(source, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @summary chekListProgress
     * @param {string} [source] 
     * @param {FilterChecklistReport} [body] chekListProgress Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public chekListProgress(source?: string, body?: FilterChecklistReport, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).chekListProgress(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary dateInTasks
     * @param {string} [source] 
     * @param {FilterChecklistReport} [body] dateInTasks Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public dateInTasks(source?: string, body?: FilterChecklistReport, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).dateInTasks(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary earningReport
     * @param {string} [source] 
     * @param {object} [body] earningReport Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public earningReport(source?: string, body?: object, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).earningReport(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary eatScheduleHtml
     * @param {string} [source] 
     * @param {FormReportFilter} [body] eatScheduleHtml Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public eatScheduleHtml(source?: string, body?: FormReportFilter, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).eatScheduleHtml(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary employeeActivityInTask
     * @param {string} [source] 
     * @param {FilterChecklistReport} [body] employeeActivityInTask Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public employeeActivityInTask(source?: string, body?: FilterChecklistReport, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).employeeActivityInTask(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reportTimeSheetByTime
     * @param {string} [source] 
     * @param {FormFilterListTimeSheetApproveEmployeeView} [body] reportTimeSheetByTime Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public reportTimeSheetByTime(source?: string, body?: FormFilterListTimeSheetApproveEmployeeView, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).reportTimeSheetByTime(source, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary summaryCheckList
     * @param {string} [source] 
     * @param {FilterChecklistReport} [body] summaryCheckList Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public summaryCheckList(source?: string, body?: FilterChecklistReport, options?: RawAxiosRequestConfig) {
        return ReportApiFp(this.configuration).summaryCheckList(source, body, options).then((request) => request(this.axios, this.basePath));
    }
}

