// Import the functions you need from the SDKs you need
import {
  child,
  limitToLast,
  off,
  onValue,
  orderByChild,
  push,
  query,
  ref,
  update,
} from "firebase/database"
import { useEffect, useState } from "react"
import { defaultLoadItems, FMessage, FRoom, FUser, FUserReaction, Settings } from "./type"
import { DATABASE, getBasePath } from "./useRoomChat"
import moment from "moment-timezone"
import { v4 as uuidv4 } from "uuid"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export function useChat(settings: Settings, user: FUser) {
  const [messages, setMessages] = useState<FMessage[]>([])
  const [room, setRoom] = useState<FRoom>()
  const [roomMemberIds, setRoomMemberIds] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const basePath = getBasePath(settings?.companyId)
  const offChat = () => {
    off(ref(DATABASE, `${basePath}/rooms/${settings.currentRoomId}/messages`))
    off(ref(DATABASE, `${basePath}/users/${user._id}/rooms/${settings.currentRoomId}`))
  }
  const initRoom = () => {
    const roomId = settings?.currentRoomId
    const roomMembersRef = ref(DATABASE, `${basePath}/rooms/${roomId}/members`)
    onValue(roomMembersRef, (snapshot) => {
      if (snapshot.exists()) {
        const memberIds = snapshot.val()
        setRoomMemberIds(Object.values(memberIds))
        off(roomMembersRef)
      }
      return null
    })
    const myRoomRef = ref(DATABASE, `${basePath}/users/${user._id}/rooms/${roomId}`)
    onValue(myRoomRef, (snapshot) => {
      if (snapshot.exists()) {
        setRoom(snapshot.val())
      }
      return null
    })
    setIsLoading(false)
  }
  const loadMessages = (roomId: string) => {
    const roomMessagesRef = query(
      ref(DATABASE, `${basePath}/rooms/${roomId}/messages`),
      orderByChild("createdAt"),
      limitToLast(defaultLoadItems + 50),
    )
    onValue(roomMessagesRef, (snapshot) => {
      if (snapshot.exists()) {
        const messageData = snapshot.val()
        const messageLs = Object.keys(messageData)
          .map((key) => {
            return {
              ...messageData[key],
              _id: key,
            }
          })
          .sort((a, b) => {
            return b.createdAt - a.createdAt
          })
        setMessages(messageLs)
      }
    })
  }

  const sendMessage = async (text: string, file?: string) => {
    try {
      const currentRoomId = settings.currentRoomId
      if ((!text && !file) || !currentRoomId) {
        return
      }
      const newPostKey = push(
        child(ref(DATABASE), `${basePath}/rooms/${currentRoomId}/messages`),
      ).key
      const message: FMessage = {
        _id: newPostKey ?? uuidv4(),
        text,
        user,
        createdAt: moment().unix(),
      }
      if (file) {
        if (["jpg", "jpeg", "png", "gif"].some((i) => i === file.split(".").pop()))
          message.image = file
        else message.text = file
      }
      const updates: any = {}
      updates[`${basePath}/rooms/${currentRoomId}/messages/${newPostKey}`] = message
      return update(ref(DATABASE), updates)
    } catch (e) {
      console.log(e)
    }
  }
  const sendReaction = (type: string, message: FMessage) => {
    const updates: any = {}
    const currentRoomId = settings.currentRoomId
    const newData: FUserReaction = {
      ...user,
      type,
    }
    let newLikes = message.likes ?? []
    const isLiked =
      message?.likes && message?.likes?.some((i) => i._id === user._id && i.type === type)
    if (message.likes && isLiked) {
      newLikes = message?.likes?.filter((i) => !(i._id === user._id && i.type === type))
    } else {
      newLikes = [...(message.likes ?? []), newData]
    }
    updates[`${basePath}/rooms/${currentRoomId}/messages/${message._id}/likes`] = newLikes
    return update(ref(DATABASE), updates)
  }
  useEffect(() => {
    if (settings?.currentRoomId) {
      setIsLoading(true)
      initRoom()
      loadMessages(settings?.currentRoomId)
    }
  }, [settings.currentRoomId])
  return {
    isLoading,
    messages,
    roomMemberIds,
    room,
    initRoom,
    offChat,
    sendMessage,
    sendReaction,
  }
}
