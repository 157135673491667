import { Typography, Box } from "@mui/material";
import moment from "moment-timezone";
import logo from '../../../assets/images/logo.png'
import right from '../../../assets/images/right.png'

export function Header ({ onPress }: { onPress: () => void }) {
  const currentDate = moment().format('dddd, DD/MM/YYYY')
  return <header className="header">
    <img src={logo} alt="Logo" />
    <Box sx={{textAlign: 'center'}}>
      <Typography sx={{fontSize: 24, lineHeight: '32px'}}>Who is working today</Typography>
      <Typography sx={{fontSize: 15, lineHeight: '23px'}}>{currentDate}</Typography>
    </Box>
    <div onClick={onPress} role='button'>
      <img src={right} alt="Open modal" />
    </div>
  </header>
}