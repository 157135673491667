import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import {
  getToken,
  getUrl,
  processAPIURL,
  saveToken,
  saveUrl,
} from "./services";
import { CircularProgress } from "@mui/material";
import { useStores } from "./models/helpers/useStores";
import { observer } from "mobx-react-lite";

const App: React.FC = observer(() => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    appStore: {
      getAppData,
      userProfile,
      getAllDepartments,
      fetchAcl,
      listModule,
      isLoading,
    },
  } = useStores();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      const url = decodeURIComponent(searchParams.get("url") ?? "") || getUrl();
      const token =
        decodeURIComponent(searchParams.get("token") ?? "") || getToken();
      processAPIURL(undefined, url);
      const next = searchParams.get("next");
      if (url && token) {
        saveToken(token);
        saveUrl(url);
        getAppData().then(async () => {
          await getAllDepartments()
            .then((departments) => {
              fetchAcl(departments ?? [], listModule ?? [], userProfile ?? {});
            })
            .catch((error) => {
              console.error("Error fetching departments:", error);
            });
          if (next) {
            navigate(`/${next}`);
          } 
          // else {
          //   navigate("who-is-working");
          // }
        });
        setIsInitialized(true);
      } else {
        alert(
          "You do not have permission to view this page!\nError code: MISSING_DATA"
        );
      }
    }
  }, [searchParams, userProfile, isInitialized, navigate]);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return <Outlet />;
});

export default App;
