import React, { useEffect, useMemo } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import NoDataFound from "../../../components/NoDataFound";
import { Card } from "../../../components/Card";
import ChecklistTemplateDetailModal from "./ChecklistTemplateDetailModal";
import { useStores } from "../../../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";
import { Colors } from "../../../../../theme";

const CheckListTemplates = observer(() => {
  const [showDetail, setShowDetail] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(0);
  const {
    templateStore: {
      checklistTemplates,
      getListChecklistTemplate,
      isGetLoading,
    },
    appStore: { userProfile, getACLGroup },
  } = useStores();

  async function fetchData() {
    try {
      getListChecklistTemplate();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const { aclChecklist } = useMemo(() => {
    const aclGroup = getACLGroup()?.[userProfile?.PrimaryGroup?.GroupId ?? 0];
    return {
      aclChecklist: aclGroup?.task,
    };
  }, [userProfile]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isGetLoading && <CircularProgress size={24} />}
      {!isGetLoading && (
        <>
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
              whiteSpace: "nowrap",
              justifyContent: "flex-end",
              marginBottom: "16px",
            }}
          >
            {aclChecklist?.CreateTemplate && (
              <Button
                style={{ maxHeight: "36px", fontSize: "13px", color: Colors.white }}
                variant="contained"
                onClick={async () => {
                  await setSelectedId(0);
                  setShowDetail(true);
                }}
              >
                  New checklist template
              </Button>
            )}
          </Box>
          {checklistTemplates?.length === 0 && <NoDataFound />}
          {checklistTemplates && checklistTemplates?.length > 0 && (
            <Box
              style={{
                display: "flex",
                width: "100%",
                gap: "16px",
                flexWrap: "wrap",
              }}
            >
              {checklistTemplates.map((cL, index) => (
                <Box
                  key={index}
                  style={{
                    display: "block",
                    width: "30%",
                    textDecoration: "none",
                    flexBasis: "calc(33.333% - 16px)",
                  }}
                >
                  <Card
                    onClick={async () => {
                      await setSelectedId(cL.check_list_id ?? 0);
                      setShowDetail(true);
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                    ContentComponent={
                      <>
                        <Typography variant="subtitle2" color={Colors.black}>
                          {cL?.name}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            marginTop: "4px",
                            color: "gray",
                          }}
                        >
                          {(cL?.total_task ?? 0) < 1
                            ? `0 task`
                            : `${cL?.total_task} tasks`}
                        </Typography>
                      </>
                    }
                  />
                </Box>
              ))}
            </Box>
          )}
        </>
      )}
      <ChecklistTemplateDetailModal
        open={showDetail}
        onClose={() => {
          setShowDetail(false);
        }}
        id={selectedId}
        onSucess={fetchData}
      />
    </>
  );
});

export default CheckListTemplates;
