import {
  Divider,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Task, TasksApi } from "../../../services/phpServices";
import Checked from "./IconChecked";
import Unchecked from "./IconUnchecked";
import NoDataFound from "./NoDataFound";
import ProgressBar from "./ProgressBar";
import TaskDetailModal from "./TaskDetailModal";
import { Card } from "./Card";
import EditTask from "../tabs/Everyone/Contents/EditTask";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models/helpers/useStores";
import moment from "moment";

interface ListTaskProps {
  data: Task[];
  onRefresh?: () => void;
  hideProgressBar?: boolean;
  isMy?: boolean;
  onConfirm?: (task: Task) => void;
  taskDate: number;
}

const ListTasks: React.FC<ListTaskProps> = observer(
  ({
    data,
    onRefresh,
    hideProgressBar = false,
    isMy = false,
    onConfirm,
    taskDate,
  }) => {
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);
    const [taskdetail, setSelectedTaskdetail] = useState<Task>();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const openMenu = Boolean(anchorEl);
    const {
      allChecklistStore: {
        isSubmitLoading,
        deleteTask,
        changeStatusTask,
        updateTask,
      },
      appStore: { userProfile, getACLGroup },
    } = useStores();

    const { aclChecklist } = useMemo(() => {
      const aclGroup = getACLGroup()?.[userProfile?.PrimaryGroup?.GroupId ?? 0];
      return {
        aclChecklist: aclGroup?.task,
      };
    }, [userProfile]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, task?: Task) => {
      setAnchorEl(event.currentTarget);
      setSelectedTask(task ?? {});
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    async function actionTask(task: Task) {
      try {
        const newStatus =
          task.complete_status === "COMPLETED" ? "UNCOMPLETED" : "COMPLETED";
        await changeStatusTask(task.task_id ?? 0, taskDate, newStatus);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    const handleOpenModal = async (id: number) => {
      try {
        const { data } = await new TasksApi().detailTask(
          id ?? 0,
          "web",
          moment.unix(taskDate).startOf("day").unix(),
          moment.unix(taskDate).endOf("day").unix()
        );
        setSelectedTaskdetail(data);
      } catch (err) {
        console.error("Failed to fetch task details:", err);
      }
    };

    const triggerDeleteDialog = () => {
      setOpenDeleteDialog(true);
      handleCloseMenu();
    };

    // Confirm delete action
    const confirmDelete = async () => {
      try {
        await deleteTask(selectedTask?.task_id ?? 0);
        onRefresh && onRefresh();
      } catch (error) {
        console.error("Error deleting task:", error);
      } finally {
        setOpenDeleteDialog(false);
      }
    };

    const handleTaskClick = async (task: Task) => {
      if (isMy && !aclChecklist?.View) {
        return;
      }
      if (!isMy && !aclChecklist?.Approve) {
        return;
      }
      if (isMy && (task.photo === 1 || task.note === 1)) {
        onConfirm && onConfirm(task);
      } else {
        if (task.status === "PUBLISH") {
          await actionTask(task);
          onRefresh && onRefresh();
        } else {
          //
        }
      }
    };

    const cancelDelete = () => {
      setOpenDeleteDialog(false);
      handleCloseMenu();
    };

    async function publishTask() {
      try {
        handleCloseMenu();
        const newStatus =
          selectedTask?.status === "PUBLISH" ? "UNPUBLISH" : "PUBLISH";
        await updateTask(selectedTask?.task_id ?? 0, { status: newStatus });
        onRefresh && onRefresh();
      } catch (e) {
        //
      }
    }

    return (
      <>
        {isSubmitLoading && <CircularProgress size={24} />}
        {!hideProgressBar && <ProgressBar data={data} />}
        {data.length === 0 && <NoDataFound />}
        {data.length > 0 &&
          !isSubmitLoading &&
          data.map((task: Task, index: number) => (
            <div key={task.task_id}>
              <Card
                style={{
                  border:
                    task?.status === "UNPUBLISH" ? "1px dashed orange" : "none",
                }}
                LeftComponent={
                  <div
                    onClick={async () => {
                      await handleTaskClick(task);
                    }}
                  >
                    {task.complete_status === "COMPLETED" ? (
                      <Checked />
                    ) : (
                      <Unchecked />
                    )}
                  </div>
                }
                ContentComponent={
                  <div
                    onClick={(e) => {
                      if (!isMy) {
                        handleClick(e, task);
                      } else {
                        setOpen(true);
                        handleOpenModal(task.task_id ?? 0);
                      }
                    }}
                  >
                    <Typography
                      style={{
                        color:
                          task.complete_status === "COMPLETED"
                            ? "#08B0A0"
                            : "black",
                      }}
                      variant="subtitle2"
                    >
                      {task.name}
                    </Typography>
                  </div>
                }
              />
              <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                slotProps={{
                  paper: {
                    elevation: 1,
                    sx: {
                      overflow: "visible",
                    },
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleOpenModal(selectedTask?.task_id ?? 0);
                    setOpen(true);
                    handleCloseMenu();
                  }}
                >
                  View task detail
                </MenuItem>
                {selectedTask?.status === "PUBLISH" &&
                  !isMy &&
                  selectedTask?.complete_status !== "COMPLETED" &&
                  aclChecklist?.Edit && (
                    <div>
                      <Divider />
                      <MenuItem onClick={() => publishTask()}>
                        Unpublish
                      </MenuItem>
                    </div>
                  )}
                {selectedTask?.status === "UNPUBLISH" &&
                  aclChecklist?.Edit &&
                  !isMy &&
                  selectedTask?.complete_status !== "COMPLETED" && (
                    <div>
                      <Divider />
                      <MenuItem onClick={() => publishTask()}>Publish</MenuItem>
                    </div>
                  )}
                {selectedTask?.complete_status !== "COMPLETED" &&
                  aclChecklist?.Delete && (
                    <div>
                      <Divider />
                      <MenuItem onClick={() => triggerDeleteDialog()}>
                        Delete task
                      </MenuItem>
                    </div>
                  )}
                {!isMy &&
                  selectedTask?.complete_status !== "COMPLETED" &&
                  aclChecklist?.Edit && (
                    <div>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          setOpenModalEdit(true);
                          handleCloseMenu();
                        }}
                      >
                        Edit task
                      </MenuItem>
                    </div>
                  )}
              </Menu>
            </div>
          ))}

        <TaskDetailModal
          open={open}
          onClose={() => setOpen(false)}
          taskDetail={taskdetail}
        />
        <EditTask
          open={openModalEdit}
          id={selectedTask?.task_id}
          onClose={() => setOpenModalEdit(false)}
          onConfirm={onRefresh}
        />

        <Dialog open={openDeleteDialog} onClose={cancelDelete}>
          <Box
            sx={{
              height: "40px",
              backgroundColor: "#08B0A0",
              display: "flex",
              alignItems: "center",
              borderRadius: "2px 2px 0 0",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" color="white" sx={{ paddingLeft: "16px" }}>
              CONFIRMATION
            </Typography>
          </Box>
          <DialogContent>
            Are you sure you want to delete this task?
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDelete} color="secondary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

export default ListTasks;
