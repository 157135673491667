import { Box, Button, CircularProgress } from "@mui/material";
import { useEffect, useMemo } from "react";
import { TasksApi } from "../../../../../services/phpServices";
import moment from "moment";
import CustomDatePicker from "../../../components/Datepicker";
import ListTasks from "../../../components/ListTasks";
import { useStores } from "../../../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";

const AllTasks = observer(() => {
  const {
    allChecklistStore: { filter, isGetLoading, alltasks, getAllTasks },
    appStore: { userProfile, getACLGroup },
  } = useStores();
  const refresh = () => {
    getAllTasks({
      start_time: filter.start_time ?? moment().startOf("day").unix(),
      end_time: filter.end_time ?? moment().endOf("day").unix(),
      isMy: false,
    });
  };

  useEffect(() => {
    getAllTasks({
      start_time: filter.start_time ?? moment().startOf("day").unix(),
      end_time: filter.end_time ?? moment().endOf("day").unix(),
      isMy: false,
    });
  }, []);

  const { aclChecklist } = useMemo(() => {
    const aclGroup = getACLGroup()?.[userProfile?.PrimaryGroup?.GroupId ?? 0];
    return {
      aclChecklist: aclGroup?.task,
    };
  }, [userProfile]);

  async function completeAll() {
    try {
      await new TasksApi().markAllCompleteTasks("web", {
        start_time: filter.start_time ?? moment().startOf("day").unix(),
        end_time: filter.end_time ?? moment().endOf("day").unix(),
        isMy: false,
        complete_status: "COMPLETED",
      });
      refresh();
    } catch (e) {
      //
    }
  }

  return (
    <>
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          width: "100%",
          whiteSpace: "nowrap",
          marginBottom: "8px",
        }}
      >
        <CustomDatePicker
          start_time={filter?.start_time || moment().startOf("day").unix()}
          end_time={filter?.end_time || moment().endOf("day").unix()}
          onChange={(st, et) => {
            getAllTasks({
              ...filter,
              start_time: st,
              end_time: et,
              isMy: false,
            });
          }}
        />
        {aclChecklist?.Approve && (
          <Button
            onClick={completeAll}
            variant="contained"
            disabled={alltasks.every((d) => d.complete_status === "COMPLETED")}
            style={{ fontSize: "13px", maxHeight: "36px" }}
          >
            Complete all
          </Button>
        )}
      </Box>
      {isGetLoading && <CircularProgress size={24} />}
      {!isGetLoading && (
        <ListTasks
          data={alltasks}
          onRefresh={refresh}
          taskDate={filter.start_time ?? 0}
        />
      )}
    </>
  );
});

export default AllTasks;
