import React, { useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import NoDataFound from "../../../components/NoDataFound";
import { Card } from "../../../components/Card";
import Avatar from "../../../../../common/components/Avatar";
import ProgressBar from "../../../components/ProgressBar";
import moment from "moment";
import CustomDatePicker from "../../../components/Datepicker";
import { Link } from "react-router-dom";
import { useStores } from "../../../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";
import { Colors } from "../../../../../theme";

const Employees = observer(() => {
  const {
    allChecklistStore: { filter, isGetLoading, emptasks, getEmpTasks },
  } = useStores();
  
  useEffect(() => {
    getEmpTasks({
      start_time: filter.start_time ?? moment().startOf("day").unix(),
      end_time: filter.end_time ?? moment().endOf("day").unix(),
    });
  }, []);

  return (
    <>
        <CustomDatePicker
          start_time={filter?.start_time || moment().startOf("day").unix()}
          end_time={filter?.end_time || moment().endOf("day").unix()}
          onChange={(st, et) => {
            getEmpTasks({
              ...filter,
              start_time: st,
              end_time: et,
            });
          }}
        />
      {isGetLoading && <CircularProgress size={24} />}
      {!isGetLoading && (
        <>
          {emptasks.length === 0 && <NoDataFound />}
          {emptasks.length > 0 && (
            <Box
              style={{
                display: "flex",
                width: "100%",
                gap: "16px",
                flexWrap: "wrap",
              }}
            >
              {emptasks.map((emp, index) => (
                <Link
                  key={index}
                  to={`/checklist/everyone/employees/${emp.employee?.employee_id}`}
                  style={{ width: "30%", textDecoration: "none" }}
                >
                  <Card
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                    LeftComponent={
                      <Avatar
                        image={emp?.employee?.avatar ?? ""}
                        color={emp?.employee?.color ?? ""}
                        name={emp?.employee?.fullname ?? ""}
                        isSquare={true}
                      />
                    }
                    ContentComponent={
                      <>
                        <Typography
                          variant="subtitle2"
                          color={Colors.black}
                        >
                          {emp?.employee?.fullname}
                        </Typography>
                        <Typography
                          style={{
                            marginTop: "4px",
                            marginBottom: "4px",
                          }}
                          variant="subtitle2"
                          color={Colors.backdropBg}
                        >
                          {emp?.employee?.group?.group_name}
                        </Typography>
                        <ProgressBar data={emp.tasks} isEmp={true} />
                      </>
                    }
                  />
                </Link>
              ))}
            </Box>
          )}
        </>
      )}
    </>
  );
});

export default Employees;
