import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Categories,
  CompanyApi,
  TasksApi,
  TaskTemplate,
} from "../../../../../services/phpServices";
import { CustomListItem } from "../../../components/ListItem";
import { useEffect, useMemo, useState } from "react";
import { useStores } from "../../../../../models/helpers/useStores";
import { Colors } from "../../../../../theme";

interface TaskTemplateDetailModalProps {
  open: boolean;
  id?: number;
  onConfirmed?: () => void;
  onClose?: () => void;
}

const TaskTemplateDetailModal: React.FC<TaskTemplateDetailModalProps> = ({
  open,
  onConfirmed,
  onClose,
  id,
}) => {
  const [task, setTask] = useState<TaskTemplate | undefined>();
  const [categories, setCategories] = useState<Categories[] | undefined>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const {
    appStore: { userProfile, getACLGroup },
  } = useStores();

  const { aclChecklist } = useMemo(() => {
    const aclGroup = getACLGroup()?.[userProfile?.PrimaryGroup?.GroupId ?? 0];
    return {
      aclChecklist: aclGroup?.task,
    };
  }, [userProfile]);

  async function getDetailTaskTemplate() {
    try {
      const res = await new TasksApi().detailTaskTemplate(id ?? 0);
      setTask(res.data);
    } catch (e) {
      //
    }
  }
  async function getCategories() {
    try {
      const res = await new CompanyApi().getListCategories({
        type: "TASKS",
        limit: 1000,
        page: 1,
      });
      setCategories(res.data.data);
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    const fetchData = () => {
      getDetailTaskTemplate();
      getCategories();
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (task?.categorys) {
      const categoryIds = task.categorys
        .map((category) => category.category_id)
        .filter((id): id is number => id !== undefined);
      setSelectedCategories(categoryIds);
    }
  }, [open]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTask((prevTask) =>
      prevTask ? { ...prevTask, name: event.target.value } : undefined
    );
  };
  const handleDeshange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTask((prevTask) =>
      prevTask ? { ...prevTask, description: event.target.value } : undefined
    );
  };

  const handleSwitchChange = (field: keyof TaskTemplate, checked: boolean) => {
    setTask((prevTask) =>
      prevTask ? { ...prevTask, [field]: checked ? 1 : 0 } : undefined
    );
  };

  const handleSelectCategories = (event: any) => {
    const value = event.target.value;
    setSelectedCategories((prevSelectedCategories) => {
      const newSelectedCategories = prevSelectedCategories.includes(
        Number(value[value.length - 1])
      )
        ? prevSelectedCategories.filter(
            (categoryId) => categoryId !== Number(value[value.length - 1])
          )
        : [...prevSelectedCategories, Number(value[value.length - 1])];
      setTask((prevTask: any) => ({
        ...prevTask,
        category_ids: newSelectedCategories,
      }));
      return newSelectedCategories;
    });
  };

  async function updateTaskTemplate() {
    try {
      await new TasksApi().updateTaskTemplate(
        task?.task_template_id ?? 0,
        "web",
        {
          task_template_id: task?.task_template_id,
          description: task?.description ?? "",
          note: task?.note ?? 0,
          photo: task?.photo ?? 0,
          checkin: task?.checkin ?? 0,
          category_ids: selectedCategories,
          name: task?.name ?? "",
        }
      );
    } catch (e) {
      //
    }
  }

  const handleConfirmed = async () => {
    await updateTaskTemplate();
    onConfirmed && onConfirmed();
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          backgroundColor: "white",
          borderRadius: "4px",
          boxShadow: 24,
          width: "500px",
          maxHeight: "80vh",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            height: "40px",
            backgroundColor: "#08B0A0",
            display: "flex",
            alignItems: "center",
            borderRadius: "4px 4px 0 0",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h5"
            color={Colors.white}
            sx={{ paddingLeft: "16px" }}
          >
            {aclChecklist?.EditTemplate
              ? "EDIT TASK TEMPLATE"
              : "TASK TEMPLATE DETAIL"}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: Colors.white, width: 24, height: 24 }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "20px",
            overflowY: "auto",
            maxHeight: "calc(80vh - 40px)",
            marginTop: "16px",
          }}
        >
          <CustomListItem
            leftContainerWidth={150}
            text="Name*"
            containerStyle={{ marginBottom: "16px" }}
            RightComponent={
              <TextField
                id="task-name"
                label="Task name"
                variant="standard"
                style={{ width: "100%" }}
                value={task?.name ?? ""}
                onChange={handleNameChange}
                disabled={!aclChecklist?.EditTemplate}
                InputProps={{
                  style: {
                    fontSize: "13px",
                    fontWeight: "400",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: "13px",
                    fontWeight: "400",
                  },
                }}
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: "13px",
                    fontWeight: "400",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                    fontWeight: "400",
                  },
                }}
              />
            }
          />
          <CustomListItem
            leftContainerWidth={150}
            text="Description (optional)"
            containerStyle={{ marginBottom: "16px" }}
            RightComponent={
              <TextField
                id="description"
                label="Description"
                variant="standard"
                style={{ width: "100%" }}
                value={task?.description}
                onChange={handleDeshange}
                disabled={!aclChecklist?.EditTemplate}
                InputProps={{
                  style: {
                    fontSize: "13px",
                    fontWeight: "400",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: "13px",
                    fontWeight: "400",
                  },
                }}
                multiline
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: "13px",
                    fontWeight: "400",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "13px",
                    fontWeight: "400",
                  },
                }}
              />
            }
          />
          <CustomListItem
            leftContainerWidth={150}
            text="Category"
            containerStyle={{ marginBottom: "16px" }}
            RightComponent={
              <FormControl fullWidth>
                <Select
                  value={selectedCategories}
                  displayEmpty
                  multiple
                  variant="standard"
                  onChange={handleSelectCategories}
                  disabled={!aclChecklist?.EditTemplate}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return (
                        <Typography
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            color: "gray",
                          }}
                        >
                          Select categories
                        </Typography>
                      );
                    }
                    return (
                      <Typography
                        style={{
                          maxWidth: "300px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                        variant="subtitle2"
                      >
                        {selected
                          .map(
                            (id) =>
                              categories?.find(
                                (c) => Number(c.category_id) === Number(id)
                              )?.name
                          )
                          .join(", ")}
                      </Typography>
                    );
                  }}
                  inputProps={{ "aria-label": "Select employee" }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        width: 300,
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  {categories?.map((e) => (
                    <MenuItem
                      key={e.category_id}
                      value={e.category_id.toString()}
                    >
                      <Checkbox
                        checked={selectedCategories.includes(
                          Number(e.category_id)
                        )}
                      />
                      <Typography>{e.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          />
          <CustomListItem
            leftContainerWidth={150}
            text="Status"
            containerStyle={{ marginTop: "20px" }}
            RightComponent={
              <>
                <FormControl>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Switch
                      inputProps={{
                        "aria-label": "Required note status toggle",
                      }}
                      color="primary"
                      checked={task?.note === 1}
                      disabled={!aclChecklist?.EditTemplate}
                      onChange={(e) =>
                        handleSwitchChange("note", e.target.checked)
                      }
                    />
                    <Typography variant="subtitle2">Required note</Typography>
                  </Box>
                </FormControl>
                <FormControl style={{ marginLeft: "40px" }}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Switch
                      inputProps={{
                        "aria-label": "Required photo status toggle",
                      }}
                      color="primary"
                      disabled={!aclChecklist?.EditTemplate}
                      checked={task?.photo === 1}
                      onChange={(e) =>
                        handleSwitchChange("photo", e.target.checked)
                      }
                    />
                    <Typography variant="subtitle2">Required photo</Typography>
                  </Box>
                </FormControl>
              </>
            }
          />
        </Box>
        <Box
          sx={{
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "40px",
            paddingRight: "20px",
            paddingBottom: "20px",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          {aclChecklist?.EditTemplate && (
            <>
              <Button
                variant="outlined"
                style={{
                  color: "gray",
                  borderColor: "gray",
                  fontSize: "13px",
                  maxHeight: "36px",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                style={{ fontSize: "13px", maxHeight: "36px" }}
                variant="contained"
                onClick={handleConfirmed}
              >
                Save
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default TaskTemplateDetailModal;
