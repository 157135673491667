import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Categories,
  CompanyApi,
  Task,
  TaskTemplateForm,
} from "../../../../../services/phpServices";
import { CustomListItem } from "../../../components/ListItem";
import { useEffect, useState } from "react";
import { useStores } from "../../../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Moment } from "moment";
import moment from "moment";
import { Colors } from "../../../../../theme";

interface NewTaskProps {
  open: boolean;
  onConfirmed?: (t: Task | TaskTemplateForm) => void;
  onClose?: () => void;
  isTemplate?: boolean;
  isCreateTask?: boolean;
}

const NewTask: React.FC<NewTaskProps> = observer(
  ({
    open,
    onConfirmed,
    onClose,
    isTemplate = false,
    isCreateTask = false,
  }) => {
    const defaultTaskTemplate: TaskTemplateForm = {
      task_template_id: 0,
      name: "",
      description: "",
      note: 0,
      photo: 0,
      checkin: 0,
      require_time: 0,
      category_ids: [0],
    };

    const defaultTask: Task = {
      task_id: 0,
      name: "",
      description: "",
      note: 0,
      photo: 0,
      checkin: 0,
      require_time: 0,
      status: "PUBLISH",
    };

    const [task, setTask] = useState<any>(
      isTemplate ? defaultTaskTemplate : defaultTask
    );
    const {
      templateStore: { createTaskTemplate },
    } = useStores();
    const [categories, setCategories] = useState<Categories[] | undefined>([]);
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

    const roundUpToNextHour = (time: Moment) => {
      return time.minute() > 0 ? time.startOf("hour").add(1, "hour") : time;
    };

    const [requiredTime, setRequiredTime] = useState<Moment | null>(
      roundUpToNextHour(moment())
    );
    const [isOpenTimePicker, setIsOpenTimePicker] = useState<boolean>(false);

    const handleChange = (field: keyof Task, value: any) => {
      setTask((prevTask: any) => ({
        ...prevTask,
        [field]: value,
      }));
    };
    const handleClose = () => {
      if (!isTemplate) {
        setTask(defaultTask);
      } else {
        setTask(defaultTaskTemplate);
      }
      setSelectedCategories([]);
      if (onClose) {
        onClose();
      }
    };

    const handleSwitchChange = (field: keyof Task, checked: boolean) => {
      setTask((prevTask: any) => ({
        ...prevTask,
        [field]:
          field === "status"
            ? checked
              ? "PUBLISH"
              : "UNPUBLISH"
            : checked
            ? field === "require_time"
              ? roundUpToNextHour(moment()).hours() * 60 +
                roundUpToNextHour(moment()).minutes()
              : 1
            : 0,
      }));

      if (field === "require_time" && checked) {
        const newTime = roundUpToNextHour(moment());
        setRequiredTime(newTime);
        setIsOpenTimePicker(true);
      } else if (field === "require_time" && !checked) {
        setRequiredTime(null);
        setIsOpenTimePicker(false);
      }
    };

    const handleConfirm = async () => {
      if (onConfirmed && !isCreateTask) {
        onConfirmed(task);
        handleClose();
      }
      if (isCreateTask && onConfirmed) {
        try {
          await createTaskTemplate(task);
        } catch {
          //
        }
        onConfirmed(task);
        handleClose();
      }
    };

    const handleSaveAndNew = async () => {
      if (onConfirmed) {
        try {
          await createTaskTemplate(task);
        } catch {
          //
        }
        onConfirmed(task);
        setTask(defaultTaskTemplate);
        setSelectedCategories([]);
      }
    };

    const handleSelectCategories = (event: any) => {
      const value = event.target.value;
      setSelectedCategories((prevSelectedCategories) => {
        const newSelectedCategories = prevSelectedCategories.includes(
          Number(value[value.length - 1])
        )
          ? prevSelectedCategories.filter(
              (categoryId) => categoryId !== Number(value[value.length - 1])
            )
          : [...prevSelectedCategories, Number(value[value.length - 1])];
        setTask((prevTask: any) => ({
          ...prevTask,
          category_ids: newSelectedCategories,
        }));
        return newSelectedCategories;
      });
    };

    async function getCategories() {
      try {
        const res = await new CompanyApi().getListCategories({
          type: "TASKS",
          limit: 1000,
          page: 1,
        });
        setCategories(res.data.data);
      } catch (e) {
        //
      }
    }
    useEffect(() => {
      getCategories();
    }, []);

    return (
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: 24,
            width: "500px",
            maxHeight: "80vh",
            transform: "translate(-50%, -50%)",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              height: "40px",
              backgroundColor: "#08B0A0",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px 4px 0 0",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h5"
              color={Colors.white}
              sx={{ paddingLeft: "16px" }}
            >
              NEW TASK
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "white", width: 24, height: 24 }} />
            </IconButton>
          </Box>

          <Box
            sx={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
              overflowY: "auto",
              maxHeight: "calc(80vh - 40px)",
              marginTop: "8px",
            }}
          >
            <CustomListItem
              leftContainerWidth={150}
              text="Name*"
              containerStyle={{ marginBottom: "16px" }}
              RightComponent={
                <TextField
                  id="task-name"
                  label="Task name"
                  variant="standard"
                  style={{ width: "100%", fontSize: "13px" }}
                  value={task.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  InputProps={{
                    style: {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                  }}
                />
              }
            />
            <CustomListItem
              leftContainerWidth={150}
              text="Description (optional)"
              containerStyle={{ marginBottom: "16px" }}
              RightComponent={
                <TextField
                  id="description"
                  label="Description"
                  variant="standard"
                  style={{ width: "100%", fontSize: "13px" }}
                  value={task.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  multiline
                  InputProps={{
                    style: {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                      fontWeight: "400",
                    },
                  }}
                />
              }
            />
            {isTemplate === true && (
              <CustomListItem
                leftContainerWidth={150}
                text="Category"
                containerStyle={{ marginBottom: "16px" }}
                RightComponent={
                  <FormControl fullWidth>
                    <Select
                      value={selectedCategories}
                      displayEmpty
                      multiple
                      variant="standard"
                      onChange={handleSelectCategories}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <Typography
                              style={{
                                color: "gray",
                              }}
                              variant="subtitle2"
                            >
                              Select categories
                            </Typography>
                          );
                        }
                        return (
                          <Typography
                            style={{
                              maxWidth: "300px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                            variant="subtitle2"
                          >
                            {selected
                              .map(
                                (id) =>
                                  categories?.find(
                                    (c) => Number(c.category_id) === Number(id)
                                  )?.name
                              )
                              .join(", ")}
                          </Typography>
                        );
                      }}
                      inputProps={{ "aria-label": "Select employee" }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            width: 300,
                          },
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    >
                      {categories?.map((e) => (
                        <MenuItem
                          key={e.category_id}
                          value={e.category_id.toString()}
                        >
                          <Checkbox
                            checked={selectedCategories.includes(
                              Number(e.category_id)
                            )}
                          />
                          <Typography>{e.name}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              />
            )}
            <CustomListItem
              leftContainerWidth={150}
              text="Status"
              containerStyle={{ marginTop: "20px" }}
              RightComponent={
                <Box
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <FormControl>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Switch
                        inputProps={{
                          "aria-label": "Required note status toggle",
                        }}
                        color="primary"
                        checked={task.note === 1}
                        onChange={(e) =>
                          handleSwitchChange("note", e.target.checked)
                        }
                      />
                      <Typography variant="subtitle2">Required note</Typography>
                    </Box>
                  </FormControl>
                  <FormControl>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Switch
                        inputProps={{
                          "aria-label": "Required photo status toggle",
                        }}
                        color="primary"
                        checked={task.photo === 1}
                        onChange={(e) =>
                          handleSwitchChange("photo", e.target.checked)
                        }
                      />
                      <Typography variant="subtitle2">
                        Required photo
                      </Typography>
                    </Box>
                  </FormControl>
                  <FormControl>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Switch
                        inputProps={{
                          "aria-label": "Required time toggle",
                        }}
                        color="primary"
                        checked={task.require_time > 0}
                        onChange={(e) => {
                          handleSwitchChange("require_time", e.target.checked);
                          if (e.target.checked) {
                            setRequiredTime(roundUpToNextHour(moment()));
                          } else {
                            setRequiredTime(null);
                          }
                        }}
                      />
                      <Typography variant="subtitle2">Required time</Typography>
                    </Box>
                  </FormControl>
                  {isOpenTimePicker && (
                    <FormControl sx={{ padding: "20px" }}>
                      <TimePicker
                        label="Select Time"
                        value={requiredTime}
                        minutesStep={15}
                        onChange={(newValue) => {
                          setRequiredTime(newValue);
                          handleChange(
                            "require_time",
                            newValue
                              ? newValue.hours() * 60 + newValue.minutes()
                              : 0
                          );
                        }}
                        slotProps={{
                          textField: {
                            variant: "standard",
                            InputProps: {
                              style: {
                                fontSize: "13px",
                                fontWeight: "400",
                              },
                            },
                            InputLabelProps: {
                              style: {
                                fontSize: "13px",
                                fontWeight: "400",
                              },
                            },
                          },
                          actionBar: {
                            sx: {
                              display: "flex",
                              justifyContent: "center",
                            },
                          },
                        }}
                      />
                    </FormControl>
                  )}
                  {!isTemplate && (
                    <FormControl>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Switch
                          inputProps={{
                            "aria-label": "Published",
                          }}
                          color="primary"
                          checked={task.status === "PUBLISH"}
                          onChange={(e) =>
                            handleSwitchChange("status", e.target.checked)
                          }
                        />
                        <Typography variant="subtitle2">Published</Typography>
                      </Box>
                    </FormControl>
                  )}
                </Box>
              }
            />
          </Box>

          <Box
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              marginTop: "40px",
              paddingRight: "20px",
              paddingBottom: "20px",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <Button
              variant="outlined"
              style={{
                color: "gray",
                borderColor: "gray",
                fontSize: "13px",
                maxHeight: "36px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ fontSize: "13px", maxHeight: "36px" }}
              onClick={handleConfirm}
              disabled={task.name === ""}
            >
              Save
            </Button>
            {isTemplate && isCreateTask && (
              <Button
                variant="contained"
                style={{ fontSize: "13px", maxHeight: "36px" }}
                onClick={handleSaveAndNew}
                disabled={task.name === ""}
              >
                Save and new
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    );
  }
);

export default NewTask;
