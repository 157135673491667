import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Card } from "../../../components/Card";
import { TaskTemplate } from "../../../../../services/phpServices";
import { CheckCircleOutline, CircleOutlined } from "@mui/icons-material";
import NoDataFound from "../../../components/NoDataFound";
import { useStores } from "../../../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";
import { Colors } from "../../../../../theme";

interface TasksContentProps {
  onConfirmed?: (tasks: TaskTemplate[]) => void;
  onClose?: () => void;
}

const ImportTaskContent: React.FC<TasksContentProps> = observer(
  ({ onConfirmed, onClose }) => {
    const [selectedTasks, setSelectedTasks] = React.useState<TaskTemplate[]>(
      []
    );
    const {
      templateStore: { taskTemplates, getListTaskTemplate, isGetLoading },
    } = useStores();

    const handleConfirm = async () => {
      if (onConfirmed && selectedTasks.length > 0) {
        onConfirmed(selectedTasks);
        onClose && onClose();
      }
    };

    const handleSelectTask = (task: TaskTemplate) => {
      setSelectedTasks((prevSelectedTasks) => {
        if (
          prevSelectedTasks.some(
            (selected) => selected.task_template_id === task.task_template_id
          )
        ) {
          return prevSelectedTasks.filter(
            (selected) => selected.task_template_id !== task.task_template_id
          );
        } else {
          return [...prevSelectedTasks, task];
        }
      });
    };

    async function fetchData() {
      try {
        getListTaskTemplate();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <Box
        sx={{
          paddingLeft: "16px",
          paddingRight: "16px",
          maxHeight: "600px",
          minHeight: "300px",
          overflowY: "auto",
          paddingTop: "16px",
        }}
      >
        {isGetLoading && <CircularProgress size={24} />}
        {taskTemplates && taskTemplates.length === 0 && <NoDataFound />}
        {taskTemplates && taskTemplates?.length > 0 && !isGetLoading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              paddingBottom: "80px",
            }}
          >
            {taskTemplates?.map((t, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography variant="h5" color={Colors.black}>
                  {t?.name}
                </Typography>
                {t.template_taks?.map((task, index) => (
                  <Card
                    key={index}
                    onClick={() => handleSelectTask(task)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "8px",
                    }}
                    LeftComponent={
                      selectedTasks?.some(
                        (t) => t.task_template_id === task.task_template_id
                      ) ? (
                        <CheckCircleOutline style={{ color: "#08B0A0" }} />
                      ) : (
                        <CircleOutlined style={{ color: "gray" }} />
                      )
                    }
                    ContentComponent={
                      <Typography variant="subtitle2" color={Colors.black}>
                        {task?.name}
                      </Typography>
                    }
                  />
                ))}
              </Box>
            ))}
          </Box>
        )}

        <Box
          sx={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            backgroundColor: "white",
            paddingTop: "16px",
            paddingBottom: "16px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
            paddingRight: "16px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              color: "gray",
              borderColor: "gray",
              fontSize: "13px",
              maxHeight: "36px",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            style={{ fontSize: "13px", maxHeight: "36px" }}
            disabled={selectedTasks.length === 0}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  }
);

export default ImportTaskContent;
