/* tslint:disable */
/* eslint-disable */
/**
 * TitanPlan
 * TitanPlan API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ETransactionType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ETransactionType = typeof ETransactionType[keyof typeof ETransactionType];



