import {
  Box,
  Button,
  FormControl,
  IconButton,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Task, TasksApi } from "../../../../../services/phpServices";
import { CustomListItem } from "../../../components/ListItem";
import { useEffect, useState } from "react";
import { Colors } from "../../../../../theme";

interface EditTaskProps {
  id: number | undefined;
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

const EditTask: React.FC<EditTaskProps> = ({
  open,
  onClose,
  id,
  onConfirm,
}) => {
  const [task, setTask] = useState<Task>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await new TasksApi().detailTask(id ?? 0);
        setTask(res.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [id, open]);

  const handleChange = (field: keyof Task, value: any) => {
    setTask((prevTask: any) => ({
      ...prevTask,
      [field]: value,
    }));
  };
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSwitchChange = (field: keyof Task, checked: boolean) => {
    setTask((prevTask: any) => ({
      ...prevTask,
      [field]: checked ? 1 : 0,
    }));
  };

  async function handleConfirm() {
    try {
      await new TasksApi().updateTask(id ?? 0, "web", task);
      handleClose();
      onConfirm && onConfirm();
    } catch {
      //
    }
  }

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          backgroundColor: "white",
          borderRadius: "4px",
          boxShadow: 24,
          width: "500px",
          maxHeight: "80vh",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            height: "40px",
            backgroundColor: "#08B0A0",
            display: "flex",
            alignItems: "center",
            borderRadius: "4px 4px 0 0",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h5"
            color={Colors.white}
            sx={{ paddingLeft: "16px" }}
          >
            NEW TASK
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: Colors.white, width: 24, height: 24 }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "20px",
            overflowY: "auto",
            maxHeight: "calc(80vh - 40px)",
            marginTop: "16px",
          }}
        >
          <CustomListItem
            leftContainerWidth={150}
            text="Name*"
            containerStyle={{ marginBottom: "16px" }}
            RightComponent={
              <TextField
                id="task-name"
                label="Task name"
                variant="standard"
                style={{ width: "100%", fontSize: "13px" }}
                value={task?.name}
                onChange={(e) => handleChange("name", e.target.value)}
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: "13px",
                    fontWeight: "400",
                  },
                }}
              />
            }
          />
          <CustomListItem
            leftContainerWidth={150}
            text="Description (optional)"
            containerStyle={{ marginBottom: "16px" }}
            RightComponent={
              <TextField
                id="description"
                label="Description"
                variant="standard"
                style={{ width: "100%", fontSize: "13px" }}
                value={task?.description ?? ""}
                onChange={(e) => handleChange("description", e.target.value)}
                multiline
                sx={{
                  "& .MuiInputLabel-root": {
                    fontSize: "13px",
                    fontWeight: "400",
                  },
                }}
              />
            }
          />
          <CustomListItem
            leftContainerWidth={150}
            text="Status"
            containerStyle={{ marginTop: "20px" }}
            RightComponent={
              <Box
                sx={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <FormControl>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Switch
                      inputProps={{
                        "aria-label": "Required note status toggle",
                      }}
                      color="primary"
                      checked={task?.note === 1}
                      onChange={(e) =>
                        handleSwitchChange("note", e.target.checked)
                      }
                    />
                    <Typography variant="subtitle2">Required note</Typography>
                  </Box>
                </FormControl>
                <FormControl>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Switch
                      inputProps={{
                        "aria-label": "Required photo status toggle",
                      }}
                      color="primary"
                      checked={task?.photo === 1}
                      onChange={(e) =>
                        handleSwitchChange("photo", e.target.checked)
                      }
                    />
                    <Typography variant="subtitle2">Required photo</Typography>
                  </Box>
                </FormControl>
                <FormControl>
                  <Box display="flex" alignItems="center" gap={2}>
                    <Switch
                      inputProps={{
                        "aria-label": "Published",
                      }}
                      color="primary"
                      checked={task?.status === "PUBLISH"}
                      onChange={(e) =>
                        handleSwitchChange("status", e.target.checked)
                      }
                    />
                    <Typography variant="subtitle2">Published</Typography>
                  </Box>
                </FormControl>
              </Box>
            }
          />
        </Box>

        <Box
          sx={{
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "40px",
            paddingRight: "20px",
            paddingBottom: "20px",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <Button
            variant="outlined"
            style={{
              fontSize: "13px",
              maxHeight: "36px",
              color: "gray",
              borderColor: "gray",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ fontSize: "13px", maxHeight: "36px" }}
            variant="contained"
            onClick={handleConfirm}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditTask;
