import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  Modal,
  Switch,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  SettingRepeat,
  SettingRepeatFrequencyEnum,
} from "../../../../../services/phpServices";
import { observer } from "mobx-react-lite";
import { CustomListItem } from "../../../components/ListItem";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { KeyboardArrowDown } from "@mui/icons-material";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { Colors } from "../../../../../theme";

interface RepeatSettingsProps {
  open: boolean;
  onConfirmed?: (s: SettingRepeat) => void;
  onClose?: () => void;
  settings?: SettingRepeat;
  startTime: number;
  endTime: number;
  isEdit?: boolean;
}

const RepeatSettings: React.FC<RepeatSettingsProps> = observer(
  ({ open, onConfirmed, onClose, settings, startTime, endTime, isEdit }) => {
    const [frequency, setFrequency] = useState<SettingRepeatFrequencyEnum>(
      settings?.frequency ?? "daily"
    );
    const [endRepeat, setEndRepeat] = useState<string | undefined>(
      settings?.end_repeat ?? "until_date"
    );
    const [selectedDays, setSelectedDays] = useState<
      { value: number; label: string }[]
    >([]);
    const [selectedDates, setSelectedDates] = useState<
      { value: number; label: string }[]
    >([]);
    const [localEndTime, setLocalEndTime] = useState<Moment | undefined>(
      moment.unix(isEdit && settings?.end_date ? settings.end_date : endTime)
    );

    const [repeatStatus, setRepeatStatus] = useState<number>(
      settings?.repeat ?? 1
    );
    const [occurrences, setOccurrences] = useState<number>(1);
    const [settingsObj, setSettingObj] = useState<any>();

    const handleRepeatToggle = () => {
      setRepeatStatus((prev) => {
        const newStatus = prev === 0 ? 1 : 0;

        if (newStatus === 0) {
          setFrequency("daily");
          setEndRepeat("until_date");
          setSelectedDays([]);
          setSelectedDates([]);
          setLocalEndTime(moment.unix(endTime));
        }
        return newStatus;
      });
    };

    const handleFrequencyChange = (event: any, newValue: string | null) => {
      if (newValue) {
        setFrequency(newValue as SettingRepeatFrequencyEnum);
      }
    };

    const handleEndRepeatChange = (event: any, newValue: string | null) => {
      if (newValue) {
        setEndRepeat(newValue);
      }
    };

    const handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const dayValue = parseInt(event.target.value);
      const dayLabel =
        daysOfWeek.find((d) => d.value === dayValue)?.label || "";

      setSelectedDays((prev) =>
        prev.some((d) => d.value === dayValue)
          ? prev.filter((d) => d.value !== dayValue)
          : [...prev, { value: dayValue, label: dayLabel }]
      );
    };

    const handleDateChange = (
      event: any,
      newValue: { value: number; label: string }[] | null
    ) => {
      if (newValue) {
        setSelectedDates(newValue);
      }
    };

    const getMaxOccurrences = () => {
      if (frequency === "monthly") {
        return 12;
      } else if (frequency === "weekly") {
        return 12 * 4;
      }
      return undefined;
    };

    const frequencyOptions = [
      { label: "Daily", value: "daily" },
      { label: "Weekly", value: "weekly" },
      { label: "Monthly", value: "monthly" },
    ];
    const endRepeatOptions = [
      { label: "Until date", value: "until_date" },
      { label: "After x occurrences", value: "after_x_occurrences" },
    ];
    const daysOfWeek = [
      { value: 1, label: "Monday" },
      { value: 2, label: "Tuesday" },
      { value: 3, label: "Wednesday" },
      { value: 4, label: "Thursday" },
      { value: 5, label: "Friday" },
      { value: 6, label: "Saturday" },
      { value: 7, label: "Sunday" },
    ];
    const dateOptions = Array.from({ length: 31 }, (_, i) => i + 1).map(
      (date) => ({
        value: date,
        label: `${date}${
          ["th", "st", "nd", "rd"][
            date % 10 > 3 || Math.floor((date % 100) / 10) === 1 ? 0 : date % 10
          ]
        }`,
      })
    );

    const getRepeatSummary = () => {
      if (repeatStatus === 0) return "";
      const endRepeatText =
        endRepeat === "until_date"
          ? `until ${localEndTime?.format("DD/MM/YYYY")}`
          : `after ${occurrences} occurrence${occurrences > 1 ? "s" : ""}`;
      if (frequency === "weekly") {
        const daysText = selectedDays
          .sort((a, b) => a.value - b.value)
          .map((day) => day.label)
          .join(", ");
        return `Repeat ${frequency} on ${daysText} ${endRepeatText}`;
      } else if (frequency === "monthly") {
        const datesText = selectedDates
          .sort((a, b) => a.value - b.value)
          .map((date) => date.label)
          .join(", ");
        return `Repeat ${frequency} on ${datesText} ${endRepeatText}`;
      } else {
        return `Repeat ${frequency} ${endRepeatText}`;
      }
    };

    const occurrencesCheckWeek = (
      startTime: number,
      endTime: number,
      selectedDays: number[]
    ): boolean => {
      let current = moment.unix(startTime);
      const end = moment.unix(endTime);
      while (current.isBefore(end) || current.isSame(end, "day")) {
        if (selectedDays.includes(current.isoWeekday())) {
          return true;
        }
        current.add(1, "day");
      }
      return false;
    };

    const occurrencesCheckMonth = (
      startTime: number,
      endTime: number,
      selectedDates: number[]
    ): boolean => {
      let current = moment.unix(startTime).startOf("month");
      const end = moment.unix(endTime).endOf("month");
      while (current.isBefore(end) || current.isSame(end, "month")) {
        const startDay = current.isSame(moment.unix(startTime), "month")
          ? moment.unix(startTime).date()
          : 1;
        const endDay = current.isSame(moment.unix(endTime), "month")
          ? moment.unix(endTime).date()
          : current.daysInMonth();
        if (selectedDates.some((day) => day >= startDay && day <= endDay)) {
          return true;
        }
        current.add(1, "month");
      }
      return false;
    };

    useEffect(() => {
      setSettingObj({
        repeat: repeatStatus,
        frequency: frequency,
        frequency_values:
          frequency === "weekly"
            ? selectedDays.map((day) => day.value)
            : frequency === "monthly"
            ? selectedDates.map((date) => date.value)
            : [],
        end_repeat: endRepeat,
        end_repeat_value: occurrences,
        end_date:
          frequency === "weekly" && endRepeat === "after_x_occurrences"
            ? moment.unix(startTime).add(occurrences, "week").unix()
            : frequency === "monthly" && endRepeat === "after_x_occurrences"
            ? moment.unix(startTime).add(occurrences, "month").unix()
            : frequency === "daily" && endRepeat === "after_x_occurrences"
            ? moment.unix(startTime).add(occurrences, "day").unix()
            : localEndTime?.unix(),
      });
    }, [
      repeatStatus,
      frequency,
      selectedDays,
      selectedDates,
      endRepeat,
      localEndTime,
      occurrences,
      startTime,
    ]);

    return (
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: 24,
            width: "500px",
            maxHeight: "80vh",
            transform: "translate(-50%, -50%)",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              height: "40px",
              backgroundColor: "#08B0A0",
              display: "flex",
              alignItems: "center",
              borderRadius: "4px 4px 0 0",
              justifyContent: "space-between",
            }}
          >
            <Typography
              color={Colors.white}
              sx={{ paddingLeft: "16px" }}
              variant="h5"
            >
              REPEAT SETTINGS
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ color: Colors.white, width: 24, height: 24 }} />
            </IconButton>
          </Box>

          <Box
            sx={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
              overflowY: "auto",
              maxHeight: "calc(80vh - 40px)",
              marginTop: "8px",
            }}
          >
            <CustomListItem
              leftContainerWidth={150}
              text="Repeat"
              containerStyle={{ marginTop: "10px", marginBottom: "10px" }}
              RightComponent={
                <Box
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <FormControl>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Switch
                        checked={repeatStatus === 1}
                        onChange={handleRepeatToggle}
                        inputProps={{ "aria-label": "Repeat toggle" }}
                        color="primary"
                        disabled={isEdit}
                      />
                    </Box>
                  </FormControl>
                </Box>
              }
            />
            {repeatStatus === 1 && (
              <div>
                <CustomListItem
                  leftContainerWidth={150}
                  text="Frequency"
                  containerStyle={{ marginBottom: "16px" }}
                  RightComponent={
                    <FormControl fullWidth>
                      <Autocomplete
                        id="Frequency"
                        disabled={isEdit}
                        value={frequencyOptions.find(
                          (opt) => opt.value === frequency
                        )}
                        onChange={(event, newValue) =>
                          handleFrequencyChange(event, newValue?.value)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Frequency"
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "13px",
                                fontWeight: "400",
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "13px",
                                fontWeight: "400",
                                maxWidth: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            }}
                          />
                        )}
                        options={frequencyOptions}
                        getOptionLabel={(option) => option.label}
                        disableClearable
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            style={{ fontSize: "13px", fontWeight: "400" }}
                          >
                            {option.label}
                          </li>
                        )}
                      />
                    </FormControl>
                  }
                />
                {frequency === "weekly" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      ml: 2,
                      justifyContent: "center",
                      marginLeft: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    {daysOfWeek.map((day) => (
                      <FormControlLabel
                        key={day.value}
                        style={{ width: "70px" }}
                        control={
                          <Checkbox
                            checked={selectedDays.some(
                              (d) => d.value === day.value
                            )}
                            onChange={handleDayChange}
                            disabled={isEdit}
                            value={day.value}
                          />
                        }
                        label={day.label}
                        sx={{
                          flexDirection: "column",
                          alignItems: "center",
                          "& .MuiFormControlLabel-label": { fontSize: "11px" },
                        }}
                      />
                    ))}
                  </Box>
                )}
                {frequency === "monthly" && (
                  <CustomListItem
                    leftContainerWidth={150}
                    text="Repeat on the same date each month"
                    containerStyle={{ marginBottom: "16px" }}
                    RightComponent={
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple
                          disabled={isEdit}
                          id="Repeat on the same date each month"
                          value={selectedDates}
                          onChange={handleDateChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Dates"
                              sx={{
                                "& .MuiInputLabel-root": {
                                  fontSize: "13px",
                                  fontWeight: "400",
                                },
                                "& .MuiInputBase-input": {
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  maxWidth: "300px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                },
                              }}
                            />
                          )}
                          options={dateOptions}
                          getOptionLabel={(option) => option.label}
                          disableCloseOnSelect
                          renderTags={(value, getTagProps) => {
                            return value.map((option, index) => (
                              <Typography
                                key={option.value}
                                style={{
                                  margin: "2px",
                                }}
                                variant="subtitle2"
                              >
                                {option.label}
                                {index < value.length - 1 && ", "}
                              </Typography>
                            ));
                          }}
                          renderOption={(props, option, state) => (
                            <li
                              {...props}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Checkbox
                                checked={selectedDates.some(
                                  (date) => date.value === option.value
                                )}
                              />
                              <Typography>{option.label}</Typography>
                            </li>
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                        />
                      </FormControl>
                    }
                  />
                )}
                <CustomListItem
                  leftContainerWidth={150}
                  text="End repeat"
                  containerStyle={{ marginBottom: "16px" }}
                  RightComponent={
                    <FormControl fullWidth>
                      <Autocomplete
                        id="End repeat"
                        disabled={isEdit}
                        onChange={(event, newValue) =>
                          handleEndRepeatChange(event, newValue?.value)
                        }
                        value={endRepeatOptions.find(
                          (opt) => opt.value === endRepeat
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="End repeat"
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontSize: "13px",
                                fontWeight: "400",
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "13px",
                                fontWeight: "400",
                                maxWidth: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                            }}
                          />
                        )}
                        options={endRepeatOptions}
                        getOptionLabel={(option) => option.label}
                        disableClearable
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            style={{ fontSize: "13px", fontWeight: "400" }}
                          >
                            {option.label}
                          </li>
                        )}
                      />
                    </FormControl>
                  }
                />
                {endRepeat === "until_date" && (
                  <CustomListItem
                    leftContainerWidth={150}
                    text="End date"
                    containerStyle={{ marginBottom: "16px" }}
                    RightComponent={
                      <DesktopDatePicker
                        value={localEndTime}
                        format="DD/MM/YYYY"
                        minDate={moment.unix(startTime)}
                        disabled={isEdit}
                        onChange={(newValue) =>
                          setLocalEndTime(moment(newValue))
                        }
                        maxDate={moment.unix(endTime).add(1, "year")}
                        slots={{ openPickerIcon: KeyboardArrowDown }}
                        slotProps={{
                          textField: {
                            variant: "standard",
                            style: { width: "50%", fontSize: "13px" },
                            InputLabelProps: { size: "small" },
                            sx: {
                              "& .MuiInputLabel-root": {
                                fontSize: "13px",
                                fontWeight: "400",
                              },
                              "& .MuiInputBase-input": {
                                fontSize: "13px",
                                fontWeight: "400",
                              },
                            },
                          },
                        }}
                      />
                    }
                  />
                )}
                {endRepeat === "after_x_occurrences" && (
                  <CustomListItem
                    leftContainerWidth={150}
                    text="Occurrences"
                    containerStyle={{ marginBottom: "16px" }}
                    RightComponent={
                      <TextField
                        type="number"
                        disabled={isEdit}
                        value={occurrences}
                        onChange={(e) => {
                          const newValue = Math.max(
                            1,
                            Math.min(
                              getMaxOccurrences() || 100,
                              Number(e.target.value)
                            )
                          );
                          setOccurrences(newValue);
                          setLocalEndTime(
                            frequency === "weekly"
                              ? moment.unix(startTime).add(newValue, "week")
                              : frequency === "monthly"
                              ? moment.unix(startTime).add(newValue, "month")
                              : frequency === "daily"
                              ? moment.unix(startTime).add(newValue, "day")
                              : localEndTime
                          );
                        }}
                        inputProps={{ min: 1, max: getMaxOccurrences() }}
                        variant="standard"
                        sx={{
                          "& .MuiInputBase-input": {
                            fontSize: "13px",
                            fontWeight: "400",
                          },
                        }}
                      />
                    }
                  />
                )}
              </div>
            )}
            <Box sx={{ marginTop: "30px" }}>
              <Typography
                style={{
                  margin: "2px",
                  color:
                    (frequency === "weekly" &&
                      occurrencesCheckWeek(
                        startTime,
                        localEndTime?.unix() ?? 0,
                        selectedDays.map((d) => d.value)
                      ) === false) ||
                    (frequency === "monthly" &&
                      occurrencesCheckMonth(
                        startTime,
                        localEndTime?.unix() ?? 0,
                        selectedDates.map((d) => d.value)
                      ) === false)
                      ? "red"
                      : "gray",
                }}
                variant="subtitle2"
              >
                {getRepeatSummary()}
              </Typography>
            </Box>
          </Box>
          {!isEdit && (
            <Box
              sx={{
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "40px",
                paddingRight: "20px",
                paddingBottom: "20px",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <Button
                variant="outlined"
                style={{
                  color: "gray",
                  borderColor: "gray",
                  fontSize: "13px",
                  maxHeight: "36px",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ fontSize: "13px", maxHeight: "36px" }}
                onClick={() => {
                  if (onConfirmed && settingsObj) {
                    onConfirmed(settingsObj);
                  }
                  onClose && onClose();
                }}
                disabled={
                  repeatStatus === 1 &&
                  (!frequency ||
                    (frequency === "weekly" &&
                      (selectedDays.length === 0 ||
                        occurrencesCheckWeek(
                          startTime,
                          localEndTime?.unix() ?? 0,
                          selectedDays.map((d) => d.value)
                        ) === false)) ||
                    (frequency === "monthly" &&
                      (selectedDates.length === 0 ||
                        occurrencesCheckMonth(
                          startTime,
                          localEndTime?.unix() ?? 0,
                          selectedDates.map((d) => d.value)
                        ) === false)))
                }
              >
                Submit
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    );
  }
);

export default RepeatSettings;
