import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { withSetPropAction } from "../helpers/withSetPropAction";
import {
  FilterChecklistReport,
  ResponseChecklistReport,
  ReportApi,
  ResponseEmployeeActivityInTask,
  ResponseDateInTasks,
  ResponseSummaryCheckList,
} from "../../services/phpServices";

export const ChecklistReportStoreModel = types
  .model("ChecklistReportStore")
  .props({
    isGetLoading: false,
    listChecklistReport: types.optional(
      types.frozen<ResponseChecklistReport[]>(),
      []
    ),
    filterCList: types.optional(types.frozen<FilterChecklistReport>(), {}),
    filterEmp: types.optional(types.frozen<FilterChecklistReport>(), {}),
    filterMonthly: types.optional(types.frozen<FilterChecklistReport>(), {}),
    listEmpReport: types.optional(
      types.frozen<ResponseEmployeeActivityInTask[]>(),
      []
    ),
    listEmpDateReport: types.optional(
      types.frozen<ResponseDateInTasks[]>(),
      []
    ),
    listSummaryReport: types.optional(
      types.frozen<ResponseSummaryCheckList | undefined>(),
      undefined
    ),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async checkListProgress(paramRequest?: FilterChecklistReport) {
      try {
        store.setProp("listChecklistReport", []);
        store.setProp("filterCList", paramRequest);
        store.setProp("isGetLoading", true);
        const { data } = await new ReportApi().chekListProgress(
          "web",
          paramRequest
        );
        store.setProp("listChecklistReport", data.data);
      } catch (error) {
        console.log(error);
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async employeeActivityInTask(paramRequest?: FilterChecklistReport) {
      try {
        store.setProp("isGetLoading", true);
        store.setProp("listEmpReport", []);
        store.setProp("filterEmp", paramRequest);
        const { data } = await new ReportApi().employeeActivityInTask(
          "web",
          paramRequest
        );
        store.setProp("listEmpReport", data.data);
      } catch (error) {
        console.log(error);
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async dateInTasks(paramRequest?: FilterChecklistReport) {
      try {
        store.setProp("filterEmp", paramRequest);
        store.setProp("listEmpDateReport", []);
        store.setProp("isGetLoading", true);
        const { data } = await new ReportApi().dateInTasks("web", paramRequest);
        store.setProp("listEmpDateReport", data);
      } catch (error) {
        console.log(error);
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async summaryCheckList(paramRequest?: FilterChecklistReport) {
      try {
        store.setProp("filterMonthly", paramRequest);
        store.setProp("isGetLoading", true);
        const { data } = await new ReportApi().summaryCheckList(
          "web",
          paramRequest
        );
        store.setProp("listSummaryReport", data);
      } catch (error) {
        console.log(error);
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
  }));

export interface ChecklistReportStore
  extends Instance<typeof ChecklistReportStoreModel> {}
export interface ChecklistReportStoreSnapshot
  extends SnapshotOut<typeof ChecklistReportStoreModel> {}
