import React from "react";
import { Box, Typography } from "@mui/material";
import { Task } from "../../../services/phpServices";

interface ProgressBarProps {
  data: Task[] | undefined;
  isEmp?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ data, isEmp = false }) => {
  const totalTasks = data?.length ?? 0;
  const completedTasks = data?.filter(
    (task: Task) => task.complete_status === "COMPLETED"
  ).length ?? 0;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "nowrap",
        overflowX: "auto",
        gap: "4px",
        width: "100%",
        marginBottom: isEmp ? "0px" : "16px",
      }}
    >
      {/* Dynamically fill the progress bar */}
      {Array.from({ length: totalTasks }).map((_, index) => {
        return (
          <Box
            key={index}
            style={{
              flex: `1 1 ${100 / totalTasks - 3}%`,
              height: "8px",
              backgroundColor:
                index < completedTasks ? "#08B0A0" : "#CCCCCC", // Fill completed tasks
              borderRadius: "2px",
            }}
          />
        );
      })}
      {data && data?.length > 0 && (
        <Typography
          style={{
            color: "#08B0A0",
            marginLeft: "12px",
            whiteSpace: "nowrap",
          }}
          variant="h5"
        >
          {completedTasks} / {totalTasks}
        </Typography>
      )}
    </Box>
  );
};

export default ProgressBar;