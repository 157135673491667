import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { withSetPropAction } from "../helpers/withSetPropAction";
import {
  CheckList,
  CreateChecklistRequest,
  EmployeeDetail,
  FormFilterTasks,
  FormListEmployee,
  ListTasksGroupEmployee,
  Task,
  TasksApi,
  UpdateChecklistRequest,
  UpdateTask,
  UserApi,
} from "../../services/phpServices";

export const AllChecklistStoreModel = types
  .model("AllChecklistStore")
  .props({
    isGetLoading: false,
    filter: types.optional(types.frozen<FormFilterTasks>(), {}),
    alltasks: types.optional(types.frozen<Task[]>(), []),
    emptasks: types.optional(types.frozen<ListTasksGroupEmployee[]>(), []),
    checklists: types.optional(types.frozen<CheckList[]>(), []),
    checklistDetail: types.optional(types.frozen<CheckList>(), {}),
    listEmps: types.optional(types.frozen<EmployeeDetail[]>(), []),
    isSubmitLoading: false,
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async getAllTasks(newFilter: FormFilterTasks) {
      store.setProp("filter", newFilter);
      try {
        store.setProp("isGetLoading", true);
        const { data } = await new TasksApi().getListTasks("web", newFilter);
        store.setProp("alltasks", data);
      } catch (error: any) {
        if (error.response) {
        }
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async getEmpTasks(newFilter: FormFilterTasks) {
      store.setProp("filter", newFilter);
      try {
        store.setProp("isGetLoading", true);
        const { data } = await new TasksApi().getListTasksGroupEmployee(
          "web",
          newFilter
        );
        store.setProp("emptasks", data);
      } catch (error: any) {
        if (error.response) {
        }
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async getListCList(newFilter: FormFilterTasks) {
      store.setProp("filter", newFilter);
      try {
        store.setProp("isGetLoading", true);
        const { data } = await new TasksApi().getChecklist("web", newFilter);
        store.setProp("checklists", data);
      } catch (error: any) {
        if (error.response) {
        }
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async deleteTask(id: number) {
      try {
        store.setProp("isSubmitLoading", true);
        await new TasksApi().deleteTask(id, "web", {
          status: "DELETE",
        });
      } catch (error) {
        console.error("Error deleting task:", error);
      } finally {
        store.setProp("isSubmitLoading", false);
      }
    },
    async updateTask(id: number, task: UpdateTask) {
      try {
        store.setProp("isSubmitLoading", true);
        await new TasksApi().updateTask(id, "web", task);
      } catch (error) {
        console.error("Error updating task:", error);
      } finally {
        store.setProp("isSubmitLoading", false);
      }
    },
    async changeStatusTask(id: number, date: number, status: string) {
      try {
        store.setProp("isSubmitLoading", true);
        await new TasksApi().changeStatusCompleteTask(id, date, "web", [], status, "");
      } catch (error) {
        console.error("Error updating task:", error);
      } finally {
        store.setProp("isSubmitLoading", false);
      }
    },
    async getChecklistDetail(id: number) {
      try {
        store.setProp("isGetLoading", true);
        const {data} = await new TasksApi().detailChecklist(id);
        store.setProp("checklistDetail", data)
      } catch (error) {
        console.error("Error get detail checklist:", error);
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async getListEmp(param: FormListEmployee) {
      try {
        store.setProp("isGetLoading", true);
        const {data} = await new UserApi().getListEmployee(param);
        store.setProp("listEmps", data.data)
      } catch (error) {
        console.error("Error get list employees:", error);
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async createChecklist(param: CreateChecklistRequest) {
      try {
        store.setProp("isSubmitLoading", true);
        await new TasksApi().createChecklist(param);
      } catch (error) {
        console.error("Error create checklist:", error);
      } finally {
        store.setProp("isSubmitLoading", false);
      }
    },
    async updateChecklist(id:number, param: UpdateChecklistRequest) {
      try {
        store.setProp("isSubmitLoading", true);
        await new TasksApi().updateChecklist(id, param);
      } catch (error) {
        console.error("Error updating task:", error);
      } finally {
        store.setProp("isSubmitLoading", false);
      }
    },
  }));

export interface AllChecklistStore extends Instance<typeof AllChecklistStoreModel> {}
export interface AllChecklistStoreSnapshot
  extends SnapshotOut<typeof AllChecklistStoreModel> {}
