import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { CustomListItem } from "../../../components/ListItem";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useMemo, useState } from "react";
import {
  Task,
  TaskTemplateCheckListForm,
  TaskTemplateForm,
} from "../../../../../services/phpServices";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import NewTask from "../../Everyone/Contents/NewTaskModal";
import ImportCListModal from "../ImportCListModal";
import { useStores } from "../../../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";
import { Colors } from "../../../../../theme";
interface NewChecklistModalProps {
  open: boolean;
  onClose: () => void;
  id?: number;
  onSucess?: () => void;
}

const ChecklistTemplateDetailModal: React.FC<NewChecklistModalProps> = observer(
  ({ open, onClose, id, onSucess }) => {
    const [openTaskModal, setOpenTaskModal] = useState(false);
    const [showImportClist, setShowImportClist] = useState(false);
    const {
      templateStore: {
        getChecklistTemplateDetail,
        createChecklistTemplate,
        updateChecklistTemplate,
        templateDetail,
      },
      appStore: { userProfile, getACLGroup },
    } = useStores();

    useEffect(() => {
      if (id !== 0) {
        const fetchData = async () => {
          try {
            await getChecklistTemplateDetail(id ?? 0);
          } catch (e) {
            console.log(e);
          }
        };
        fetchData();
      }
    }, [id, open]);

    const { aclChecklist } = useMemo(() => {
      const aclGroup = getACLGroup()?.[userProfile?.PrimaryGroup?.GroupId ?? 0];
      return {
        aclChecklist: aclGroup?.task,
      };
    }, [userProfile]);

    const defaultNewParam: TaskTemplateCheckListForm = {
      check_list_id: 0,
      name: "",
      templateTasks: [],
    };

    const defaultEditParam: TaskTemplateCheckListForm = {
      check_list_id: templateDetail?.check_list_id ?? 0,
      name: templateDetail?.name ?? "",
      templateTasks: (templateDetail?.tasks as TaskTemplateForm[]) ?? [],
    };

    const [params, setParams] = useState<TaskTemplateCheckListForm>(
      Number(id) === 0 ? defaultNewParam : defaultEditParam
    );

    useEffect(() => {
      if (templateDetail && id !== 0) {
        setParams(defaultEditParam);
      }
      if (id === 0) {
        setParams(defaultNewParam);
      }
    }, [templateDetail, id]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setParams({
        ...params,
        name: event.target.value,
      });
    };

    const handleTaskConfirmed = (t: Task) => {
      setParams((prevParams) => ({
        ...prevParams,
        templateTasks: [...((prevParams.templateTasks as any) ?? []), t],
      }));
    };

    const handleRemoveTask = (taskIndex: number) => {
      setParams((prevParams) => ({
        ...prevParams,
        templateTasks: prevParams?.templateTasks?.filter(
          (_, index) => index !== taskIndex
        ),
      }));
    };

    const handleImportClist = (cList: any) => {
      setParams((prevParams) => ({
        ...prevParams,
        name:
          prevParams.name !== ""
            ? prevParams.name
            : cList.name ?? prevParams.name,
        templateTasks: [
          ...(prevParams.templateTasks ?? []),
          ...(cList.tasks ?? []),
        ],
      }));
    };

    const handleImportTasks = (t: any[]) => {
      setParams((prevParams) => ({
        ...prevParams,
        templateTasks: [...(prevParams.templateTasks ?? []), ...(t ?? [])],
      }));
    };

    const handleClose = () => {
      if (id === 0) {
        setParams(defaultNewParam);
      } else {
        setParams(defaultEditParam);
      }
      onClose();
    };

    async function createChecklist() {
      try {
        if (id === 0) {
          await createChecklistTemplate(params);
        } else {
          await updateChecklistTemplate(id ?? 0, params);
        }
        if (onSucess) {
          onSucess();
        }
      } catch (e) {
        console.log(e);
      } finally {
        handleClose();
      }
    }

    return (
      <>
        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              backgroundColor: "white",
              borderRadius: "4px",
              boxShadow: 24,
              width: "600px",
              maxWidth: "600px",
              maxHeight: "80vh",
              transform: "translate(-50%, -50%)",
              overflow: "hidden",
            }}
          >
            <>
              {/* Modal Header */}
              <Box
                sx={{
                  height: "40px",
                  backgroundColor: "#08B0A0",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "4px 4px 0 0",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  color={Colors.white}
                  sx={{ paddingLeft: "16px" }}
                  variant="h5"
                >
                  {!aclChecklist?.EditTemplate
                    ? "CHECKLIST TEMPLATE DETAIL"
                    : id === 0
                    ? "NEW CHECKLIST TEMPLATE"
                    : "EDIT CHECKLIST TEMPLATE"}
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon sx={{ color: Colors.white, width: 24, height: 24 }} />
                </IconButton>
              </Box>

              {/* Modal Content */}
              <Box
                sx={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingBottom: "20px",
                  overflowY: "auto",
                  maxHeight: "calc(80vh - 40px)",
                  marginTop: "8px",
                }}
              >
                <CustomListItem
                  leftContainerWidth={150}
                  text="Name*"
                  containerStyle={{ marginBottom: "16px" }}
                  RightComponent={
                    <TextField
                      id="cL-name"
                      label="Checklist name"
                      variant="standard"
                      style={{ width: "100%" }}
                      disabled={!aclChecklist?.EditTemplate}
                      InputProps={{
                        style: {
                          fontSize: "13px",
                          fontWeight: "400",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "13px",
                          fontWeight: "400",
                        },
                      }}
                      value={params.name}
                      onChange={handleNameChange}
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontSize: "13px",
                          fontWeight: "400",
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "13px",
                          fontWeight: "400",
                        },
                      }}
                    />
                  }
                />
                <CustomListItem
                  leftContainerWidth={150}
                  containerStyle={{ marginBottom: "100px" }}
                  noCentered={true}
                  text="Tasks*"
                  RightComponent={
                    <Box
                      sx={{
                        width: "100%",
                        minHeight: "100px",
                        maxHeight: "300px",
                        overflowY: "auto",
                      }}
                    >
                      <List>
                        {params?.templateTasks?.map((t, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <AssignmentOutlinedIcon
                              sx={{
                                color: "gray",
                                width: 20,
                                height: 20,
                                marginRight: "12px",
                              }}
                            />
                            <ListItemText
                              primary={t.name}
                              sx={{ fontSize: "13px", fontWeight: "400" }}
                            />
                            <IconButton
                              onClick={() => handleRemoveTask(index)}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            >
                              {((id !==0 && aclChecklist?.EditTemplate) || (id === 0 && aclChecklist?.CreateTemplate)) && (
                                <DeleteIcon
                                  sx={{ color: "gray", width: 20, height: 20 }}
                                />
                              )}
                            </IconButton>
                          </ListItem>
                        ))}
                      </List>
                      {((id !==0 && aclChecklist?.EditTemplate) || (id === 0 && aclChecklist?.CreateTemplate)) && (
                        <Box
                          sx={{
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                          }}
                        >
                          <Button
                            variant="outlined"
                            style={{
                              color: "rgb(82, 146, 82)",
                              borderColor: "rgb(69, 139, 69)",
                              marginRight: "20px",
                              fontSize: "13px",
                              maxHeight: "36px",
                            }}
                            onClick={() => setOpenTaskModal(true)}
                          >
                            Add
                          </Button>
                          <Button
                            variant="outlined"
                            style={{
                              color: "rgb(82, 104, 146)",
                              borderColor: "rgb(69, 92, 139)",
                              fontSize: "13px",
                              maxHeight: "36px",
                            }}
                            onClick={() => setShowImportClist(true)}
                          >
                            Import
                          </Button>
                        </Box>
                      )}
                    </Box>
                  }
                />
                <NewTask
                  isTemplate={true}
                  open={openTaskModal}
                  onClose={() => setOpenTaskModal(false)}
                  onConfirmed={handleTaskConfirmed}
                />

                {/* Modal Button */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    display: "flex",
                    gap: "20px",
                    backgroundColor: "white",
                    padding: "20px 20px",
                    borderRadius: "4px",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  {((id !==0 && aclChecklist?.EditTemplate) || (id === 0 && aclChecklist?.CreateTemplate)) && (
                    <>
                      <Button
                        variant="outlined"
                        style={{
                          color: "gray",
                          borderColor: "gray",
                          fontSize: "13px",
                          maxHeight: "36px",
                        }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          fontSize: "13px",
                          maxHeight: "36px",
                        }}
                        onClick={createChecklist}
                        disabled={
                          params.name === "" ||
                          params.templateTasks?.length === 0
                        }
                      >
                        Save
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </>

            <ImportCListModal
              open={showImportClist}
              onClose={() => setShowImportClist(false)}
              onConfirmed={handleImportClist}
              onConfirmedTask={handleImportTasks}
            />
          </Box>
        </Modal>
      </>
    );
  }
);
export default ChecklistTemplateDetailModal;
