import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import CustomDatePicker from "../components/Datepicker";
import { Task, TasksApi } from "../../../services/phpServices";
import moment from "moment-timezone";
import ListTasks from "../components/ListTasks";
import RequiredModal from "../components/RequiredModal";
import { useStores } from "../../../models/helpers/useStores";
import { observer } from "mobx-react-lite";
import { Colors } from "../../../theme";

const MyChecklist = observer(() => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const {
    myChecklistStore: { filter, isGetLoading, mytasks, getListMyChecklist },
  } = useStores();

  const refresh = () => {
    getListMyChecklist({
      start_time: filter.start_time ?? moment().startOf("day").unix(),
      end_time: filter.end_time ?? moment().endOf("day").unix(),
      isMy: true,
    });
  };

  const handleTaskConfirm = async (task: Task) => {
    setSelectedTask(task);
    if (
      (task.note === 1 || task.photo === 1) &&
      task.complete_status === "UNCOMPLETED"
    ) {
      setOpenModal(true);
    } else {
      try {
        const newStatus =
          task.complete_status === "COMPLETED" ? "UNCOMPLETED" : "COMPLETED";
        await new TasksApi().changeStatusCompleteTask(
          task.task_id ?? 0,
          filter.start_time ?? 0,
          "web",
          [],
          newStatus,
          ""
        );
        refresh();
      } catch (e) {
        //
      }
    }
  };

  useEffect(() => {
    getListMyChecklist({
      start_time: filter.start_time ?? moment().startOf("day").unix(),
      end_time: filter.end_time ?? moment().endOf("day").unix(),
      isMy: true,
    });
  }, []);
  return (
    <Box>
      <Typography color={Colors.black} variant="h6">
        MY CHECKLIST
      </Typography>

      <Box
        style={{
          width: "100%",
          borderBottom: "2px solid #E0E0E0",
          marginTop: "24px",
          marginBottom: "10px",
        }}
      />
      <CustomDatePicker
        start_time={filter?.start_time || moment().startOf("day").unix()}
        end_time={filter?.end_time || moment().endOf("day").unix()}
        onChange={(st, et) => {
          getListMyChecklist({
            ...filter,
            start_time: st,
            end_time: et,
            isMy: true,
          });
        }}
      />
      {isGetLoading ? (
        <CircularProgress size={24} />
      ) : (
        <ListTasks
          data={mytasks}
          onRefresh={refresh}
          isMy={true}
          taskDate={filter.start_time ?? 0}
          onConfirm={handleTaskConfirm}
        />
      )}
      <RequiredModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        task={selectedTask}
        dateTime={filter.start_time ?? 0}
        onConfirm={refresh}
      />
    </Box>
  );
});

export default MyChecklist;
