import React from "react";
import { useParams } from "react-router-dom";
import { Task, TasksApi } from "../../../../../services/phpServices";
import moment from "moment-timezone";
import ListTasks from "../../../components/ListTasks";
import { Box, CircularProgress, Typography } from "@mui/material";
import Avatar from "../../../../../common/components/Avatar";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../models/helpers/useStores";

export const EmployeeCList = observer(() => {
  const params = useParams();
  const {
    allChecklistStore: { filter, isGetLoading, alltasks, getAllTasks },
  } = useStores();
  const selectedEmp = alltasks[0]?.employees?.find(
    (emp) => emp.employee_id === Number(params.empId)
  );

  async function getData(empId: number) {
    try {
      getAllTasks({
        start_time: filter.start_time ?? moment().startOf("day").unix(),
        end_time: filter.end_time ?? moment().endOf("day").unix(),
        isMy: false,
        employee_id: empId,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  React.useEffect(() => {
    if (params.empId) {
      getData(Number(params.empId));
    }
  }, [params.empId]);

  return (
    <>
      {isGetLoading && <CircularProgress size={24} />}
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
          marginBottom: "16px",
          marginTop: "8px",
        }}
      >
        <Avatar
          image={selectedEmp?.avatar ?? ""}
          color={selectedEmp?.color ?? ""}
          name={selectedEmp?.fullname ?? ""}
        />
        <Typography style={{ marginLeft: "8px" }}>
          {selectedEmp?.fullname}
        </Typography>
      </Box>
      <ListTasks
        data={alltasks}
        onRefresh={() => getData(Number(params.empId))}
        taskDate={filter.start_time ?? moment().startOf("day").unix()}
      />
    </>
  );
});
