import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomListItem } from "./ListItem";
import moment from "moment-timezone";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { observer } from "mobx-react-lite";
import { formatDate, formatTime } from "../../../common/dateTime";
import { Task } from "../../../services/phpServices";
import { Colors } from "../../../theme";

interface TaskDetailModalProps {
  open: boolean;
  onClose: () => void;
  taskDetail: Task | undefined
}

const TaskDetailModal: React.FC<TaskDetailModalProps> = observer(
  ({ open, onClose, taskDetail }) => {
    const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
    const slides = taskDetail?.logs?.flatMap(
      (log) => log.data_photo?.map((photo) => ({ src: photo })) ?? []
    );

    const openLightbox = (index: number) => {
      setCurrentImageIndex(index);
      setIsLightboxOpen(true);
    };

    const closeLightbox = () => {
      setIsLightboxOpen(false);
    };

    return (
      <>

          <Modal
            open={open}
            aria-labelledby="task-details-modal"
            aria-describedby="task-details-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                backgroundColor: "white",
                borderRadius: "4px",
                boxShadow: 24,
                width: "600px",
                maxHeight: "80vh",
                transform: "translate(-50%, -50%)",
                overflow: "hidden",
              }}
            >
              {taskDetail && (
                <>
                  {/* Modal Header */}
                  <Box
                    sx={{
                      height: "40px",
                      backgroundColor: "#08B0A0",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "4px 4px 0 0",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={Colors.white}
                      sx={{ paddingLeft: "16px" }}
                    >
                      TASK DETAIL
                    </Typography>
                    <IconButton onClick={onClose}>
                      <CloseIcon
                        sx={{ color: Colors.white, width: 24, height: 24 }}
                      />
                    </IconButton>
                  </Box>

                  {/* Modal Content */}
                  <Box
                    sx={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      paddingBottom: "20px",
                      overflowY: "auto",
                      maxHeight: "calc(80vh - 40px)",
                    }}
                  >
                    {/* Task Info Section */}
                    <Typography
                      style={{
                        marginTop: "20px",
                      }}
                      variant="h5"
                      color={Colors.primary}
                    >
                      INFO
                    </Typography>
                    <CustomListItem
                      text="Task Name"
                      containerStyle={{ marginTop: "8px" }}
                      leftContainerWidth={150}
                      RightComponent={
                        <Typography>{taskDetail.name}</Typography>
                      }
                    />
                    <CustomListItem
                      text="Description"
                      containerStyle={{ marginTop: "8px" }}
                      leftContainerWidth={150}
                      RightComponent={
                        <Typography>
                          {taskDetail.description
                            ? taskDetail.description
                            : "-"}
                        </Typography>
                      }
                    />
                    <CustomListItem
                      text="Assigned to"
                      containerStyle={{ marginTop: "8px" }}
                      leftContainerWidth={150}
                      RightComponent={
                        <Typography>
                          {taskDetail.employees
                            ?.map((emp) => emp.fullname)
                            .join(", ")}
                        </Typography>
                      }
                    />
                    <CustomListItem
                      text="Required"
                      containerStyle={{ marginTop: "8px" }}
                      leftContainerWidth={150}
                      RightComponent={
                        <Typography>
                          {[
                            taskDetail?.note !== 0 && "Note",
                            taskDetail?.photo !== 0 && "Photo",
                            taskDetail?.require_time !== 0 && "Time",
                          ]
                            .filter(Boolean)
                            .join(", ") || "None"}{" "}
                        </Typography>
                      }
                    />
                    {taskDetail?.logs?.length === 1 && (
                      <CustomListItem
                        text="Status"
                        containerStyle={{ marginTop: "8px" }}
                        leftContainerWidth={150}
                        RightComponent={
                          <Typography
                            style={{
                              color:
                                taskDetail.logs[0].status === "UNCOMPLETED"
                                  ? Colors.warning
                                  : Colors.primary,
                            }}
                          >
                            {taskDetail.logs[0].status === "UNCOMPLETED"
                              ? "INCOMPLETE"
                              : taskDetail.logs[0].status === "CREATED"
                              ? "CREATED"
                              : "COMPLETED"}
                          </Typography>
                        }
                      />
                    )}

                    {/* Task Logs Section */}
                    {taskDetail?.logs && taskDetail?.logs?.length > 1 && (
                      <>
                        <Box
                          style={{
                            width: "calc(100% + 40px)",
                            borderBottom: "6px solid #E0E0E0",
                            marginTop: "24px",
                            marginBottom: "10px",
                            marginLeft: "-20px",
                            marginRight: "-40px",
                          }}
                        />
                        <Typography
                          style={{
                            marginTop: "20px",
                            marginBottom: "8px",
                          }}
                          color={Colors.primary}
                          variant="h5"
                        >
                          PERFORMANCE
                        </Typography>
                        {taskDetail.logs
                          .slice()
                          .reverse()
                          .map((log, index, arr) => (
                            <React.Fragment key={index}>
                              <CustomListItem
                                text="Status"
                                leftContainerWidth={150}
                                RightComponent={
                                  <Typography
                                    style={{
                                      color:
                                        log.status === "COMPLETED"
                                          ? Colors.primary
                                          : Colors.warning,
                                    }}
                                  >
                                    {log.status}
                                  </Typography>
                                }
                              />
                              <CustomListItem
                                text="Date"
                                containerStyle={{ marginTop: "8px" }}
                                leftContainerWidth={150}
                                RightComponent={
                                  <Typography>
                                    {moment
                                      .unix(Number(log.task_date))
                                      .format("MM/DD/YYYY")}
                                  </Typography>
                                }
                              />
                              <CustomListItem
                                text={
                                  index === arr.length - 1
                                    ? "Created by"
                                    : "Updated"
                                }
                                containerStyle={{ marginTop: "8px" }}
                                leftContainerWidth={150}
                                RightComponent={
                                  <Typography>
                                    {log.created_employee?.fullname}
                                  </Typography>
                                }
                              />
                              {log.data_note && (
                                <CustomListItem
                                  text="Note"
                                  containerStyle={{ marginTop: "8px" }}
                                  leftContainerWidth={150}
                                  RightComponent={
                                    <Typography>"{log.data_note}"</Typography>
                                  }
                                />
                              )}

                              {Number(log.created_at) && (
                                <CustomListItem
                                  text="Time"
                                  containerStyle={{ marginTop: "8px" }}
                                  leftContainerWidth={150}
                                  RightComponent={
                                    <Typography
                                      style={
                                        taskDetail?.require_time !== null &&
                                        (log?.created_at ?? 0) >
                                          (taskDetail?.require_time ?? 0) * 60 +
                                            (taskDetail?.date_instance ?? 0)
                                          ? { color: Colors.danger }
                                          : { color: Colors.black }
                                      }
                                    >
                                      {formatDate(
                                        Number(taskDetail?.date_instance),
                                        "DD MMMM YYYY"
                                      )}{" "}
                                      @{formatTime(log?.created_at ?? 0)}
                                    </Typography>
                                  }
                                />
                              )}

                              {log.data_photo && log.data_photo.length > 0 && (
                                <>
                                  <Typography
                                    style={{
                                      marginTop: "16px",
                                      color: Colors.black,
                                    }}
                                    variant="h5"
                                  >
                                    Photos
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: "16px",
                                      flexDirection: "row",
                                      marginTop: "12px",
                                    }}
                                  >
                                    {log.data_photo.map((photo, subIndex) => (
                                      <img
                                        key={subIndex}
                                        src={photo}
                                        alt={`task-photo-${subIndex}`}
                                        onClick={() => openLightbox(subIndex)}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          borderRadius: "4px",
                                          objectFit: "cover",
                                          cursor: "pointer",
                                        }}
                                      />
                                    ))}
                                  </Box>
                                </>
                              )}

                              {index !== arr.length - 1 && (
                                <Box
                                  style={{
                                    width: "100%",
                                    borderBottom: "2px solid #E0E0E0",
                                    marginTop: "16px",
                                    marginBottom: "16px",
                                  }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                      </>
                    )}
                  </Box>
                </> 
              )}
            </Box>
          </Modal>
        {isLightboxOpen && (
          <Lightbox
            open={isLightboxOpen}
            close={closeLightbox}
            slides={slides ?? []}
            index={currentImageIndex}
          />
        )}
      </>
    );
  }
);

export default TaskDetailModal;
