import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { withSetPropAction } from "../helpers/withSetPropAction";
import {
  Categories,
  CategoryTaskTemplate,
  CheckList,
  CheckListTemplateDataInner,
  CompanyApi,
  TasksApi,
  TaskTemplate,
  TaskTemplateCheckListForm,
  TaskTemplateForm,
} from "../../services/phpServices";

export const TemplateStoreModel = types
  .model("TemplateStore")
  .props({
    isSubmitting: false,
    templateDetail: types.optional(types.frozen<CheckList>(), {}),
    checklistTemplates: types.optional(
      types.frozen<CheckListTemplateDataInner[]>(),
      []
    ),
    taskTemplates: types.optional(types.frozen<CategoryTaskTemplate[]>(), []),
    isGetLoading: false,
    taskTemplateDetail: types.optional(types.frozen<TaskTemplate>(), {
      task_template_id: 0,
      name: "",
      description: "",
      created_at: 0,
      created_id: 0,
      updated_at: 0,
      updated_id: 0,
    }),
    categories: types.optional(types.frozen<Categories[]>(), []),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async createTaskTemplate(task: TaskTemplateForm) {
      try {
        store.setProp("isSubmitting", true);
        await new TasksApi().createTaskTemplate("web", task);
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isSubmitting", false);
      }
    },
    async createChecklistTemplate(cList: TaskTemplateCheckListForm) {
      try {
        store.setProp("isSubmitting", true);
        await new TasksApi().createChecklistTaskTemplate("web", cList);
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isSubmitting", false);
      }
    },
    async updateChecklistTemplate(
      id: number,
      cList: TaskTemplateCheckListForm
    ) {
      try {
        store.setProp("isSubmitting", true);
        await new TasksApi().updateChecklistTaskTemplate(id, "web", cList);
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isSubmitting", false);
      }
    },
    async getChecklistTemplateDetail(id: number) {
      try {
        store.setProp("isSubmitting", true);
        const { data } = await new TasksApi().detailChecklistTemplate(id);
        store.setProp("templateDetail", data);
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isSubmitting", false);
      }
    },
    async getListChecklistTemplate() {
      try {
        store.setProp("isGetLoading", true);
        const { data } = await new TasksApi().getChecklistTemplate();
        store.setProp("checklistTemplates", data.data);
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async getListTaskTemplate() {
      try {
        store.setProp("isGetLoading", true);
        const { data } = await new TasksApi().getListTaskTemplate();
        store.setProp("taskTemplates", data);
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async deleteTaskTemplate(id: number) {
      try {
        store.setProp("isSubmitting", true);
        await new TasksApi().deleteTaskTemplate(id);
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isSubmitting", false);
      }
    },
    async getTaskTemplateDetail(id: number) {
      try {
        store.setProp("isGetLoading", true);
        const { data } = await new TasksApi().detailTaskTemplate(id);
        store.setProp("taskTemplateDetail", data);
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
    async getListTaskCategories() {
      try {
        store.setProp("isGetLoading", true);
        const {data} = await new CompanyApi().getListCategories({
          type: "TASKS",
          limit: 1000,
          page: 1,
        });
        store.setProp("categories", data.data)
      } catch (error: any) {
        if (error.response) {
          console.log(error);
        }
      } finally {
        store.setProp("isGetLoading", false);
      }
    },
  }));

export interface TemplateStore extends Instance<typeof TemplateStoreModel> {}
export interface TemplateStoreSnapshot
  extends SnapshotOut<typeof TemplateStoreModel> {}
