import { Box, CircularProgress, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import CustomMonthPicker from "../../../components/MonthPicker";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../models/helpers/useStores";
import moment from "moment";
import { useEffect } from "react";
import { Card } from "../../../components/Card";
import { Colors } from "../../../../../theme";

const MonthlyReport = observer(() => {
  const {
    checklistReportStore: {
      isGetLoading,
      filterMonthly,
      summaryCheckList,
      listSummaryReport,
    },
  } = useStores();

  useEffect(() => {
    summaryCheckList({
      start_time: filterMonthly.start_time ?? moment().startOf("month").unix(),
      end_time: filterMonthly.end_time ?? moment().endOf("month").unix(),
      page: 1,
      limit: 100,
    });
  }, []);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      ></Box>
      <CustomMonthPicker
        start_time={filterMonthly.start_time}
        onChange={(st, et) =>
          summaryCheckList({
            ...filterMonthly,
            start_time: st,
            end_time: et,
            page: 1,
            limit: 100,
          })
        }
      />
      {isGetLoading && <CircularProgress size={24} />}
      {!isGetLoading && (
        <>
          <Typography
            style={{
              color: "gray",
              marginTop: "16px",
              marginBottom: "8px",
            }}
            variant="h5"
          >
            SUMMARY
          </Typography>
          <Card
            disabled
            ContentComponent={
              <Typography variant="h5">Total checklists</Typography>
            }
            RightComponent={
              <Typography variant="h5" color={Colors.primary}>
                {listSummaryReport?.total_checklist}
              </Typography>
            }
          />
          <Card
            disabled
            ContentComponent={<Typography variant="h5">Total tasks</Typography>}
            RightComponent={
              <Typography variant="h5" color={Colors.primary}>
                {listSummaryReport?.total}
              </Typography>
            }
          />
          <Card
            disabled
            ContentComponent={
              <Typography variant="h5">Tasks completed</Typography>
            }
            RightComponent={
              <Typography variant="h5" color={Colors.primary}>
                {`${listSummaryReport?.completed ?? 0} (${(
                  ((listSummaryReport?.completed ?? 0) /
                    (listSummaryReport?.total ?? 0)) *
                  100
                ).toFixed(2)}%)`}
              </Typography>
            }
          />
          <Card
            disabled
            ContentComponent={
              <Typography variant="h5">Tasks pending</Typography>
            }
            RightComponent={
              <Typography variant="h5" color={Colors.primary}>
                {`${listSummaryReport?.in_completed ?? 0} (${(
                  ((listSummaryReport?.in_completed ?? 0) /
                    (listSummaryReport?.total ?? 0)) *
                  100
                ).toFixed(2)}%)`}
              </Typography>
            }
          />
          <Typography
            style={{
              color: "gray",
              marginTop: "16px",
              marginBottom: "8px",
            }}
            variant="h5"
          >
            TOP PERFORMERS
          </Typography>
          {listSummaryReport?.tops?.map((item, index) => (
            <Card
              disabled
              ContentComponent={
                <Typography variant="h5">{item.fullname}</Typography>
              }
              RightComponent={
                <Typography variant="h5" color={Colors.primary}>
                  {item.percent}%
                </Typography>
              }
            />
          ))}
        </>
      )}
      <Outlet />
    </Box>
  );
});

export default MonthlyReport;
